import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {stylesheet} from 'typestyle';
import {formatCents} from '../../../utils/formatCents';
import {Button} from '../../common/Button';

const headingStyle = {
  background: 'linear-gradient(180deg, #FFFFFF 24.65%, #F9E086 73.24%)',
  backgroundClip: 'text',
  '-webkit-background-clip': 'text',
  '-webkit-text-fill-color': 'transparent',
  textFillColor: 'transparent',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.18)',
  zIndex: 2,
  fontWeight: 700,
  fontSize: 47,
  margin: 0,
};

const classes = stylesheet({
  root: {
    width: '100%',
    textAlign: 'center',
    zIndex: 2,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  ticket: {
    marginTop: 30,
    marginBottom: -100,
    paddingTop: 120,
    width: '100%',
    height: 265,
    background: 'url(/ticket.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'center',
    zIndex: 3,
    position: 'relative',
  },
  pokerGold: {
    position: 'absolute',
    top: -25,
    left: 'calc(50% - 73px)',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '134px 36px 32px',
    background: 'rgba(0,0,0,0.5)',
    borderBottom: '1px solid #FFE179',
    borderTop: '1px solid #FFE179',
    position: 'relative',

    $nest: {
      '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: 280,
        height: 18,
        top: -18,
        left: 'calc(50% - 140px)',
        background: 'url(/handle.svg)',
        transform: 'rotate(180deg)',
      },
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: 280,
        height: 18,
        bottom: -18,
        left: 'calc(50% - 140px)',
        background: 'url(/handle.svg)',
      },
    },
  },
  text: {
    width: 250,
    fontWeight: 300,
    zIndex: 2,
  },
  joinText: {
    fontSize: 10,
    marginTop: 40,
    marginBottom: 0,
    zIndex: 2,
  },
  button: {
    marginTop: 8,
  },
});

type Props = {
  couponId: string;
  resultCents: number;
};

export const CashOutRequested: React.FC<Props> = ({resultCents, couponId}) => {
  const {t} = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.ticket}>
        <img className={classes.pokerGold} src="/poker-gold.png" alt="" />
        <h1 style={headingStyle}>{formatCents(resultCents)}</h1>
      </div>
      <div className={classes.content}>
        <p className={classes.text}>
          {t('Speak to your agent to get back your current balance.')}
        </p>
      </div>
      <p className={classes.joinText}>
        {t('You can join us at JackPoker at any time.')}
        <br />
        {t("We'll be happy to see you!")}
      </p>
      <Link to={`/customer/registration/${couponId}`}>
        <Button className={classes.button} text={t('Join JackPoker')} />
      </Link>
    </div>
  );
};

import QR from 'qrcode.react';
import {stylesheet} from 'typestyle';

type Props = {
  url: string;
  size: number;
  fgColor?: string;
  bgColor?: string;
};

export const QRCode: React.FC<Props> = ({url, size, fgColor, bgColor}) => {
  const classes = stylesheet({
    root: {
      padding: Math.floor(size / 15),
      borderRadius: 4,
      background: bgColor ?? '#FFF',
      width: size,
      height: size,
    },
  });

  return (
    <div className={classes.root}>
      <QR
        value={url}
        size={size - 2 * Math.floor(size / 15)}
        fgColor={fgColor ?? '#000'}
        bgColor={bgColor ?? '#fff'}
      />
    </div>
  );
};

import {stylesheet, classes as cx} from 'typestyle';
import {
  ListOperationsResponse_Item as Operation,
  ListOperationsResponse_Item_Purchase as Purchase,
} from '../../../proto/deeplay/jackpoker_fast_funnel/agent/v1/agent';
import assert from 'assert';
import {Card} from '@blueprintjs/core';
import React from 'react';
import {formatCents} from '../../../utils/formatCents';
import {useAuth} from '../../../Auth';
import {useTranslation} from 'react-i18next';

const classes = stylesheet({
  couponList: {
    height: '100%',
    overflowY: 'scroll',
    padding: 1,
    margin: -1,

    $nest: {
      h2: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '24px',
      },
    },
  },
  coupon: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 8,
    marginBottom: 4,
    background: 'rgba(92, 22, 189, 0.3)',
    border: '1px solid rgba(255, 255, 255, 0.15)',
    borderRadius: 4,
  },
  couponUsed: {
    position: 'relative',
    border: '1px solid rgba(255, 255, 255, 0.1)',

    $nest: {
      '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        background: 'rgba(0,0,0,0.4)',
      },
    },
  },
  couponContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  qr: {
    borderRadius: 4,
  },
  qrAndStatus: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start',
  },
  couponState: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: 8,
  },
  idAndDate: {
    display: 'flex',
    flexDirection: 'column',
  },
  couponId: {
    fontSize: 10,
    lineHeight: '16px',
    fontWeight: 600,
  },
  couponDate: {
    fontSize: 10,
    lineHeight: '16px',
    color: '#fff',
  },
});

type Props = {
  operations: Operation[];
  onCardClick: (operation: Operation) => void;
  showAll?: boolean;
};

export const CouponList: React.FC<Props> = ({
  operations,
  onCardClick,
  showAll,
}) => {
  const {t} = useTranslation();
  const {user} = useAuth();
  assert(user != null);

  const cashOutRequested = operations.filter(
    coupon =>
      coupon.type?.$case === 'purchase' &&
      coupon.type.purchase.state?.$case === 'cashOutRequested',
  );

  const active = operations.filter(
    coupon =>
      coupon.type?.$case === 'purchase' &&
      (coupon.type.purchase.state?.$case === 'pending' ||
        (showAll &&
          (coupon.type.purchase.state?.$case === 'inGame' ||
            coupon.type.purchase.state?.$case === 'preGame' ||
            coupon.type.purchase.state?.$case === 'gameFinished'))),
  );

  const rest = showAll
    ? operations.filter(
        coupon =>
          coupon.type?.$case === 'purchase' &&
          (coupon.type.purchase.state?.$case === 'cashedOut' ||
            coupon.type.purchase.state?.$case === 'convertedToJackpoker'),
      )
    : [];

  return (
    <div className={classes.couponList}>
      {cashOutRequested.length > 0 ? (
        <>
          <h2>{t('Cashout requested')}</h2>
          {cashOutRequested.map(operation => {
            assert(operation.type?.$case === 'purchase');
            const {purchase} = operation.type;
            const date = operation.timestamp
              ? new Date(operation.timestamp.seconds.toNumber() * 1000)
              : '';

            return (
              <Card
                interactive
                className={classes.coupon}
                key={operation.transactionId}
                onClick={event => onCardClick(operation)}
              >
                <div className={classes.couponContent}>
                  <div className={classes.qrAndStatus}>
                    <img
                      alt=""
                      width={36}
                      height={36}
                      src="agent/cor-coupon-icon.svg"
                    />
                    <div className={classes.couponState}>
                      <div className={classes.idAndDate}>
                        <span className={classes.couponId}>
                          {purchase.couponId.substring(0, 6)}
                        </span>
                        <span className={classes.couponDate}>
                          {date.toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Status purchase={purchase} />
                </div>
                <Result purchase={purchase} />
              </Card>
            );
          })}
        </>
      ) : (
        ''
      )}
      {active.length > 0 ? (
        <>
          <h2>{t('Active coupons')}</h2>
          {active.map(operation => {
            assert(operation.type?.$case === 'purchase');
            const {purchase} = operation.type;
            const date = operation.timestamp
              ? new Date(operation.timestamp.seconds.toNumber() * 1000)
              : '';

            return (
              <Card
                interactive
                className={classes.coupon}
                key={operation.transactionId}
                onClick={event => onCardClick(operation)}
              >
                <div className={classes.couponContent}>
                  <div className={classes.qrAndStatus}>
                    <img
                      alt=""
                      width={36}
                      height={36}
                      src="agent/coupon-icon.svg"
                    />
                    <div className={classes.couponState}>
                      <div className={classes.idAndDate}>
                        <span className={classes.couponId}>
                          {purchase.couponId.substring(0, 6)}
                        </span>
                        <span className={classes.couponDate}>
                          {date.toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Status purchase={purchase} />
                </div>
                <Result purchase={purchase} />
              </Card>
            );
          })}
        </>
      ) : (
        ''
      )}
      {rest.length > 0 ? (
        <>
          <h2>{t('Used coupons')}</h2>
          {rest.map(operation => {
            assert(operation.type?.$case === 'purchase');
            const {purchase} = operation.type;
            const date = operation.timestamp
              ? new Date(operation.timestamp.seconds.toNumber() * 1000)
              : '';

            return (
              <Card
                interactive
                className={cx(classes.coupon, classes.couponUsed)}
                key={operation.transactionId}
                onClick={event => onCardClick(operation)}
              >
                <div className={classes.couponContent}>
                  <div className={classes.qrAndStatus}>
                    <img
                      alt=""
                      width={36}
                      height={36}
                      src="agent/coupon-icon.svg"
                    />
                    <div className={classes.couponState}>
                      <div className={classes.idAndDate}>
                        <span className={classes.couponId}>
                          {purchase.couponId.substring(0, 6)}
                        </span>
                        <span className={classes.couponDate}>
                          {date.toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Status purchase={purchase} />
                </div>
                <Result purchase={purchase} />
              </Card>
            );
          })}
        </>
      ) : (
        ''
      )}
    </div>
  );
};

const Status: React.FC<{purchase: Purchase}> = ({purchase}) => {
  const {t} = useTranslation();
  if (!purchase.state) {
    return <></>;
  }

  let status;
  let nickname;
  let color = '#808080';
  let border = '1px solid #808080';
  switch (purchase.state.$case) {
    case 'cashedOut':
      status = t('cashed out');
      color = 'rgba(55, 55, 55, 0.3)';
      border = '1px solid #373737';
      nickname = purchase.state.cashedOut.nickname;
      break;
    case 'gameFinished':
      status = t('game finished');
      color = 'rgba(61, 153, 65, 0.3)';
      border = '1px solid #3D9941';
      nickname = purchase.state.gameFinished.nickname;
      break;
    case 'inGame':
      status = t('in game');
      color = 'rgba(30, 104, 192, 0.3)';
      border = '1px solid #1E68C0';
      nickname = purchase.state.inGame.nickname;
      break;
    case 'pending':
      status = t('pending');
      color = 'rgba(128, 128, 128, 0.3)';
      border = '1px solid #808080';
      break;
    case 'preGame':
      status = t('pre-game');
      color = 'rgba(204, 124, 31, 0.3)';
      border = '1px solid #CC7C1F';
      break;
    case 'cashOutRequested':
      status = t('cashout requested');
      border = '1px solid #B21B1B';
      color = 'rgba(179, 27, 27, 0.3)';
      nickname = purchase.state.cashOutRequested.nickname;
      break;
    case 'convertedToJackpoker':
      status = t('paid by JP');
      border = '1px solid #373737';
      color = 'rgba(55, 55, 55, 0.3)';
      break;
    default:
      status = t('unknown');
      break;
  }

  const classes = stylesheet({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 10,
    },
    status: {
      display: 'inline-block',
      boxSizing: 'border-box',
      marginRight: 8,
      padding: '2px 8px',
      borderRadius: 4,
      background: color,
      border,
      fontSize: 10,
      width: 'fit-content',
    },
    playerIcon: {
      marginBottom: -3,
    },
    nickname: {
      display: 'inline-block',
      marginLeft: 4,
      fontSize: 10,
      color: 'rgba(255, 255, 255, 0.6)',
    },
  });

  return (
    <div className={classes.root}>
      <span className={classes.status}>{status}</span>
      {nickname ? (
        <div>
          <img
            className={classes.playerIcon}
            width={16}
            height={16}
            src="/agent/profile-filled-icon.svg"
            alt=""
          />
          <span className={classes.nickname}>{nickname}</span>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const Result: React.FC<{
  purchase: Purchase;
}> = ({purchase}) => {
  const {t} = useTranslation();
  if (!purchase.state) {
    return <></>;
  }

  let result: number | undefined = undefined;
  let background = 'rgba(67, 16, 138, 0.37)';
  let text = 'Result';
  let height = '82px';
  let borderRadius: string | number = '0px 4px 4px 0px';
  let padding: string | number = '16px 8px';
  let marginRight = -8;

  const {state} = purchase;

  switch (state?.$case) {
    case 'cashOutRequested':
      result = state.cashOutRequested.resultCents.toNumber();
      height = '50%';
      borderRadius = 4;
      padding = 4;
      background = '#8C3CFA';
      text = t('Pay');
      marginRight = 0;
      break;
    case 'cashedOut':
      result = state.cashedOut.resultCents.toNumber();
      background = 'rgba(0,0,0,0.2)';
      text = t('Paid');
      break;
    case 'gameFinished':
      result = state.gameFinished.resultCents.toNumber();
      break;
    default:
      break;
  }

  if (result === undefined) {
    return <></>;
  }

  const classes = stylesheet({
    result: {
      width: 72,
      height,
      margin: -8,
      marginRight,
      padding,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
      alignSelf: 'center',
      background,
      borderRadius,

      $nest: {
        '& span': {
          fontSize: 10,
          color: 'rgba(255, 255, 255, 0.6)',
        },
        '& b': {
          fontSize: 16,
          fontWeight: 500,
        },
      },
    },
  });

  return (
    <div coupon-id={purchase.couponId} className={classes.result}>
      <span>{text}</span>
      <b>{formatCents(result)}</b>
    </div>
  );
};

import {stylesheet, classes as cx, style, media} from 'typestyle';
import {Dialog, DialogProps} from '@blueprintjs/core';
import React from 'react';

const classes = stylesheet({
  root: {
    margin: 0,
    padding: '24px 36px',
    width: '100%',
    position: 'absolute',
    bottom: 0,
    background: '#2A0A57',
    borderRadius: '8px 8px 0 0',
    color: '#fff',
    fontFamily: 'Poppins',
    maxWidth: 800,
  },
  close: {
    cursor: 'pointer',
    alignSelf: 'flex-end',
    width: 25,
    height: 25,
    marginTop: -2,
    border: `1px solid #fff`,
    borderRadius: 12,
    position: 'absolute',
    $nest: {
      '&::after': {
        content: "''",
        display: 'block',
        position: 'absolute',
        width: 11,
        height: 1,
        top: 11,
        left: 6,
        transform: 'rotate(45deg)',
        backgroundColor: '#fff',
      },
      '&::before': {
        content: "''",
        display: 'block',
        position: 'absolute',
        width: 11,
        height: 1,
        top: 11,
        left: 6,
        transform: 'rotate(-45deg)',
        backgroundColor: '#fff',
      },
    },
  },
});

export const JPDialog: React.FC<DialogProps> = props => {
  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    props.onClose && props.onClose(event);
  };

  const className = style(
    media({minWidth: 600}, {position: 'static', borderRadius: 12}),
  );

  return (
    <Dialog {...props} className={cx(props.className, classes.root, className)}>
      <div onClick={onClick} className={classes.close} />
      {props.children}
    </Dialog>
  );
};

import assert from 'assert';
import {createContext, ReactNode, useContext} from 'react';
import {useQuery} from 'react-query';
import {useAuth} from '../../Auth';
import {AgentClient} from '../../client';
import {ListOperationsQueryFn} from './queries/listOperations';
import {ListOperationsResponse_Item as Operation} from '../../proto/deeplay/jackpoker_fast_funnel/agent/v1/agent';
import {GetBalanceQueryFn} from './queries/getBalance';

type AgentContext = {
  coupons: Operation[];
  /**
   * Достаточно ли денег на балансе для покупки купона.
   */
  canBuyCoupon: boolean;
  /**
   * Количество денег на балансе.
   */
  balanceCents: number;
  /**
   * Сумма стоимости всех купленных купонов.
   */
  boughtCouponsCents: number;
  /**
   * Количество купленных купонов.
   */
  boughtCoupons: number;
  /**
   * Сумма стоимости всех проданных купонов.
   */
  receivedCents: number;
  /**
   * Количество проданных купонов.
   */
  soldCoupons: number;
  /**
   * Сумма стоимости всех cashed out купонов(возвратов).
   */
  cashedOutCents: number;
  /**
   * Количество cashed out купонов.
   */
  cashedOutCoupons: number;
  /**
   * Сумма всех компенсаций агенту.
   */
  compensatedCents: number;
  /**
   * Количество компенсированных купонов.
   */
  compensatedCoupons: number;
  profitCents: number;
};

const context = createContext<AgentContext>(null!);

export function AgentProvider({children}: {children: ReactNode}) {
  const {user} = useAuth();
  assert(user != null);

  const {data: operationsResponse} = useQuery({
    queryKey: ['list-operations'],
    queryFn: ListOperationsQueryFn({
      agentClient: AgentClient(user.credentials),
    }),
    suspense: true,
  });
  assert(operationsResponse != null);

  const {data: balanceResponse} = useQuery({
    queryKey: ['get-balance'],
    queryFn: GetBalanceQueryFn({
      agentClient: AgentClient(user.credentials),
    }),
    suspense: true,
  });
  assert(balanceResponse != null);

  let receivedCents = 0;
  let soldCoupons = 0;
  let cashedOutCents = 0;
  let cashedOutCoupons = 0;
  let compensatedCents = 0;
  let compensatedCoupons = 0;
  let boughtCouponsCents = 0;
  let boughtCoupons = 0;
  const coupons = [];

  for (const item of operationsResponse.items) {
    switch (item.type?.$case) {
      case 'compensation':
        compensatedCents += Math.abs(item.deltaCents.toNumber());
        compensatedCoupons++;
        break;
      case 'purchase':
        coupons.push(item);
        boughtCouponsCents += Math.abs(item.deltaCents.toNumber());
        boughtCoupons++;

        if (item.type.purchase.state?.$case !== 'pending') {
          receivedCents += Math.round(
            Math.abs(item.type.purchase.amountCents.toNumber()),
          );
          soldCoupons++;
        }
        if (item.type.purchase.state?.$case === 'cashedOut') {
          cashedOutCents +=
            item.type.purchase.state.cashedOut.resultCents.toNumber();
          cashedOutCoupons++;
        }
        break;
      default:
        break;
    }
  }

  const profitCents = receivedCents - cashedOutCents + compensatedCents;
  const balanceCents = balanceResponse.balanceCents.toNumber();

  const value = {
    coupons,
    canBuyCoupon: balanceCents > 1000,
    balanceCents,
    boughtCouponsCents,
    boughtCoupons,
    receivedCents,
    soldCoupons,
    cashedOutCents,
    cashedOutCoupons,
    compensatedCents,
    compensatedCoupons,
    profitCents,
  };

  return <context.Provider value={value}>{children}</context.Provider>;
}

export function useAgentContext() {
  return useContext(context);
}

import {Dialog} from '@blueprintjs/core';
import assert from 'assert';
import {ClientError} from 'nice-grpc-web';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation, useQueryClient} from 'react-query';
import {stylesheet, cssRaw} from 'typestyle';
import {useAuth} from '../../../Auth';
import {AgentClient} from '../../../client';
import {ListOperationsResponse_Item as Operation} from '../../../proto/deeplay/jackpoker_fast_funnel/agent/v1/agent';
import {toaster} from '../../../toaster';
import {formatCents} from '../../../utils/formatCents';
import {Button} from '../../common/Button';
import {
  CashOutCouponMutationFn,
  postCashOutCouponMutation,
} from '../queries/cashOutCoupon';
import {AgentAlert} from './AgentAlert';

const resultStyle = {
  background: 'linear-gradient(180deg, #FFFFFF 24.65%, #F9E086 73.24%)',
  backgroundClip: 'text',
  '-webkit-background-clip': 'text',
  '-webkit-text-fill-color': 'transparent',
  textFillColor: 'transparent',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.18)',
  zIndex: 2,
  fontWeight: 600,
  fontSize: 24,
  lineHeight: '20px',
  margin: 0,
  marginTop: 48,
};

cssRaw(`
@keyframes slidein {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0%);
  }
}
`);

const classes = stylesheet({
  root: {
    animation: '0.2s slidein both',
    padding: '50px 26px 26px',
    borderRadius: '12px 12px 0px 0px',
    margin: 0,
    width: '100%',
    height: '100%',
    background: '#1E073D',
    color: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: 0,
    fontFamily: 'Poppins',
    textAlign: 'center',
  },
  details: {
    width: '100%',
  },
  nickname: {
    marginTop: 15,
    marginBottom: 24,
    fontSize: 20,
    fontWeight: 500,
  },
  id: {
    marginTop: 12,
    fontSize: 20,
    fontWeight: 500,
  },
  date: {
    marginTop: 12,
    marginBottom: 12,
    fontSize: 14,
    lineHeight: '16px',
    color: 'rgba(255,255,255,0.6)',
  },
  divider: {
    width: '100%',
    height: 1,
    background: '#3E1478',
  },
  result: {
    marginTop: 48,
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '20px',
    background: 'linear-gradient(180deg, #FFFFFF 24.65%, #F9E086 73.24%)',
    backgroundClip: 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  buttons: {
    width: '100%',
  },
  button: {
    width: '100%',
  },
  topButton: {
    width: '100%',
    marginBottom: 16,
  },
});

export const CashOutDialog: React.FC<{
  operation?: Operation;
  isOpen: boolean;
  onClose: () => void;
}> = ({operation, isOpen, onClose}) => {
  const {t} = useTranslation();
  const {user} = useAuth();
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);

  assert(user != null);

  const queryClient = useQueryClient();
  const cashOutCouponMutation = useMutation({
    mutationFn: CashOutCouponMutationFn({
      agentClient: AgentClient(user.credentials),
    }),
    onSuccess(data, variables) {
      postCashOutCouponMutation(queryClient, data, variables);
    },
    onError(err: any) {
      toaster.show({
        message: (
          <div>
            <div>Error:</div>
            <div>{err instanceof ClientError ? err.details : err.message}</div>
          </div>
        ),
        intent: 'danger',
      });
    },
  });

  if (
    operation === undefined ||
    operation.type?.$case !== 'purchase' ||
    operation.type.purchase.state?.$case !== 'cashOutRequested'
  ) {
    return <></>;
  }

  const {couponId, stateChangedAt} = operation.type.purchase;
  const {resultCents, nickname} =
    operation.type.purchase.state.cashOutRequested;

  const onPayClick = () => setIsAlertOpen(true);
  const onAlertClose = () => setIsAlertOpen(false);
  const onConfirm = () => cashOutCouponMutation.mutate({couponId});

  return (
    <Dialog className={classes.root} isOpen={isOpen} onClose={onClose}>
      <div className={classes.details}>
        <img src="agent/player-icon.svg" alt="" width={90} height={90} />
        <div className={classes.nickname}>{nickname}</div>
        <div className={classes.divider} />
        <div className={classes.id}>{couponId.slice(0, 6).toUpperCase()}</div>
        <div className={classes.date}>
          {new Date(
            stateChangedAt
              ? stateChangedAt.seconds.toNumber() * 1000
              : Date.now(),
          ).toLocaleString()}
        </div>
        <div className={classes.divider} />
        <div style={resultStyle} className={classes.result}>
          {t('Balance: {{amount}}', {
            amount: formatCents(resultCents.toNumber()),
          })}
        </div>
      </div>
      <div className={classes.buttons}>
        <Button primary className={classes.topButton} onClick={onPayClick}>
          {t('Pay {{amount}}', {amount: formatCents(resultCents.toNumber())})}
        </Button>
        <Button className={classes.button} onClick={onClose}>
          {t('Close')}
        </Button>
      </div>
      <AgentAlert
        intent="danger"
        cancelButtonText={t('Cancel')}
        confirmButtonText={t('Cash out')}
        canEscapeKeyCancel
        canOutsideClickCancel
        onClose={onAlertClose}
        onConfirm={onConfirm}
        isOpen={isAlertOpen}
      >
        {t('Are you sure you want to cash out coupon?')}
      </AgentAlert>
    </Dialog>
  );
};

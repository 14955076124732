import {QueryFunction} from 'react-query';
import {CustomerClient} from '../../../client';
import {GetCouponInfoResponse} from '../../../proto/deeplay/jackpoker_fast_funnel/customer/v1/customer';

export type GetCouponInfoQueryKey = [tag: 'get-coupon-info'];

export type GetCouponInfoQueryData = GetCouponInfoResponse;

export type GetCouponInfoQueryDeps = {
  customerClient: CustomerClient;
  couponId: string;
};

export type GetCouponInfoQueryFn = QueryFunction<
  GetCouponInfoQueryData,
  GetCouponInfoQueryKey
>;

export function GetCouponInfoQueryFn({
  customerClient,
  couponId,
}: GetCouponInfoQueryDeps): GetCouponInfoQueryFn {
  return ({signal}) => customerClient.getCouponInfo({couponId}, {signal});
}

import {MutationFunction, QueryClient} from 'react-query';
import {CustomerClient} from '../../../client';
import {
  DeepPartial,
  RequestCashOutRequest,
  RequestCashOutResponse,
} from '../../../proto/deeplay/jackpoker_fast_funnel/customer/v1/customer';

export type RequestCashOutMutationVariables =
  DeepPartial<RequestCashOutRequest>;

export type RequestCashOutMutationData = RequestCashOutResponse;

export type RequestCashOutMutationDeps = {
  customerClient: CustomerClient;
};

export type RequestCashOutMutationFn = MutationFunction<
  RequestCashOutMutationData,
  RequestCashOutMutationVariables
>;

export function RequestCashOutMutationFn({
  customerClient,
}: RequestCashOutMutationDeps): RequestCashOutMutationFn {
  return async request => {
    return await customerClient.requestCashOut(request);
  };
}

export function postRequestCashOutMutation(
  queryClient: QueryClient,
  data: RequestCashOutMutationData,
  request: RequestCashOutMutationVariables,
) {
  queryClient.invalidateQueries(['get-coupon-info']);
}

import {ClientMiddleware, Metadata} from 'nice-grpc-web';

export function AuthMiddleware(credentials: string): ClientMiddleware {
  return async function* authMiddleware(call, options) {
    const metadata = Metadata(options.metadata);

    if (credentials) {
      metadata.set('Authorization', `Basic ${credentials}`);
    }

    return yield* call.next(call.request, {
      ...options,
      metadata,
    });
  };
}

import {MutationFunction} from 'react-query';
import {CustomerClient} from '../../../client';
import {
  DeepPartial,
  FinishGameRequest,
  FinishGameResponse,
} from '../../../proto/deeplay/jackpoker_fast_funnel/customer/v1/customer';

export type FinishGameMutationVariables = DeepPartial<FinishGameRequest>;

export type FinishGameMutationData = FinishGameResponse;

export type FinishGameMutationDeps = {
  customerClient: CustomerClient;
};

export type FinishGameMutationFn = MutationFunction<
  FinishGameMutationData,
  FinishGameMutationVariables
>;

export function FinishGameMutationFn({
  customerClient,
}: FinishGameMutationDeps): FinishGameMutationFn {
  return async request => {
    return await customerClient.finishGame(request);
  };
}

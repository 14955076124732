import {useTranslation} from 'react-i18next';
import {stylesheet} from 'typestyle';
import {Button} from '../../common/Button';

const classes = stylesheet({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    textAlign: 'center',
    zIndex: 2,
    position: 'relative',
    width: '100%',
  },
  pokerGold: {
    position: 'absolute',
    top: -115,
    left: 'calc(50% - 73px)',
    zIndex: 3,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '36px 32px',
    marginBottom: 50,
    background: 'rgba(0,0,0,0.5)',
    borderBottom: '1px solid #FFE179',
    borderTop: '1px solid #FFE179',
    position: 'relative',

    $nest: {
      '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: 280,
        height: 18,
        top: -18,
        left: 'calc(50% - 140px)',
        background: 'url(/handle.svg)',
        transform: 'rotate(180deg)',
      },
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: 280,
        height: 18,
        bottom: -18,
        left: 'calc(50% - 140px)',
        background: 'url(/handle.svg)',
      },
    },
  },
  logo: {
    margin: '50px auto 130px',
  },
  cashOut: {
    marginTop: 200,
  },
  heading: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '27px',
    zIndex: 2,
  },
  text: {
    width: 250,
    fontWeight: 300,
    zIndex: 2,
  },
});

export const AllSet = () => {
  const {t} = useTranslation();

  return (
    <div className={classes.root}>
      <img width={180} className={classes.logo} alt="logo" src="/logo.svg" />
      <div className={classes.content}>
        <img className={classes.pokerGold} src="/poker-gold.png" alt="" />
        <h1 className={classes.heading}>You're all set!</h1>
        <p className={classes.text}>
          {t('Go to JackPoker')}
          <br />
          {t('and use those bonuses.')}
        </p>
      </div>
      <a href="https://room.jack-poker.com/">
        <Button primary text={t('Get Bonuses and Play')} />
      </a>
    </div>
  );
};

import {DialogProps} from '@blueprintjs/core';
import {useTranslation} from 'react-i18next';
import {stylesheet} from 'typestyle';
import {JPDialog} from './JPDialog';

const classes = stylesheet({
  help: {
    fontSize: 16,
    fontWeight: 600,
  },
  helpLinks: {
    marginTop: 25,
    fontSize: 14,
    fontWeight: 500,
  },
  helpLink: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px 24px',
    borderTop: '1px solid #3E1478',
    textDecoration: 'none',
    color: '#fff',

    $nest: {
      '&:hover, &:active': {
        color: '#fff',
      },
      img: {
        marginRight: 16,
      },
    },
  },
});

export const ContactUs: React.FC<DialogProps> = props => {
  const {t} = useTranslation();

  return (
    <JPDialog
      className={classes.help}
      onClose={props.onClose}
      isOpen={props.isOpen}
    >
      {t('Need help? Contact us!')}
      <div className={classes.helpLinks}>
        <a
          target="_blank"
          href="https://wa.me/+1"
          className={classes.helpLink}
          rel="noreferrer"
        >
          <img width={24} height={24} src="/whatsapp.svg" alt="" />
          WhatsApp
        </a>
        <a
          target="_blank"
          href="https://m.me/+1"
          className={classes.helpLink}
          rel="noreferrer"
        >
          <img width={24} height={24} src="/fbmessenger.svg" alt="" />
          Facebook Messenger
        </a>
        <a
          target="_blank"
          href="https://t.me/+1"
          className={classes.helpLink}
          rel="noreferrer"
        >
          <img width={24} height={24} src="/telegram.svg" alt="" />
          Telegram
        </a>
      </div>
    </JPDialog>
  );
};

import {stylesheet} from 'typestyle';

const classes = stylesheet({
  pokerBackground: {
    background: 'url(/background.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    backgroundSize: 'contain',
    top: 0,
    left: 0,
    position: 'absolute',
    width: '100%',
    height: 320,
    mixBlendMode: 'screen',
    zIndex: '-3',
  },
});

export const PokerBackground: React.FC<{height?: number}> = ({height}) => {
  return (
    <div style={height ? {height} : {}} className={classes.pokerBackground} />
  );
};

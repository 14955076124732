/* eslint-disable jsx-a11y/anchor-is-valid */
import assert from 'assert';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation, useQueryClient} from 'react-query';
import {useParams} from 'react-router-dom';
import {stylesheet} from 'typestyle';
import {CustomerClient} from '../../../client';
import {Button} from '../../common/Button';
import {Input} from '../../common/Input';
import {
  CompleteRegistrationMutationFn,
  postCompleteRegistrationMutation,
} from '../queries/completeRegistration';

const customerClient = CustomerClient();

const classes = stylesheet({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  header: {
    display: 'flex',
  },
  logo: {
    margin: '45px auto',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0px auto 0',
  },
  text: {
    fontSize: 12,
    textAlign: 'center',
  },
  input: {
    marginBottom: 24,
  },
  passwordHint: {
    marginBottom: 18,
    width: 250,
    fontSize: 10,
    fontWeight: 300,
  },
  button: {
    marginTop: 12,
  },
});

export const Verified = () => {
  const {t} = useTranslation();
  const {couponId} = useParams();

  assert(couponId != null);

  const [nickname, setNickname] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [nicknameError, setNicknameError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const completeRegistartionMutation = useMutation({
    mutationFn: CompleteRegistrationMutationFn({
      customerClient,
    }),
    onSuccess(data, variables) {
      setLoading(false);
      if (data.validationErrors.length > 0) {
        for (const error of data.validationErrors) {
          if (error.field?.$case === 'email') {
            setEmailError(error.field.email);
          }
          if (error.field?.$case === 'password') {
            setPasswordError(error.field.password);
          }
          if (error.field?.$case === 'nickname') {
            setNicknameError(error.field.nickname);
          }
        }
      } else {
        postCompleteRegistrationMutation(queryClient, data, variables);
      }
    },
    onError(err: any) {
      setLoading(false);
      throw err;
    },
  });

  const onChangeNickname = (event: React.ChangeEvent<HTMLInputElement>) =>
    setNickname(event.target.value);
  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(event.target.value);
  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(event.target.value);

  const onCloseNickname = () => setNickname('');
  const onCloseEmail = () => setEmail('');

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setNicknameError('');
    setPasswordError('');
    setEmailError('');
    setLoading(true);

    completeRegistartionMutation.mutate({couponId, nickname, password, email});
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <img
          width={180}
          height={52}
          className={classes.logo}
          alt="logo"
          src="/logo.svg"
        />
      </div>
      <form autoComplete="off" onSubmit={onFormSubmit} className={classes.form}>
        <p className={classes.text}>{t('Sign up for JackPoker')}</p>
        <Input
          name="username"
          autoComplete="none"
          className={classes.input}
          onClose={onCloseNickname}
          onChange={onChangeNickname}
          value={nickname}
          placeholder={t('Nickname')}
          type="text"
          error={nicknameError}
        />
        <Input
          name="password"
          autoComplete="new-password"
          className={classes.input}
          onChange={onChangePassword}
          value={password}
          placeholder={t('Password')}
          type="password"
          error={passwordError}
        />
        <span className={classes.passwordHint}>
          {t('Keep it within 8-20 characters (uppercase, lowercase, numeric)')}
        </span>
        <Input
          className={classes.input}
          onClose={onCloseEmail}
          onChange={onChangeEmail}
          value={email}
          placeholder={t('Email')}
          type="text"
          error={emailError}
        />

        <Button
          className={classes.button}
          loading={loading}
          primary
          disabled={
            nickname.length < 3 ||
            password.length < 3 ||
            email.length < 3 ||
            loading
          }
          text={t('Next')}
        />
      </form>
    </div>
  );
};

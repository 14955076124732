import {QueryFunction} from 'react-query';
import {CustomerClient} from '../../../client';
import {GetRegistrationStateResponse} from '../../../proto/deeplay/jackpoker_fast_funnel/customer/v1/customer';

export type GetRegistrationStateQueryKey = [tag: 'get-registration-state'];

export type GetRegistrationStateQueryData = GetRegistrationStateResponse;

export type GetRegistrationStateQueryDeps = {
  customerClient: CustomerClient;
  couponId: string;
};

export type GetRegistrationStateQueryFn = QueryFunction<
  GetRegistrationStateQueryData,
  GetRegistrationStateQueryKey
>;

export function GetRegistrationStateQueryFn({
  customerClient,
  couponId,
}: GetRegistrationStateQueryDeps): GetRegistrationStateQueryFn {
  return ({signal}) =>
    customerClient.getRegistrationState({couponId}, {signal});
}

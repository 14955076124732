import {MutationFunction, QueryClient} from 'react-query';
import {CustomerClient} from '../../../client';
import {
  DeepPartial,
  VerifyPhoneNumberRequest,
  VerifyPhoneNumberResponse,
} from '../../../proto/deeplay/jackpoker_fast_funnel/customer/v1/customer';

export type VerifyPhoneNumberMutationVariables =
  DeepPartial<VerifyPhoneNumberRequest>;

export type VerifyPhoneNumberMutationData = VerifyPhoneNumberResponse;

export type VerifyPhoneNumberMutationDeps = {
  customerClient: CustomerClient;
};

export type VerifyPhoneNumberMutationFn = MutationFunction<
  VerifyPhoneNumberMutationData,
  VerifyPhoneNumberMutationVariables
>;

export function VerifyPhoneNumberMutationFn({
  customerClient,
}: VerifyPhoneNumberMutationDeps): VerifyPhoneNumberMutationFn {
  return async request => {
    return await customerClient.verifyPhoneNumber(request);
  };
}

export function postVerifyPhoneNumberMutation(
  queryClient: QueryClient,
  data: VerifyPhoneNumberMutationData,
  request: VerifyPhoneNumberMutationVariables,
) {
  queryClient.invalidateQueries(['get-registration-state']);
}

import {
  Button,
  ButtonGroup,
  Classes,
  Colors,
  Dialog,
  Divider,
} from '@blueprintjs/core';
import {ClientError} from 'nice-grpc-web';
import React, {FormEvent, useEffect, useState} from 'react';
import {useMutation, useQueryClient} from 'react-query';
import {stylesheet} from 'typestyle';
import {AdminClient} from '../../client';
import {ListAgentsResponse_Item as Agent} from '../../proto/deeplay/jackpoker_fast_funnel/admin/v1/admin';
import {postSaveAgentMutation, SaveAgentMutationFn} from './queries/saveAgent';
import {
  postUpdateAgentBalanceMutation,
  UpdateAgentBalanceMutationFn,
} from './queries/updateBalance';
import {nanoid} from 'nanoid';
import {useAuth} from '../../Auth';
import assert from 'assert';
import {formatCents} from '../../utils/formatCents';

const classes = stylesheet({
  root: {
    padding: 20,
  },
  button: {
    width: '100%',
  },
  error: {
    color: Colors.RED1,
  },
});

export const EditDialog: React.FC<{
  agent?: Agent;
  isOpen: boolean;
  onClose: () => void;
}> = ({agent, isOpen, onClose}) => {
  const {user} = useAuth();
  assert(user != null);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [balance, setBalance] = useState<number | undefined>(undefined);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (agent) {
      setEmail(agent.email);
    }
  }, [agent]);

  const queryClient = useQueryClient();
  const saveAgentMutation = useMutation({
    mutationFn: SaveAgentMutationFn({
      adminClient: AdminClient(user.credentials),
    }),
    onSuccess(data, variables) {
      postSaveAgentMutation(queryClient, data, variables);
      setEmail('');
      setPassword('');
      setIsDirty(false);
      setError('');
      onClose();
    },
    onError(err: any) {
      if (err instanceof ClientError) {
        setError(err.details);
      } else {
        setError(err.message);
      }
    },
  });
  const updateAgentBalanceMutation = useMutation({
    mutationFn: UpdateAgentBalanceMutationFn({
      adminClient: AdminClient(user.credentials),
    }),
    onSuccess(data, variables) {
      postUpdateAgentBalanceMutation(queryClient, data, variables);
      setEmail('');
      setPassword('');
      setIsDirty(false);
      setError('');
      onClose();
    },
    onError(err: any) {
      if (err instanceof ClientError) {
        setError(err.details);
      } else {
        setError(err.message);
      }
    },
  });

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError('');
    saveAgentMutation.mutate({
      email,
      password,
    });
  };

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    if (value.length !== 0) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
    setEmail(event.target.value);
  };

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    if (value.length !== 0) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
    setPassword(value);
  };

  const onBalanceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBalance(event.target.valueAsNumber);
  };

  const onBalanceAdd = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    if (!balance) {
      return;
    }

    updateAgentBalanceMutation.mutate({
      agentEmail: email,
      transactionId: nanoid(),
      deltaCents: +balance,
    });

    setBalance(undefined);
  };

  const onBalanceWithdraw = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    if (!balance) {
      return;
    }

    updateAgentBalanceMutation.mutate({
      agentEmail: email,
      transactionId: nanoid(),
      deltaCents: -balance,
    });

    setBalance(undefined);
  };

  const onDialogClose = () => {
    setEmail('');
    setPassword('');
    setIsDirty(false);
    setError('');
    setBalance(undefined);
    onClose();
  };

  return (
    <Dialog
      title={agent ? 'Edit agent' : 'Add agent'}
      isOpen={isOpen}
      onClose={onDialogClose}
    >
      <form onSubmit={onSubmit} className={classes.root}>
        <div className={Classes.FORM_GROUP}>
          <label className={Classes.LABEL} htmlFor="email">
            Email
          </label>
          <input
            type="email"
            disabled={!!agent}
            value={email}
            onChange={onEmailChange}
            className={Classes.INPUT}
          />
        </div>
        <div className={Classes.FORM_GROUP}>
          <label className={Classes.LABEL} htmlFor="password">
            Password
          </label>
          <input
            autoComplete="new-password"
            value={password}
            onChange={onPasswordChange}
            type="password"
            className={Classes.INPUT}
          />
        </div>
        {error && <span className={classes.error}>{error}</span>}
        <Button
          disabled={!isDirty}
          className={classes.button}
          type="submit"
          intent="primary"
        >
          {agent ? 'Save' : 'Add'}
        </Button>
      </form>
      <Divider />
      {agent && (
        <form className={classes.root}>
          <div className={Classes.FORM_GROUP}>
            <label className={Classes.LABEL} htmlFor="balance">
              Current balance: {formatCents(agent.balanceCents.toNumber())}
            </label>
            <input
              min={0}
              type="number"
              placeholder="Balance in cents"
              value={balance}
              onChange={onBalanceChange}
              className={Classes.INPUT}
            />
          </div>
          <ButtonGroup fill>
            <Button onClick={onBalanceAdd} disabled={!balance} intent="success">
              Add
            </Button>
            <Button
              onClick={onBalanceWithdraw}
              disabled={!balance || agent.balanceCents.toNumber() < balance}
              intent="danger"
            >
              Withdraw
            </Button>
          </ButtonGroup>
        </form>
      )}
    </Dialog>
  );
};

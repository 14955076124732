import {Spinner} from '@blueprintjs/core';
import {ClientError, Status} from 'nice-grpc-web';
import {useEffect} from 'react';
import {ReactNode, createContext, useState, useContext} from 'react';
import {Location, Navigate, useLocation} from 'react-router-dom';
import {stylesheet} from 'typestyle';
import {AdminClient, AgentClient} from '../client';
import {getUser, resetUser, saveUser, User} from '../utils/credentials';

const classes = stylesheet({
  centered: {
    margin: '0 auto',
  },
});

type AuthContext = {
  user: User;
  loggingIn: boolean;
  logIn: (email: string, password: string, admin: boolean) => Promise<void>;
  logOut: () => void;
  error: string | null;
};

export type AuthLocationState = {
  from: Location;
};

const context = createContext<AuthContext>(null!);

export function AuthProvider({children}: {children: ReactNode}) {
  const [user, setUser] = useState<User | null>(null);
  const [loggingIn, setLoggingIn] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const storedUser = getUser();
    if (!storedUser) {
      setLoggingIn(false);
      return;
    }

    const client = storedUser.isAdmin
      ? AdminClient(storedUser.credentials)
      : AgentClient(storedUser.credentials);

    Promise.resolve().then(async () => {
      if (!user) {
        setLoggingIn(true);
        try {
          await client.checkCredentials({});
          setUser(storedUser);
          setLoggingIn(false);
        } catch (err) {
          if (
            err instanceof ClientError &&
            err.code === Status.UNAUTHENTICATED
          ) {
            resetUser();
          }
          setUser(null);
          setLoggingIn(false);
        }
      }
    });
  }, [user]);

  const logIn = async (email: string, password: string, admin: boolean) => {
    try {
      const storedUser = saveUser(email, password, admin);
      const client = admin
        ? AdminClient(storedUser.credentials)
        : AgentClient(storedUser.credentials);
      setError('');
      setLoggingIn(true);

      await client.checkCredentials({});

      setUser(storedUser);
      setLoggingIn(false);
    } catch (err) {
      if (err instanceof ClientError && err.code === Status.UNAUTHENTICATED) {
        setError('Invalid credentials');
      } else {
        setError('Service unavailable');
      }
      resetUser();
      setUser(null);
      setLoggingIn(false);
      throw err;
    }
  };

  const logOut = () => {
    resetUser();
    setUser(null);
    setLoggingIn(false);
  };

  const value = {user, loggingIn, logIn, logOut, error};

  return <context.Provider value={value}>{children}</context.Provider>;
}

export function useAuth() {
  return useContext(context);
}

export function RequireAuth({children}: {children: JSX.Element}) {
  const {user, loggingIn} = useAuth();
  const location = useLocation();

  if (loggingIn) {
    return <Spinner className={classes.centered} />;
  }

  const isAdminRoute = location.pathname.includes('admin');

  const to = isAdminRoute ? '/admin/login' : '/login';
  const forbidden =
    !user ||
    (user && isAdminRoute && !user.isAdmin) ||
    (user && !isAdminRoute && user.isAdmin);
  const state: AuthLocationState = {from: location};

  if (forbidden) {
    return <Navigate to={to} state={state} replace />;
  }

  return children;
}

import {useState} from 'react';
import {stylesheet} from 'typestyle';
import {Button} from '../../common/Button';
import {CouponDialog} from '../components/CouponDialog';
import {CouponList} from '../components/CouponList';
import {ListOperationsResponse_Item as Operation} from '../../../proto/deeplay/jackpoker_fast_funnel/agent/v1/agent';
import {useAuth} from '../../../Auth';
import assert from 'assert';
import {useMutation, useQueryClient} from 'react-query';
import {AgentClient} from '../../../client';
import {BuyCouponMutationFn, postBuyCouponMutation} from '../queries/buyCoupon';
import {toaster} from '../../../toaster';
import {ClientError} from 'nice-grpc-web';
import {nanoid} from 'nanoid';
import {useAgentContext} from '..';
import {useTranslation} from 'react-i18next';
import {AgentAlert} from '../components/AgentAlert';
import {Search} from '../components/Search';
import {ViewBalance} from '../components/ViewBalance';
import {ShowAll} from '../components/ShowAll';
import {CashOutDialog} from '../components/CashOutDialog';

const classes = stylesheet({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '0 auto 64px',
    padding: 20,
    maxWidth: 1200,
    backgroundColor: '#1E073D',
    color: '#FFF',
    borderLeft: '1px solid rgba(0,0,0,0.1)',
    borderRight: '1px solid rgba(0,0,0,0.1)',
    fontFamily: 'Poppins',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  input: {
    padding: '6px 22px 6px 40px',
    width: '100%',
    height: 36,
    borderRadius: 4,
    border: 'none',
    background: 'rgba(0,0,0,0.5)',
    boxShadow: 'none',
    caretColor: '#fff',
    color: '#fff',

    $nest: {
      '&::placeholder': {
        color: '#6919D7',
      },
    },
  },
  button: {
    marginTop: 24,
  },
  noCoupons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    fontSize: 15,
    color: 'rgba(255,255,255,0.6)',
  },
  createCouponMessage: {
    width: 190,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
  },
  notEnoughMoney: {
    fontSize: 10,
  },
});

export const Coupons = () => {
  const {t} = useTranslation();
  const {user} = useAuth();
  assert(user != null);

  const {coupons, canBuyCoupon, balanceCents} = useAgentContext();

  const [searchText, setSearchText] = useState<string>('');
  const [operation, setOperation] = useState<Operation | undefined>(undefined);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isCashoutDialogOpen, setIsCashoutDialogOpen] =
    useState<boolean>(false);
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [showAll, setShowAll] = useState<boolean>(false);
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);

  const operations = coupons.filter(
    coupon =>
      coupon.type?.$case === 'purchase' &&
      coupon.type.purchase.couponId.startsWith(searchText),
  );

  const queryClient = useQueryClient();
  const buyCouponMutation = useMutation({
    mutationFn: BuyCouponMutationFn({
      agentClient: AgentClient(user.credentials),
    }),
    onSuccess(data, variables) {
      postBuyCouponMutation(queryClient, data, variables);
    },
    onError(err: any) {
      toaster.show({
        message: (
          <div>
            <div>Error:</div>
            <div>{err instanceof ClientError ? err.details : err.message}</div>
          </div>
        ),
        intent: 'danger',
      });
    },
  });

  const onBuyClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    setIsAlertOpen(true);
  };

  const onClose = () => setIsAlertOpen(false);

  const onConfirm = () =>
    buyCouponMutation.mutate({
      transactionId: nanoid(),
    });

  const onSearchChange = (searchText: string) => {
    setSearchText(searchText);
  };

  const onCardClick = (operation: Operation) => {
    setOperation(operation);
    if (
      operation.type?.$case === 'purchase' &&
      operation.type.purchase.state?.$case === 'cashOutRequested'
    ) {
      setIsCashoutDialogOpen(true);
    } else if (
      operation.type?.$case === 'purchase' &&
      (operation.type.purchase.state?.$case === 'pending' ||
        operation.type.purchase.state?.$case === 'preGame' ||
        operation.type.purchase.state?.$case === 'inGame' ||
        operation.type.purchase.state?.$case === 'gameFinished')
    ) {
      setIsOpen(true);
    }
  };

  const onShow = () => setShowAll(true);
  const onHide = () => setShowAll(false);

  const onSearchOpen = () => setIsSearchOpen(true);
  const onSearchClose = () => setIsSearchOpen(false);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Search
          isOpen={isSearchOpen}
          onOpen={onSearchOpen}
          onClose={onSearchClose}
          className={classes.input}
          onSearchChange={onSearchChange}
        />
        <ViewBalance
          style={isSearchOpen ? {display: 'none'} : {}}
          balance={balanceCents}
        />
        <ShowAll
          style={isSearchOpen ? {display: 'none'} : {}}
          onShow={onShow}
          onHide={onHide}
        />
      </div>
      <br />
      {operations.length > 0 ? (
        <CouponList
          showAll={showAll}
          operations={operations}
          onCardClick={onCardClick}
        />
      ) : (
        <div className={classes.noCoupons}>
          <img width={58} height={58} src="/agent/no-coupons.svg" alt="" />
          <br />
          {searchText.length > 0
            ? `${t('No matching search results')}`
            : `${t('Your coupons will be here')}`}
        </div>
      )}
      <Button
        fill
        primary
        className={classes.button}
        onClick={onBuyClick}
        disabled={!canBuyCoupon}
      >
        {
          <>
            <span>{t('Create Coupon for $10')}</span>
            <br />
            <span className={classes.notEnoughMoney}>
              {!canBuyCoupon ? t('Not enough money on your balance') : ''}
            </span>
          </>
        }
      </Button>
      <CouponDialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        operation={operation}
      />
      <CashOutDialog
        isOpen={isCashoutDialogOpen}
        onClose={() => setIsCashoutDialogOpen(false)}
        operation={operation}
      />
      <AgentAlert
        intent="danger"
        cancelButtonText={t('Cancel')}
        confirmButtonText={t('Create')}
        canEscapeKeyCancel
        canOutsideClickCancel
        onClose={onClose}
        onConfirm={onConfirm}
        isOpen={isAlertOpen}
      >
        <p className={classes.createCouponMessage}>{t('Create coupon?')}</p>
      </AgentAlert>
    </div>
  );
};

import {MutationFunction} from 'react-query';
import {CustomerClient} from '../../../client';
import {
  DeepPartial,
  StartGameRequest,
  StartGameResponse,
} from '../../../proto/deeplay/jackpoker_fast_funnel/customer/v1/customer';

export type StartGameMutationVariables = DeepPartial<StartGameRequest>;

export type StartGameMutationData = StartGameResponse;

export type StartGameMutationDeps = {
  customerClient: CustomerClient;
};

export type StartGameMutationFn = MutationFunction<
  StartGameMutationData,
  StartGameMutationVariables
>;

export function StartGameMutationFn({
  customerClient,
}: StartGameMutationDeps): StartGameMutationFn {
  return async request => {
    return await customerClient.startGame(request);
  };
}

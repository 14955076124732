import {QueryFunction} from 'react-query';
import {AgentClient} from '../../../client';
import {GetBalanceResponse} from '../../../proto/deeplay/jackpoker_fast_funnel/agent/v1/agent';

export type GetBalanceQueryKey = [tag: 'get-balance'];

export type GetBalanceQueryData = GetBalanceResponse;

export type GetBalanceQueryDeps = {
  agentClient: AgentClient;
};

export type GetBalanceQueryFn = QueryFunction<
  GetBalanceQueryData,
  GetBalanceQueryKey
>;

export function GetBalanceQueryFn({
  agentClient,
}: GetBalanceQueryDeps): GetBalanceQueryFn {
  return ({signal}) => agentClient.getBalance({}, {signal});
}

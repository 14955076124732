import {MutationFunction, QueryClient} from 'react-query';
import {AdminClient} from '../../../client';
import {
  DeepPartial,
  UpdateAgentBalanceRequest,
  UpdateAgentBalanceResponse,
} from '../../../proto/deeplay/jackpoker_fast_funnel/admin/v1/admin';

export type UpdateAgentBalanceMutationVariables =
  DeepPartial<UpdateAgentBalanceRequest>;

export type UpdateAgentBalanceMutationData = UpdateAgentBalanceResponse;

export type UpdateAgentBalanceMutationDeps = {
  adminClient: AdminClient;
};

export type UpdateAgentBalanceMutationFn = MutationFunction<
  UpdateAgentBalanceMutationData,
  UpdateAgentBalanceMutationVariables
>;

export function UpdateAgentBalanceMutationFn({
  adminClient,
}: UpdateAgentBalanceMutationDeps): UpdateAgentBalanceMutationFn {
  return async request => {
    return await adminClient.updateAgentBalance(request);
  };
}

export function postUpdateAgentBalanceMutation(
  queryClient: QueryClient,
  data: UpdateAgentBalanceMutationData,
  request: UpdateAgentBalanceMutationVariables,
) {
  queryClient.invalidateQueries(['list-agents']);
}

import {QueryFunction} from 'react-query';
import {AdminClient} from '../../../client';
import {ListAgentsResponse} from '../../../proto/deeplay/jackpoker_fast_funnel/admin/v1/admin';

export type ListAgentsQueryKey = [tag: 'list-agents'];

export type ListAgentsQueryData = ListAgentsResponse;

export type ListAgentsQueryDeps = {
  adminClient: AdminClient;
};

export type ListAgentsQueryFn = QueryFunction<
  ListAgentsQueryData,
  ListAgentsQueryKey
>;

export function ListAgentsQueryFn({
  adminClient,
}: ListAgentsQueryDeps): ListAgentsQueryFn {
  return ({signal}) => adminClient.listAgents({}, {signal});
}

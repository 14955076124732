import assert from 'assert';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import {CustomerClient} from '../../../client';
import {GetCouponInfoQueryFn} from '../queries/getCouponIInfo';
import {AllSet} from './AllSet';
import {CountryAndPhone} from './CountryAndPhone';
import {Verified} from './Verified';
import {SMSCodeVerification} from './SMSCodeVerification';
import {GetRegistrationStateResponse_Status as Status} from '../../../proto/deeplay/jackpoker_fast_funnel/customer/v1/customer';
import {GetRegistrationStateQueryFn} from '../queries/getRegistrationState';

const customerClient = CustomerClient();

export const Registration = () => {
  const {couponId} = useParams();

  assert(couponId != null);

  const {data: coupon} = useQuery({
    queryKey: ['get-coupon-info'],
    queryFn: GetCouponInfoQueryFn({customerClient, couponId}),
    suspense: true,
    refetchOnWindowFocus: false,
  });

  assert(
    coupon != null &&
      (coupon.state?.$case === 'cashOutRequested' ||
        coupon.state?.$case === 'cashedOut' ||
        coupon.state?.$case === 'gameFinished'),
  );

  const {data: registrationState} = useQuery({
    queryKey: ['get-registration-state'],
    queryFn: GetRegistrationStateQueryFn({customerClient, couponId}),
    suspense: true,
    refetchOnWindowFocus: false,
  });

  assert(registrationState != null);

  let Content = () => <CountryAndPhone />;

  switch (registrationState.status) {
    case Status.STARTED:
      Content = () => (
        <SMSCodeVerification
          countryId={registrationState.countryId}
          phoneNumber={registrationState.phoneNumber}
        />
      );
      break;
    case Status.VERIFIED:
      Content = () => <Verified />;
      break;
    case Status.COMPLETED:
      Content = () => <AllSet />;
      break;
    default:
      break;
  }

  return <Content />;
};

import assert from 'assert';
import {ClientError} from 'nice-grpc-web';
import {useEffect, useState} from 'react';
import {useMutation} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import {CustomerClient} from '../../client';
import {FinishGameMutationFn} from './queries/finishGame';

export const LeaveGame = () => {
  const [error, setError] = useState<boolean>(false);
  const {couponId} = useParams();
  const navigate = useNavigate();

  assert(couponId != null);

  const finishGameMutation = useMutation({
    mutationFn: FinishGameMutationFn({
      customerClient: CustomerClient(),
    }),
    onSuccess(data, variables) {
      navigate(`/customer/${couponId}`, {replace: true});
    },
    onError(err: any) {
      setError(err instanceof ClientError ? err.details : err.message);
      throw err;
    },
  });

  useEffect(() => {
    finishGameMutation.mutate({couponId});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <p>{error}</p>;
};

import {useTranslation} from 'react-i18next';
import {stylesheet} from 'typestyle';

const classes = stylesheet({
  canceled: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    textAlign: 'center',
    zIndex: 2,
    position: 'relative',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: 110,
    padding: '60px 36px',
    background: 'rgba(0,0,0,0.5)',
    borderBottom: '1px solid #FFE179',
    borderTop: '1px solid #FFE179',
    position: 'relative',

    $nest: {
      '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: 280,
        height: 18,
        top: -18,
        left: 'calc(50% - 140px)',
        background: 'url(/handle.svg)',
        transform: 'rotate(180deg)',
      },
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: 280,
        height: 18,
        bottom: -18,
        left: 'calc(50% - 140px)',
        background: 'url(/handle.svg)',
      },
    },
  },
  pokerGold: {
    position: 'absolute',
    top: -110,
    zIndex: 3,
  },
  canceledLogo: {
    margin: '58px auto',
  },
  heading: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '27px',
    zIndex: 2,
  },
});

export const CashedOut = () => {
  const {t} = useTranslation();

  return (
    <div className={classes.canceled}>
      <img
        width={180}
        className={classes.canceledLogo}
        alt="logo"
        src="/logo.svg"
      />
      <div className={classes.content}>
        <img className={classes.pokerGold} alt="logo" src="/poker-gold.png" />
        <h1 className={classes.heading}>{t('It was good to see you!')}</h1>
        <p>{t("You're welcome to JackPoker at any time")}</p>
      </div>
    </div>
  );
};

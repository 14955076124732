import React, {useState} from 'react';
import {useMutation, useQueryClient} from 'react-query';
import {Link} from 'react-router-dom';
import {stylesheet} from 'typestyle';
import {CustomerClient} from '../../../client';
import {
  postRequestCashOutMutation,
  RequestCashOutMutationFn,
} from '../queries/requestCashOut';
import {Button} from '../../common/Button';
import {CashedOut} from './CashedOut';
import {useTranslation} from 'react-i18next';

const customerClient = CustomerClient();

type Props = {
  bbCents: number;
  resultCents: number;
  couponId: string;
};

const classes = stylesheet({
  root: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    margin: '0 auto',
    alignItems: 'center',
  },
  image: {
    width: 320,
  },
  logo: {
    margin: '0 auto 10px',
  },
  cashOut: {
    marginTop: 200,
  },
  text: {
    width: 250,
    fontWeight: 300,
    zIndex: 2,
    marginBottom: 32,
  },
  topButton: {
    marginBottom: 24,
  },
});

export const GameFinished: React.FC<Props> = ({
  bbCents,
  resultCents,
  couponId,
}) => {
  const {t} = useTranslation();
  const [canceled, setCanceled] = useState<boolean>(false);
  const resultInBB = resultCents / bbCents;

  const text =
    resultInBB > 400
      ? t(
          `Good game, you're crushing it! Create an account in JackPoker to double the prize on your balance and continue winning!`,
        )
      : resultInBB > 0
      ? t(
          `We’re sad to see you go. You can withdraw your current balance. To double it, create an account in a new poker room — JackPoker.`,
        )
      : t(
          `Thank you for playing! Don't worry, your bonus balance is waiting for you in JackPoker – a new poker room. Sign up and start winning!`,
        );

  const queryClient = useQueryClient();
  const requestCashOutMutation = useMutation({
    mutationFn: RequestCashOutMutationFn({customerClient}),
    onError(err) {
      console.log(err);
    },
    onSuccess(data, variables) {
      postRequestCashOutMutation(queryClient, data, variables);
    },
  });
  const onCashoutClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    requestCashOutMutation.mutate({
      couponId,
    });
  };
  const onCancel = () => {
    setCanceled(true);
  };

  return canceled ? (
    <CashedOut />
  ) : (
    <div className={classes.root}>
      <div className={classes.image}>
        <img
          height={248}
          className={classes.logo}
          alt="prize"
          src={resultCents === 0 ? '/result-0.svg' : '/result-gt-0.png'}
        />
      </div>
      <p className={classes.text}>{text}</p>
      {resultCents > 0 ? (
        <Link to={`/customer/registration/${couponId}`}>
          <Button
            className={classes.topButton}
            gold
            primary
            text={t('Double up')}
          />
        </Link>
      ) : (
        <Link to={`/customer/registration/${couponId}`}>
          <Button
            className={classes.topButton}
            gold
            primary
            text={t('Get bonuses')}
          />
        </Link>
      )}
      {resultCents > 0 ? (
        <Button onClick={onCashoutClick} text={t('Cash Out')} />
      ) : (
        <Button onClick={onCancel} text={t('No, Thanks')} />
      )}
    </div>
  );
};

import {useTranslation} from 'react-i18next';
import {Link, useLocation} from 'react-router-dom';
import {stylesheet, classes as cx} from 'typestyle';

const classes = stylesheet({
  tabBar: {
    display: 'flex',
    flexDirection: 'row',
    height: 64,
    width: '100vw',
    background: '#26094D',
    borderTop: '1px solid #8C3CFA',
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  tab: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    position: 'relative',
    color: '#5C16BD',
    textDecoration: 'none !important',
  },
  icon: {
    marginBottom: 4,
  },
  activeTab: {
    color: '#FFF !important',
    $nest: {
      '&::before': {
        background: '#5C16BD',
      },
    },
  },
});

export type Page = {
  name: string;
  iconUrl: string;
  iconActiveUrl: string;
  to: string;
};

export const pages: Page[] = [
  {
    name: 'Coupons',
    iconUrl: '/agent/coupons-icon.svg',
    iconActiveUrl: '/agent/coupons-filled-icon.svg',
    to: '/',
  },
  {
    name: 'Profile',
    iconUrl: '/agent/profile-icon.svg',
    iconActiveUrl: '/agent/profile-filled-icon.svg',
    to: '/profile',
  },
];

export const TabBar = () => {
  const location = useLocation();
  const {t} = useTranslation();

  return (
    <div className={classes.tabBar}>
      {pages.map(page => (
        <Link
          to={page.to}
          key={page.name}
          className={cx(
            classes.tab,
            location.pathname === page.to ? classes.activeTab : '',
          )}
        >
          <img
            className={classes.icon}
            height={24}
            alt="icon"
            src={
              location.pathname === page.to ? page.iconActiveUrl : page.iconUrl
            }
          />
          <span>{t(page.name)}</span>
        </Link>
      ))}
    </div>
  );
};

import {QueryFunction} from 'react-query';
import {CustomerClient} from '../../../client';
import {ListCountriesResponse} from '../../../proto/deeplay/jackpoker_fast_funnel/customer/v1/customer';

export type ListCountriesQueryKey = [tag: 'list-countries'];

export type ListCountriesQueryData = ListCountriesResponse;

export type ListCountriesQueryDeps = {
  customerClient: CustomerClient;
};

export type ListCountriesQueryFn = QueryFunction<
  ListCountriesQueryData,
  ListCountriesQueryKey
>;

export function ListCountriesQueryFn({
  customerClient,
}: ListCountriesQueryDeps): ListCountriesQueryFn {
  return ({signal}) => customerClient.listCountries({}, {signal});
}

const userKey = 'user';
export type User = {
  isAdmin: boolean;
  credentials: string;
  email: string;
} | null;

export const getUser = () => {
  const item = localStorage.getItem(userKey);
  if (item) {
    return JSON.parse(item) as User;
  }
  return null;
};

export const saveUser = (email: string, password: string, isAdmin: boolean) => {
  const credentials = Buffer.from(`${email}:${password}`).toString('base64');
  const ret = {email, credentials, isAdmin};
  localStorage.setItem(userKey, JSON.stringify({email, credentials, isAdmin}));
  return ret;
};

export const resetUser = () => localStorage.removeItem(userKey);

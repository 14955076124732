import {FocusStyleManager, NonIdealState, Spinner} from '@blueprintjs/core';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import 'normalize.css/normalize.css';
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {ErrorBoundary} from 'react-error-boundary';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import {App} from './App';
import reportWebVitals from './reportWebVitals';
import i18n from './i18n';
import {I18nextProvider} from 'react-i18next';

FocusStyleManager.onlyShowFocusOnTabs();

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary
        fallback={<NonIdealState icon="error" title="Unknown error happened" />}
        onError={(err, info) => {
          console.error(`Uncaught error\n${info.componentStack}\n`, err);
        }}
      >
        <Suspense
          fallback={<NonIdealState icon={<Spinner />} title="Loading..." />}
        >
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
        </Suspense>
      </ErrorBoundary>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import {Spinner, SpinnerSize} from '@blueprintjs/core';
import {stylesheet, classes as cx} from 'typestyle';
import {Colors} from './colors';

const classes = stylesheet({
  button: {
    width: 250,
    height: 56,
    background: 'rgba(140, 60, 250, 0.2)',
    borderRadius: '4px',
    border: 'none',
    color: '#fff',
    cursor: 'pointer',
    transition: '200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    $nest: {
      '&:hover': {
        background: 'inherit',
      },
      '&[data-type="primary"]': {
        background: Colors.JPVioletMain,
        boxShadow: '0px 0px 10px rgba(140, 60, 250, 0.35)',
      },
      '&:disabled': {
        background: '#190633',
        boxShadow: 'none',
        cursor: 'not-allowed',
        color: 'rgba(255, 255, 255, 0.5)',
      },
    },
  },
  buttonGold: {
    background:
      'linear-gradient(283.68deg, #C7892C 20.43%, #FEE98F 87.75%) !important',
    boxShadow: '0px 0px 8px rgba(254, 233, 143, 0.35) !important',
    color: '#674909',
  },
});

type Props = {
  text?: string;
  primary?: boolean;
  disabled?: boolean;
  loading?: boolean;
  gold?: boolean;
  fill?: boolean;
} & React.HTMLAttributes<HTMLButtonElement>;

export const Button: React.FC<Props> = ({
  disabled,
  text,
  primary,
  loading,
  gold,
  fill,
  children,
  ...props
}) => {
  return (
    <button
      {...props}
      style={fill ? {width: '100%'} : {}}
      disabled={disabled}
      className={cx(
        classes.button,
        gold ? classes.buttonGold : '',
        props.className,
      )}
      data-type={primary ? 'primary' : ''}
    >
      {!!loading ? (
        <Spinner size={SpinnerSize.SMALL} />
      ) : children ? (
        children
      ) : (
        `${text ?? ''}`
      )}
    </button>
  );
};

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../../google/protobuf/timestamp";

export const protobufPackage = "deeplay.jackpoker_fast_funnel.admin.v1";

export interface CheckCredentialsRequest {}

export interface CheckCredentialsResponse {}

export interface ListAgentsRequest {}

export interface ListAgentsResponse {
  items: ListAgentsResponse_Item[];
}

export interface ListAgentsResponse_Item {
  email: string;
  balanceCents: Long;
}

export interface ListAgentOperationsRequest {
  agentEmail: string;
}

export interface ListAgentOperationsResponse {
  items: ListAgentOperationsResponse_Item[];
}

export interface ListAgentOperationsResponse_Item {
  /** Уникальный идентификатор транзакции. */
  transactionId: string;
  /** Больше нуля — пополнение баланса, меньше нуля — списание с баланса. */
  deltaCents: Long;
  /** Время совершения операции. */
  timestamp: Timestamp | undefined;
  type?:
    | {
        $case: "balanceChange";
        balanceChange: ListAgentOperationsResponse_Item_BalanceChange;
      }
    | { $case: "purchase"; purchase: ListAgentOperationsResponse_Item_Purchase }
    | {
        $case: "compensation";
        compensation: ListAgentOperationsResponse_Item_Compensation;
      };
}

export interface ListAgentOperationsResponse_Item_BalanceChange {
  /** Email админа, совершившего операцию. */
  adminEmail: string;
}

export interface ListAgentOperationsResponse_Item_Purchase {
  couponId: string;
}

export interface ListAgentOperationsResponse_Item_Compensation {
  couponId: string;
}

export interface SaveAgentRequest {
  email: string;
  password: string;
}

export interface SaveAgentResponse {}

export interface UpdateAgentBalanceRequest {
  agentEmail: string;
  /** Уникальный идентификатор транзакции, должен быть сгенерён клиентом. */
  transactionId: string;
  /** Больше нуля — пополнение баланса, меньше нуля — списание с баланса. */
  deltaCents: Long;
}

export interface UpdateAgentBalanceResponse {}

function createBaseCheckCredentialsRequest(): CheckCredentialsRequest {
  return {};
}

export const CheckCredentialsRequest = {
  encode(
    _: CheckCredentialsRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): CheckCredentialsRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCheckCredentialsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    _: DeepPartial<CheckCredentialsRequest>
  ): CheckCredentialsRequest {
    const message = createBaseCheckCredentialsRequest();
    return message;
  },
};

function createBaseCheckCredentialsResponse(): CheckCredentialsResponse {
  return {};
}

export const CheckCredentialsResponse = {
  encode(
    _: CheckCredentialsResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): CheckCredentialsResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCheckCredentialsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    _: DeepPartial<CheckCredentialsResponse>
  ): CheckCredentialsResponse {
    const message = createBaseCheckCredentialsResponse();
    return message;
  },
};

function createBaseListAgentsRequest(): ListAgentsRequest {
  return {};
}

export const ListAgentsRequest = {
  encode(
    _: ListAgentsRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAgentsRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAgentsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<ListAgentsRequest>): ListAgentsRequest {
    const message = createBaseListAgentsRequest();
    return message;
  },
};

function createBaseListAgentsResponse(): ListAgentsResponse {
  return { items: [] };
}

export const ListAgentsResponse = {
  encode(
    message: ListAgentsResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.items) {
      ListAgentsResponse_Item.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAgentsResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAgentsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.items.push(
            ListAgentsResponse_Item.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<ListAgentsResponse>): ListAgentsResponse {
    const message = createBaseListAgentsResponse();
    message.items =
      object.items?.map((e) => ListAgentsResponse_Item.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListAgentsResponse_Item(): ListAgentsResponse_Item {
  return { email: "", balanceCents: Long.ZERO };
}

export const ListAgentsResponse_Item = {
  encode(
    message: ListAgentsResponse_Item,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    if (!message.balanceCents.isZero()) {
      writer.uint32(16).int64(message.balanceCents);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListAgentsResponse_Item {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAgentsResponse_Item();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.email = reader.string();
          break;
        case 2:
          message.balanceCents = reader.int64() as Long;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<ListAgentsResponse_Item>
  ): ListAgentsResponse_Item {
    const message = createBaseListAgentsResponse_Item();
    message.email = object.email ?? "";
    message.balanceCents =
      object.balanceCents !== undefined && object.balanceCents !== null
        ? Long.fromValue(object.balanceCents)
        : Long.ZERO;
    return message;
  },
};

function createBaseListAgentOperationsRequest(): ListAgentOperationsRequest {
  return { agentEmail: "" };
}

export const ListAgentOperationsRequest = {
  encode(
    message: ListAgentOperationsRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.agentEmail !== "") {
      writer.uint32(10).string(message.agentEmail);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListAgentOperationsRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAgentOperationsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.agentEmail = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<ListAgentOperationsRequest>
  ): ListAgentOperationsRequest {
    const message = createBaseListAgentOperationsRequest();
    message.agentEmail = object.agentEmail ?? "";
    return message;
  },
};

function createBaseListAgentOperationsResponse(): ListAgentOperationsResponse {
  return { items: [] };
}

export const ListAgentOperationsResponse = {
  encode(
    message: ListAgentOperationsResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.items) {
      ListAgentOperationsResponse_Item.encode(
        v!,
        writer.uint32(10).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListAgentOperationsResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAgentOperationsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.items.push(
            ListAgentOperationsResponse_Item.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<ListAgentOperationsResponse>
  ): ListAgentOperationsResponse {
    const message = createBaseListAgentOperationsResponse();
    message.items =
      object.items?.map((e) =>
        ListAgentOperationsResponse_Item.fromPartial(e)
      ) || [];
    return message;
  },
};

function createBaseListAgentOperationsResponse_Item(): ListAgentOperationsResponse_Item {
  return {
    transactionId: "",
    deltaCents: Long.ZERO,
    timestamp: undefined,
    type: undefined,
  };
}

export const ListAgentOperationsResponse_Item = {
  encode(
    message: ListAgentOperationsResponse_Item,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.transactionId !== "") {
      writer.uint32(10).string(message.transactionId);
    }
    if (!message.deltaCents.isZero()) {
      writer.uint32(16).int64(message.deltaCents);
    }
    if (message.timestamp !== undefined) {
      Timestamp.encode(message.timestamp, writer.uint32(26).fork()).ldelim();
    }
    if (message.type?.$case === "balanceChange") {
      ListAgentOperationsResponse_Item_BalanceChange.encode(
        message.type.balanceChange,
        writer.uint32(90).fork()
      ).ldelim();
    }
    if (message.type?.$case === "purchase") {
      ListAgentOperationsResponse_Item_Purchase.encode(
        message.type.purchase,
        writer.uint32(98).fork()
      ).ldelim();
    }
    if (message.type?.$case === "compensation") {
      ListAgentOperationsResponse_Item_Compensation.encode(
        message.type.compensation,
        writer.uint32(106).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListAgentOperationsResponse_Item {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAgentOperationsResponse_Item();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.transactionId = reader.string();
          break;
        case 2:
          message.deltaCents = reader.int64() as Long;
          break;
        case 3:
          message.timestamp = Timestamp.decode(reader, reader.uint32());
          break;
        case 11:
          message.type = {
            $case: "balanceChange",
            balanceChange:
              ListAgentOperationsResponse_Item_BalanceChange.decode(
                reader,
                reader.uint32()
              ),
          };
          break;
        case 12:
          message.type = {
            $case: "purchase",
            purchase: ListAgentOperationsResponse_Item_Purchase.decode(
              reader,
              reader.uint32()
            ),
          };
          break;
        case 13:
          message.type = {
            $case: "compensation",
            compensation: ListAgentOperationsResponse_Item_Compensation.decode(
              reader,
              reader.uint32()
            ),
          };
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<ListAgentOperationsResponse_Item>
  ): ListAgentOperationsResponse_Item {
    const message = createBaseListAgentOperationsResponse_Item();
    message.transactionId = object.transactionId ?? "";
    message.deltaCents =
      object.deltaCents !== undefined && object.deltaCents !== null
        ? Long.fromValue(object.deltaCents)
        : Long.ZERO;
    message.timestamp =
      object.timestamp !== undefined && object.timestamp !== null
        ? Timestamp.fromPartial(object.timestamp)
        : undefined;
    if (
      object.type?.$case === "balanceChange" &&
      object.type?.balanceChange !== undefined &&
      object.type?.balanceChange !== null
    ) {
      message.type = {
        $case: "balanceChange",
        balanceChange:
          ListAgentOperationsResponse_Item_BalanceChange.fromPartial(
            object.type.balanceChange
          ),
      };
    }
    if (
      object.type?.$case === "purchase" &&
      object.type?.purchase !== undefined &&
      object.type?.purchase !== null
    ) {
      message.type = {
        $case: "purchase",
        purchase: ListAgentOperationsResponse_Item_Purchase.fromPartial(
          object.type.purchase
        ),
      };
    }
    if (
      object.type?.$case === "compensation" &&
      object.type?.compensation !== undefined &&
      object.type?.compensation !== null
    ) {
      message.type = {
        $case: "compensation",
        compensation: ListAgentOperationsResponse_Item_Compensation.fromPartial(
          object.type.compensation
        ),
      };
    }
    return message;
  },
};

function createBaseListAgentOperationsResponse_Item_BalanceChange(): ListAgentOperationsResponse_Item_BalanceChange {
  return { adminEmail: "" };
}

export const ListAgentOperationsResponse_Item_BalanceChange = {
  encode(
    message: ListAgentOperationsResponse_Item_BalanceChange,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.adminEmail !== "") {
      writer.uint32(10).string(message.adminEmail);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListAgentOperationsResponse_Item_BalanceChange {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAgentOperationsResponse_Item_BalanceChange();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.adminEmail = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<ListAgentOperationsResponse_Item_BalanceChange>
  ): ListAgentOperationsResponse_Item_BalanceChange {
    const message = createBaseListAgentOperationsResponse_Item_BalanceChange();
    message.adminEmail = object.adminEmail ?? "";
    return message;
  },
};

function createBaseListAgentOperationsResponse_Item_Purchase(): ListAgentOperationsResponse_Item_Purchase {
  return { couponId: "" };
}

export const ListAgentOperationsResponse_Item_Purchase = {
  encode(
    message: ListAgentOperationsResponse_Item_Purchase,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.couponId !== "") {
      writer.uint32(10).string(message.couponId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListAgentOperationsResponse_Item_Purchase {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAgentOperationsResponse_Item_Purchase();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.couponId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<ListAgentOperationsResponse_Item_Purchase>
  ): ListAgentOperationsResponse_Item_Purchase {
    const message = createBaseListAgentOperationsResponse_Item_Purchase();
    message.couponId = object.couponId ?? "";
    return message;
  },
};

function createBaseListAgentOperationsResponse_Item_Compensation(): ListAgentOperationsResponse_Item_Compensation {
  return { couponId: "" };
}

export const ListAgentOperationsResponse_Item_Compensation = {
  encode(
    message: ListAgentOperationsResponse_Item_Compensation,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.couponId !== "") {
      writer.uint32(10).string(message.couponId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListAgentOperationsResponse_Item_Compensation {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAgentOperationsResponse_Item_Compensation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.couponId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<ListAgentOperationsResponse_Item_Compensation>
  ): ListAgentOperationsResponse_Item_Compensation {
    const message = createBaseListAgentOperationsResponse_Item_Compensation();
    message.couponId = object.couponId ?? "";
    return message;
  },
};

function createBaseSaveAgentRequest(): SaveAgentRequest {
  return { email: "", password: "" };
}

export const SaveAgentRequest = {
  encode(
    message: SaveAgentRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    if (message.password !== "") {
      writer.uint32(18).string(message.password);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SaveAgentRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSaveAgentRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.email = reader.string();
          break;
        case 2:
          message.password = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<SaveAgentRequest>): SaveAgentRequest {
    const message = createBaseSaveAgentRequest();
    message.email = object.email ?? "";
    message.password = object.password ?? "";
    return message;
  },
};

function createBaseSaveAgentResponse(): SaveAgentResponse {
  return {};
}

export const SaveAgentResponse = {
  encode(
    _: SaveAgentResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SaveAgentResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSaveAgentResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<SaveAgentResponse>): SaveAgentResponse {
    const message = createBaseSaveAgentResponse();
    return message;
  },
};

function createBaseUpdateAgentBalanceRequest(): UpdateAgentBalanceRequest {
  return { agentEmail: "", transactionId: "", deltaCents: Long.ZERO };
}

export const UpdateAgentBalanceRequest = {
  encode(
    message: UpdateAgentBalanceRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.agentEmail !== "") {
      writer.uint32(10).string(message.agentEmail);
    }
    if (message.transactionId !== "") {
      writer.uint32(18).string(message.transactionId);
    }
    if (!message.deltaCents.isZero()) {
      writer.uint32(24).int64(message.deltaCents);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): UpdateAgentBalanceRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAgentBalanceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.agentEmail = reader.string();
          break;
        case 2:
          message.transactionId = reader.string();
          break;
        case 3:
          message.deltaCents = reader.int64() as Long;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<UpdateAgentBalanceRequest>
  ): UpdateAgentBalanceRequest {
    const message = createBaseUpdateAgentBalanceRequest();
    message.agentEmail = object.agentEmail ?? "";
    message.transactionId = object.transactionId ?? "";
    message.deltaCents =
      object.deltaCents !== undefined && object.deltaCents !== null
        ? Long.fromValue(object.deltaCents)
        : Long.ZERO;
    return message;
  },
};

function createBaseUpdateAgentBalanceResponse(): UpdateAgentBalanceResponse {
  return {};
}

export const UpdateAgentBalanceResponse = {
  encode(
    _: UpdateAgentBalanceResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): UpdateAgentBalanceResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAgentBalanceResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    _: DeepPartial<UpdateAgentBalanceResponse>
  ): UpdateAgentBalanceResponse {
    const message = createBaseUpdateAgentBalanceResponse();
    return message;
  },
};

/**
 * Все запросы должны иметь хедер с кредлами админа:
 *
 *   Authorization: Basic base64(email:password)
 *
 * При неверных данных в хедере вернётся ошибка с кодом UNAUTHENTICATED.
 */
export const AdminDefinition = {
  name: "Admin",
  fullName: "deeplay.jackpoker_fast_funnel.admin.v1.Admin",
  methods: {
    /** Проверяет валидность кредлов. */
    checkCredentials: {
      name: "CheckCredentials",
      requestType: CheckCredentialsRequest,
      requestStream: false,
      responseType: CheckCredentialsResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "NO_SIDE_EFFECTS",
      },
    },
    /** Возвращает список агентов. */
    listAgents: {
      name: "ListAgents",
      requestType: ListAgentsRequest,
      requestStream: false,
      responseType: ListAgentsResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "NO_SIDE_EFFECTS",
      },
    },
    /** Возвращает историю операций — пополнений баланса и покупок. */
    listAgentOperations: {
      name: "ListAgentOperations",
      requestType: ListAgentOperationsRequest,
      requestStream: false,
      responseType: ListAgentOperationsResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "NO_SIDE_EFFECTS",
      },
    },
    /** Создаёт или обновляет агента. */
    saveAgent: {
      name: "SaveAgent",
      requestType: SaveAgentRequest,
      requestStream: false,
      responseType: SaveAgentResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "IDEMPOTENT",
      },
    },
    /** Пополняет или списывает деньги с баланса агента. */
    updateAgentBalance: {
      name: "UpdateAgentBalance",
      requestType: UpdateAgentBalanceRequest,
      requestStream: false,
      responseType: UpdateAgentBalanceResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "IDEMPOTENT",
      },
    },
  },
} as const;

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Long
  ? string | number | Long
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string }
  ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & {
      $case: T["$case"];
    }
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

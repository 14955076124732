import assert from 'assert';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {stylesheet, classes as cx} from 'typestyle';
import {useAgentContext} from '..';
import {useAuth} from '../../../Auth';
import {saveLanguage} from '../../../i18n';
import {formatCents} from '../../../utils/formatCents';
import {ContactUs} from '../../common/ContactUs';
import {About} from '../components/About';
import {AgentAlert} from '../components/AgentAlert';

const classes = stylesheet({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '0 auto 64px',
    maxWidth: 1200,
    backgroundColor: '#1E073D',
    color: '#FFF',
    borderLeft: '1px solid rgba(0,0,0,0.1)',
    borderRight: '1px solid rgba(0,0,0,0.1)',
    fontFamily: 'Poppins',
  },
  header: {
    padding: '24px 36px',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '0px 0px 12px 12px',
    overflow: 'hidden',
    background:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), linear-gradient(360deg, #8032FF 0%, rgba(128, 50, 255, 0) 229.43%)',
    mixBlendMode: 'normal',
    position: 'relative',

    $nest: {
      '&::after': {
        content: '""',
        display: 'block',
        width: 247,
        height: 247,
        position: 'absolute',
        right: -110,
        top: -137,
        background: '#F0644B',
        opacity: '0.09',
        filter: 'blur(65px)',
        zIndex: 1,
      },
      '&::before': {
        content: '""',
        display: 'block',
        width: 178,
        height: 178,
        position: 'absolute',
        bottom: -89,
        left: -89,
        background: '#F0644B',
        opacity: '0.09',
        filter: 'blur(65px)',
        zIndex: 1,
      },
    },
  },
  section: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    zIndex: 2,
  },
  flexRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rightSection: {
    width: 140,
    alignItems: 'flex-end',
  },
  heading: {
    marginTop: 0,
    fontSize: 20,
    lineHeight: '24px',
    fontWeight: 600,
  },
  languageSwitch: {
    margin: 0,
    paddingTop: 3,
    width: 34,
    height: 19,
    fontSize: 10,
    border: '1px solid #fff',
    borderRadius: 10,
    textAlign: 'center',
  },
  logout: {
    background: 'url(/logout.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'center',
    backgroundPositionY: 0,
    fontSize: 10,
    width: 40,
    textAlign: 'center',
    paddingTop: 24,
    color: 'rgba(255, 255, 255, 0.6)',
  },
  support: {
    background: 'url(agent/support.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'center',
    backgroundPositionY: 0,
    fontSize: 10,
    width: 40,
    textAlign: 'center',
    paddingTop: 24,
    color: 'rgba(255, 255, 255, 0.6)',
  },
  about: {
    background: 'url(agent/info.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'center',
    backgroundPositionY: 0,
    fontSize: 10,
    width: 45,
    textAlign: 'center',
    paddingTop: 24,
    color: 'rgba(255, 255, 255, 0.6)',
  },
  email: {
    display: 'flex',
    marginBottom: 25,
    alignItems: 'center',
    fontSize: 12,
    lineHeight: '24px',
    fontWeight: 500,
    color: 'rgba(255,255,255,0.6)',
  },
  balance: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    $nest: {
      '& h2': {
        fontSize: 16,
        lineHeight: 2,
        opacity: 0.64,
        fontWeight: 400,
      },
    },
  },
  subHeading: {
    fontSize: 14,
    lineHeight: '16px',
    color: 'rgba(255,255,255,0.6)',
  },
  balanceString: {
    margin: 0,
    fontSize: 24,
    lineHeight: 1.5,
    fontWeight: 500,
  },
  logoutMessage: {
    width: 190,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 36px',
  },
  card: {
    marginTop: 4,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '12px 16px',
    borderRadius: 9,
    background: 'rgba(0,0,0,0.3)',
    textAlign: 'left',

    $nest: {
      '&:first-child': {
        marginTop: 0,
      },
    },
  },
  cardInner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardHeading: {
    margin: 0,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '16px',
    color: '#fff',
  },
  couponsText: {
    margin: 0,
    fontSize: 10,
    color: 'rgba(255,255,255,0.7)',
  },
  money: {
    margin: 0,
    fontSize: 20,
    minWidth: 130,
    textAlign: 'right',
  },
  green: {
    color: '#3D9941',
  },
  red: {
    color: '#B21B1B',
  },
  profit: {
    padding: '12px 16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 20,
  },
});

export const Profile = () => {
  const {t, i18n} = useTranslation();
  const {user, logOut} = useAuth();
  const [isContactUsOpen, setIsContactUsOpen] = useState<boolean>(false);
  const [isAboutOpen, setIsAboutOpen] = useState<boolean>(false);

  assert(user != null);

  const {balanceCents, soldCoupons, receivedCents, cashedOutCents} =
    useAgentContext();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onLogoutClick = () => {
    setIsOpen(true);
  };

  const onConfirm = () => {
    logOut();
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onLanguageSwitch = () => {
    const currentLanguage = i18n.language;
    const language = currentLanguage === 'en' ? 'pt' : 'en';
    i18n.changeLanguage(language);
    saveLanguage(language);
  };

  const onSupportClick = () => setIsContactUsOpen(true);
  const onSupportClose = () => setIsContactUsOpen(false);

  const onAboutClick = () => setIsAboutOpen(true);
  const onAboutClose = () => setIsAboutOpen(false);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.section}>
          <h1 className={classes.heading}>{t('Profile')}</h1>
          <span className={classes.email}>
            <img width={32} height={32} src="/agent/user.svg" alt="" />
            &nbsp;&nbsp;&nbsp;{user.email}
          </span>
          <span className={classes.subHeading}>{t('Balance')}</span>
          <p className={classes.balanceString}>{formatCents(balanceCents)}</p>
        </div>
        <div className={cx(classes.section, classes.rightSection)}>
          <div className={classes.flexRow}>
            <div onClick={onLanguageSwitch} className={classes.languageSwitch}>
              {i18n.language === 'en' ? 'PRT' : 'ENG'}
            </div>
            <div className={classes.logout} onClick={onLogoutClick}>
              {t('Log out')}
            </div>
          </div>
          <div className={classes.flexRow}>
            <div className={classes.about} onClick={onAboutClick}>
              {t('About coupons')}
            </div>
            <div className={classes.support} onClick={onSupportClick}>
              {t('Support')}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.card}>
          <div className={classes.cardInner}>
            <img
              style={{marginRight: 15}}
              src="agent/up.svg"
              width={24}
              height={24}
              alt=""
            />
            <div>
              <p className={classes.cardHeading}>{t('Received')}</p>
              <p className={classes.couponsText}>
                {t('Coupons')}: {soldCoupons}
              </p>
            </div>
          </div>
          <p className={cx(classes.money, classes.green)}>
            +{formatCents(receivedCents)}
          </p>
        </div>
        <div className={classes.card}>
          <div className={classes.cardInner}>
            <img
              style={{marginRight: 15}}
              src="agent/down.svg"
              width={24}
              height={24}
              alt=""
            />
            <p className={classes.cardHeading}>{t('Cashed out')}</p>
          </div>
          <p className={cx(classes.money, classes.red)}>
            -{formatCents(cashedOutCents)}
          </p>
        </div>
        <div className={classes.profit}>
          <div>{t('Profit')}:</div>
          <div style={{fontWeight: 400}}>
            {formatCents(receivedCents - cashedOutCents)}
          </div>
        </div>
      </div>
      <ContactUs onClose={onSupportClose} isOpen={isContactUsOpen} />
      <About onClose={onAboutClose} isOpen={isAboutOpen} />
      <AgentAlert
        intent="danger"
        cancelButtonText={t('Cancel')}
        confirmButtonText={t('Log out')}
        canEscapeKeyCancel
        canOutsideClickCancel
        onClose={onClose}
        onConfirm={onConfirm}
        isOpen={isOpen}
      >
        <p className={classes.logoutMessage}>
          {t('Are you sure you want to log out?')}
        </p>
      </AgentAlert>
    </div>
  );
};

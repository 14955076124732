import {MutationFunction} from 'react-query';
import {CustomerClient} from '../../../client';
import {
  DeepPartial,
  ResumeGameRequest,
  ResumeGameResponse,
} from '../../../proto/deeplay/jackpoker_fast_funnel/customer/v1/customer';

export type ResumeGameMutationVariables = DeepPartial<ResumeGameRequest>;

export type ResumeGameMutationData = ResumeGameResponse;

export type ResumeGameMutationDeps = {
  customerClient: CustomerClient;
};

export type ResumeGameMutationFn = MutationFunction<
  ResumeGameMutationData,
  ResumeGameMutationVariables
>;

export function ResumeGameMutationFn({
  customerClient,
}: ResumeGameMutationDeps): ResumeGameMutationFn {
  return async request => {
    return await customerClient.resumeGame(request);
  };
}

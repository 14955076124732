import {Dialog} from '@blueprintjs/core';
import {useTranslation} from 'react-i18next';
import {stylesheet, cssRaw} from 'typestyle';
import {ListOperationsResponse_Item as Operation} from '../../../proto/deeplay/jackpoker_fast_funnel/agent/v1/agent';

cssRaw(`
@keyframes slidein-details {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}
`);

const classes = stylesheet({
  root: {
    animation: '0.2s slidein-details both',
    padding: '24px 26px',
    borderRadius: '12px 12px 0px 0px',
    margin: 0,
    width: '100%',
    height: '100%',
    background: '#1E073D',
    color: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: 0,
    fontFamily: 'Poppins',
    textAlign: 'left',
  },
  heading: {
    fontSize: 20,
    fontWeight: 500,
  },
  backIcon: {
    marginRight: 25,
  },
});

export const CouponDetailsDialog: React.FC<{
  operation?: Operation;
  embedded?: boolean;
  isOpen: boolean;
  onClose: () => void;
}> = ({operation, isOpen, onClose, embedded}) => {
  const {t} = useTranslation();

  if (!operation) {
    return <></>;
  }

  return (
    <Dialog className={classes.root} isOpen={isOpen} onClose={onClose}>
      <h1 className={classes.heading}>
        {embedded ? (
          <span onClick={onClose}>
            <img
              className={classes.backIcon}
              width={21}
              height={14}
              src="agent/back-icon.svg"
              alt=""
            />
          </span>
        ) : (
          ''
        )}
        {t('Coupon details')}
      </h1>
    </Dialog>
  );
};

import React from 'react';
import {stylesheet, classes as cx} from 'typestyle';
import {BrowserRouter as Router, Outlet, Route, Routes} from 'react-router-dom';
import {Login} from './Login';
import {Admin} from './Admin';
import {AuthProvider, RequireAuth} from '../Auth';
import {Customer} from './Customer';
import {PokerLayout} from './PokerLayout';
import Helmet from 'react-helmet';
import {LeaveGame} from './Customer/LeaveGame';
import {Registration} from './Customer/Registration';
import {Coupons} from './Agent/pages/Coupons';
import {Profile} from './Agent/pages/Profile';
import {TabBar} from './Agent/components/TabBar';
import {AgentProvider} from './Agent';

const css = stylesheet({
  root: {
    height: '100%',
    display: 'flex',
    margin: '0 auto',
  },
});

export const App: React.FC = () => {
  return (
    <div className={cx(css.root)}>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Helmet>
      <AuthProvider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <RequireAuth>
                  <AgentProvider>
                    <Outlet />
                  </AgentProvider>
                </RequireAuth>
              }
            >
              <Route
                index
                element={
                  <>
                    <Coupons />
                    <TabBar />
                  </>
                }
              />
              <Route
                path="profile"
                element={
                  <>
                    <Profile />
                    <TabBar />
                  </>
                }
              />
            </Route>
            <Route
              path="admin"
              element={
                <RequireAuth>
                  <Admin />
                </RequireAuth>
              }
            />
            <Route path="/login" element={<PokerLayout />}>
              <Route index element={<Login />} />
            </Route>

            <Route path="/admin/login" element={<PokerLayout />}>
              <Route index element={<Login />} />
            </Route>
            <Route path="/customer" element={<PokerLayout />}>
              <Route path=":couponId" element={<Customer />} />
              <Route path="leaveGame/:couponId" element={<LeaveGame />} />
              <Route path="registration/:couponId" element={<Registration />} />
            </Route>
          </Routes>
        </Router>
      </AuthProvider>
    </div>
  );
};

App.displayName = 'App';

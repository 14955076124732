import {Outlet} from 'react-router-dom';
import {fontFace, stylesheet} from 'typestyle';

fontFace({
  fontFamily: 'Poppins',
  src: 'url(/Poppins/Poppins-Regular.ttf)',
});

fontFace({
  fontFamily: 'Poppins',
  src: 'url(/Poppins/Poppins-Light.ttf)',
  fontWeight: 300,
});

fontFace({
  fontFamily: 'Poppins',
  src: 'url(/Poppins/Poppins-SemiBold.ttf)',
  fontWeight: 500,
});

fontFace({
  fontFamily: 'Poppins',
  src: 'url(/Poppins/Poppins-Bold.ttf)',
  fontWeight: 600,
});

fontFace({
  fontFamily: 'Poppins',
  src: 'url(/Poppins/Poppins-Bold.ttf)',
  fontWeight: 700,
});

const classes = stylesheet({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Poppins',
    color: '#fff',
    background: '#111111',
    overflow: 'hidden',
    zIndex: 0,
  },
  gradient: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), linear-gradient(360deg, #8032FF 6.92%, rgba(128, 50, 255, 0) 100%)`,
    mixBlendMode: 'normal',
    zIndex: '-1',
  },
  back: {
    backgroundColor: '#8C3CFA',
    width: '100%',
    height: '100%',
    mixBlendMode: 'multiply',
    position: 'absolute',
    overflow: 'hidden',
    zIndex: '-2',
  },
  ellipse1: {
    position: 'absolute',
    width: 320,
    height: 320,
    top: -160,
    right: -183,
    background: '#F0644B',
    opacity: 0.17,
    filter: 'blur(65px)',
  },
  ellipse2: {
    position: 'absolute',
    width: 140,
    height: 140,
    right: -89,
    bottom: -70,
    background: '#C8A0F0',
    opacity: 0.58,
    filter: 'blur(65px)',
  },
  ellipse3: {
    position: 'absolute',
    width: 140,
    height: 140,
    left: -70,
    bottom: 'calc(50% - 70px)',
    background: '#A756F9',
    opacity: 0.36,
    filter: 'blur(65px)',
  },
  outlet: {
    position: 'relative',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    maxHeight: 900,
    maxWidth: 420,

    $nest: {
      '& p': {
        maxWidth: 260,
      },
    },
  },
});

type Props = {
  noRouter?: boolean;
};

export const PokerLayout: React.FC<Props> = ({noRouter, children}) => {
  return (
    <div className={classes.root}>
      <div className={classes.back} />
      <div className={classes.ellipse1} />
      <div className={classes.ellipse2} />
      <div className={classes.ellipse3} />
      <div className={classes.gradient} />
      <div className={classes.outlet}>{noRouter ? children : <Outlet />}</div>
    </div>
  );
};

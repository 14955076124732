import {Popover, Position, PopoverInteractionKind} from '@blueprintjs/core';
import React, {useState} from 'react';
import {stylesheet, classes as cx} from 'typestyle';
import {Classes} from '@blueprintjs/core';
import {Colors} from './colors';
import {ListCountriesResponse_Item as Country} from '../../proto/deeplay/jackpoker_fast_funnel/customer/v1/customer';

type Props = {
  countries: Country[];
  className?: string;
  onSelect?: (country: Country) => void;
};

const classes = stylesheet({
  popover: {
    width: '100%',
    $nest: {
      [`& .${Classes.POPOVER_CONTENT}`]: {
        background: '#190633',
        width: 248,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        border: 'none',
      },
    },
  },
  option: {
    height: 55,
    display: 'flex',
    alignItems: 'center',
    padding: 16,
    borderTop: `1px solid ${Colors.JPViolet4}`,
    cursor: 'pointer',
    $nest: {
      '&:hover': {
        background: Colors.JPViolet4,
      },
      '&:first-child': {
        borderTop: 'none',
      },
    },
  },
  button: {
    width: 248,
    padding: 16,
    background: 'rgba(0, 0, 0, 0.5)',
    border: '1px solid rgba(0,0,0,0)',
    boxShadow: 'none',
    borderRadius: 4,
    display: 'block',
    fontSize: 14,
    lineHeight: '21px',
    color: Colors.JPViolet4,
    textAlign: 'left',
    position: 'relative',
    $nest: {
      '&::after': {
        content: '""',
        display: 'block',
        background: 'url(/arrow.svg)',
        backgroundRepeat: 'no-repeat',
        width: 10,
        height: 6,
        top: 24,
        right: 20,
        position: 'absolute',
      },
    },
  },
  buttonOpened: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    $nest: {
      '&::after': {
        transform: 'rotate(180deg)',
      },
    },
  },
  buttonSelected: {
    paddingTop: 24,
    paddingBottom: 8,
  },
  value: {
    color: '#FFF',
  },
  label: {
    transition: '200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  },
  labelSelected: {
    position: 'absolute',
    top: 8,
    fontSize: 10,
  },
});

export const CountrySelect: React.FC<Props> = ({
  countries,
  className,
  onSelect,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [country, setCountry] = useState<Country | undefined>(undefined);

  const onOptionClick = (selectedCountry: Country) => {
    setCountry(selectedCountry);
    onSelect && onSelect(selectedCountry);
    setIsOpen(false);
  };
  const onInteraction = (nextOpen: boolean) => {
    setIsOpen(nextOpen);
  };

  return (
    <>
      <Popover
        className={className}
        interactionKind={PopoverInteractionKind.CLICK}
        popoverClassName={classes.popover}
        minimal
        usePortal={false}
        position={Position.BOTTOM}
        isOpen={isOpen}
        onInteraction={onInteraction}
      >
        <div
          className={cx(
            classes.button,
            isOpen ? classes.buttonOpened : '',
            country ? classes.buttonSelected : '',
          )}
        >
          <span
            className={cx(classes.label, country ? classes.labelSelected : '')}
          >
            Select your country
          </span>
          {country ? <span className={classes.value}>{country.name}</span> : ''}
        </div>
        <>
          {countries
            .concat([
              {
                id: '0',
                name: 'Other',
                phoneFormat: '+99999999999',
                isoCode: '',
                dialCode: 0,
              },
            ])
            .map(option => (
              <div
                onClick={() => onOptionClick(option)}
                className={classes.option}
                key={option.name}
              >
                {option.name}
              </div>
            ))}
        </>
      </Popover>
    </>
  );
};

import {retryMiddleware, RetryOptions} from 'nice-grpc-client-middleware-retry';
import {Client, createChannel, createClientFactory} from 'nice-grpc-web';
import {AdminDefinition} from './proto/deeplay/jackpoker_fast_funnel/admin/v1/admin';
import {AgentDefinition} from './proto/deeplay/jackpoker_fast_funnel/agent/v1/agent';
import {CustomerDefinition} from './proto/deeplay/jackpoker_fast_funnel/customer/v1/customer';
import {AuthMiddleware} from './utils/authMiddleware';

import {loggerMiddleware} from './utils/loggerMiddleware';

const channel = createChannel(
  process.env.REACT_APP_GRPC_URL ?? window.location.origin,
);

const clientFactory = createClientFactory()
  .use(loggerMiddleware)
  .use(retryMiddleware);

export const adminClient = clientFactory.create(AdminDefinition, channel);
export const agentClient = clientFactory.create(AgentDefinition, channel);

export type AdminClient = Client<typeof AdminDefinition, RetryOptions>;
export type AgentClient = Client<typeof AgentDefinition, RetryOptions>;
export type CustomerClient = Client<typeof CustomerDefinition, RetryOptions>;

export function AdminClient(credentials: string) {
  return clientFactory
    .use(AuthMiddleware(credentials))
    .create(AdminDefinition, channel);
}

export function AgentClient(credentials: string) {
  return clientFactory
    .use(AuthMiddleware(credentials))
    .create(AgentDefinition, channel);
}

export function CustomerClient() {
  return clientFactory.create(CustomerDefinition, channel);
}

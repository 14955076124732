import {MutationFunction, QueryClient} from 'react-query';
import {CustomerClient} from '../../../client';
import {
  DeepPartial,
  StartRegistrationRequest,
  StartRegistrationResponse,
} from '../../../proto/deeplay/jackpoker_fast_funnel/customer/v1/customer';

export type StartRegistrationMutationVariables =
  DeepPartial<StartRegistrationRequest>;

export type StartRegistrationMutationData = StartRegistrationResponse;

export type StartRegistrationMutationDeps = {
  customerClient: CustomerClient;
};

export type StartRegistrationMutationFn = MutationFunction<
  StartRegistrationMutationData,
  StartRegistrationMutationVariables
>;

export function StartRegistrationMutationFn({
  customerClient,
}: StartRegistrationMutationDeps): StartRegistrationMutationFn {
  return async request => {
    return await customerClient.startRegistration(request);
  };
}

export function postStartRegistrationMutation(
  queryClient: QueryClient,
  data: StartRegistrationMutationData,
  request: StartRegistrationMutationVariables,
) {
  queryClient.invalidateQueries(['get-registration-state']);
}

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../../google/protobuf/timestamp";

export const protobufPackage = "deeplay.jackpoker_fast_funnel.agent.v1";

export interface CheckCredentialsRequest {}

export interface CheckCredentialsResponse {}

export interface GetBalanceRequest {}

export interface GetBalanceResponse {
  balanceCents: Long;
}

export interface ListOperationsRequest {}

export interface ListOperationsResponse {
  items: ListOperationsResponse_Item[];
}

export interface ListOperationsResponse_Item {
  /** Уникальный идентификатор транзакции. */
  transactionId: string;
  /** Больше нуля — пополнение баланса, меньше нуля — списание с баланса. */
  deltaCents: Long;
  /** Время совершения операции. */
  timestamp: Timestamp | undefined;
  type?:
    | {
        $case: "balanceChange";
        balanceChange: ListOperationsResponse_Item_BalanceChange;
      }
    | { $case: "purchase"; purchase: ListOperationsResponse_Item_Purchase }
    | {
        $case: "compensation";
        compensation: ListOperationsResponse_Item_Compensation;
      };
}

export interface ListOperationsResponse_Item_BalanceChange {}

export interface ListOperationsResponse_Item_Purchase {
  couponId: string;
  stateChangedAt: Timestamp | undefined;
  amountCents: Long;
  state?:
    | {
        $case: "pending";
        pending: ListOperationsResponse_Item_Purchase_Pending;
      }
    | {
        $case: "preGame";
        preGame: ListOperationsResponse_Item_Purchase_PreGame;
      }
    | { $case: "inGame"; inGame: ListOperationsResponse_Item_Purchase_InGame }
    | {
        $case: "gameFinished";
        gameFinished: ListOperationsResponse_Item_Purchase_GameFinished;
      }
    | {
        $case: "cashOutRequested";
        cashOutRequested: ListOperationsResponse_Item_Purchase_CashOutRequested;
      }
    | {
        $case: "cashedOut";
        cashedOut: ListOperationsResponse_Item_Purchase_CashedOut;
      }
    | {
        $case: "convertedToJackpoker";
        convertedToJackpoker: ListOperationsResponse_Item_Purchase_ConvertedToJackPoker;
      };
}

export interface ListOperationsResponse_Item_Purchase_Pending {}

export interface ListOperationsResponse_Item_Purchase_PreGame {}

export interface ListOperationsResponse_Item_Purchase_InGame {
  bigBlindCents: Long;
  nickname: string;
}

export interface ListOperationsResponse_Item_Purchase_GameFinished {
  bigBlindCents: Long;
  nickname: string;
  /**
   * Размер выигрыша в центах, т.е. полное количество денег, выведенных со
   * стола.
   * Если кастомер всё проиграл — то 0.
   */
  resultCents: Long;
}

export interface ListOperationsResponse_Item_Purchase_CashOutRequested {
  bigBlindCents: Long;
  nickname: string;
  /**
   * Размер выигрыша в центах, т.е. полное количество денег, выведенных со
   * стола.
   * Если кастомер всё проиграл — то 0.
   */
  resultCents: Long;
}

export interface ListOperationsResponse_Item_Purchase_CashedOut {
  bigBlindCents: Long;
  nickname: string;
  /**
   * Размер выигрыша в центах, т.е. полное количество денег, выведенных со
   * стола.
   * Если кастомер всё проиграл — то 0.
   */
  resultCents: Long;
}

export interface ListOperationsResponse_Item_Purchase_ConvertedToJackPoker {}

export interface ListOperationsResponse_Item_Compensation {
  couponId: string;
}

export interface BuyCouponRequest {
  /** Уникальный идентификатор транзакции, должен быть сгенерён клиентом. */
  transactionId: string;
}

export interface BuyCouponResponse {
  couponId: string;
}

export interface CashOutCouponRequest {
  couponId: string;
}

export interface CashOutCouponResponse {}

function createBaseCheckCredentialsRequest(): CheckCredentialsRequest {
  return {};
}

export const CheckCredentialsRequest = {
  encode(
    _: CheckCredentialsRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): CheckCredentialsRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCheckCredentialsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    _: DeepPartial<CheckCredentialsRequest>
  ): CheckCredentialsRequest {
    const message = createBaseCheckCredentialsRequest();
    return message;
  },
};

function createBaseCheckCredentialsResponse(): CheckCredentialsResponse {
  return {};
}

export const CheckCredentialsResponse = {
  encode(
    _: CheckCredentialsResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): CheckCredentialsResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCheckCredentialsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    _: DeepPartial<CheckCredentialsResponse>
  ): CheckCredentialsResponse {
    const message = createBaseCheckCredentialsResponse();
    return message;
  },
};

function createBaseGetBalanceRequest(): GetBalanceRequest {
  return {};
}

export const GetBalanceRequest = {
  encode(
    _: GetBalanceRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetBalanceRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBalanceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<GetBalanceRequest>): GetBalanceRequest {
    const message = createBaseGetBalanceRequest();
    return message;
  },
};

function createBaseGetBalanceResponse(): GetBalanceResponse {
  return { balanceCents: Long.ZERO };
}

export const GetBalanceResponse = {
  encode(
    message: GetBalanceResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (!message.balanceCents.isZero()) {
      writer.uint32(8).int64(message.balanceCents);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetBalanceResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBalanceResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.balanceCents = reader.int64() as Long;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<GetBalanceResponse>): GetBalanceResponse {
    const message = createBaseGetBalanceResponse();
    message.balanceCents =
      object.balanceCents !== undefined && object.balanceCents !== null
        ? Long.fromValue(object.balanceCents)
        : Long.ZERO;
    return message;
  },
};

function createBaseListOperationsRequest(): ListOperationsRequest {
  return {};
}

export const ListOperationsRequest = {
  encode(
    _: ListOperationsRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListOperationsRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOperationsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<ListOperationsRequest>): ListOperationsRequest {
    const message = createBaseListOperationsRequest();
    return message;
  },
};

function createBaseListOperationsResponse(): ListOperationsResponse {
  return { items: [] };
}

export const ListOperationsResponse = {
  encode(
    message: ListOperationsResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.items) {
      ListOperationsResponse_Item.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListOperationsResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOperationsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.items.push(
            ListOperationsResponse_Item.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<ListOperationsResponse>
  ): ListOperationsResponse {
    const message = createBaseListOperationsResponse();
    message.items =
      object.items?.map((e) => ListOperationsResponse_Item.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseListOperationsResponse_Item(): ListOperationsResponse_Item {
  return {
    transactionId: "",
    deltaCents: Long.ZERO,
    timestamp: undefined,
    type: undefined,
  };
}

export const ListOperationsResponse_Item = {
  encode(
    message: ListOperationsResponse_Item,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.transactionId !== "") {
      writer.uint32(10).string(message.transactionId);
    }
    if (!message.deltaCents.isZero()) {
      writer.uint32(16).int64(message.deltaCents);
    }
    if (message.timestamp !== undefined) {
      Timestamp.encode(message.timestamp, writer.uint32(26).fork()).ldelim();
    }
    if (message.type?.$case === "balanceChange") {
      ListOperationsResponse_Item_BalanceChange.encode(
        message.type.balanceChange,
        writer.uint32(90).fork()
      ).ldelim();
    }
    if (message.type?.$case === "purchase") {
      ListOperationsResponse_Item_Purchase.encode(
        message.type.purchase,
        writer.uint32(98).fork()
      ).ldelim();
    }
    if (message.type?.$case === "compensation") {
      ListOperationsResponse_Item_Compensation.encode(
        message.type.compensation,
        writer.uint32(106).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListOperationsResponse_Item {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOperationsResponse_Item();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.transactionId = reader.string();
          break;
        case 2:
          message.deltaCents = reader.int64() as Long;
          break;
        case 3:
          message.timestamp = Timestamp.decode(reader, reader.uint32());
          break;
        case 11:
          message.type = {
            $case: "balanceChange",
            balanceChange: ListOperationsResponse_Item_BalanceChange.decode(
              reader,
              reader.uint32()
            ),
          };
          break;
        case 12:
          message.type = {
            $case: "purchase",
            purchase: ListOperationsResponse_Item_Purchase.decode(
              reader,
              reader.uint32()
            ),
          };
          break;
        case 13:
          message.type = {
            $case: "compensation",
            compensation: ListOperationsResponse_Item_Compensation.decode(
              reader,
              reader.uint32()
            ),
          };
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<ListOperationsResponse_Item>
  ): ListOperationsResponse_Item {
    const message = createBaseListOperationsResponse_Item();
    message.transactionId = object.transactionId ?? "";
    message.deltaCents =
      object.deltaCents !== undefined && object.deltaCents !== null
        ? Long.fromValue(object.deltaCents)
        : Long.ZERO;
    message.timestamp =
      object.timestamp !== undefined && object.timestamp !== null
        ? Timestamp.fromPartial(object.timestamp)
        : undefined;
    if (
      object.type?.$case === "balanceChange" &&
      object.type?.balanceChange !== undefined &&
      object.type?.balanceChange !== null
    ) {
      message.type = {
        $case: "balanceChange",
        balanceChange: ListOperationsResponse_Item_BalanceChange.fromPartial(
          object.type.balanceChange
        ),
      };
    }
    if (
      object.type?.$case === "purchase" &&
      object.type?.purchase !== undefined &&
      object.type?.purchase !== null
    ) {
      message.type = {
        $case: "purchase",
        purchase: ListOperationsResponse_Item_Purchase.fromPartial(
          object.type.purchase
        ),
      };
    }
    if (
      object.type?.$case === "compensation" &&
      object.type?.compensation !== undefined &&
      object.type?.compensation !== null
    ) {
      message.type = {
        $case: "compensation",
        compensation: ListOperationsResponse_Item_Compensation.fromPartial(
          object.type.compensation
        ),
      };
    }
    return message;
  },
};

function createBaseListOperationsResponse_Item_BalanceChange(): ListOperationsResponse_Item_BalanceChange {
  return {};
}

export const ListOperationsResponse_Item_BalanceChange = {
  encode(
    _: ListOperationsResponse_Item_BalanceChange,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListOperationsResponse_Item_BalanceChange {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOperationsResponse_Item_BalanceChange();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    _: DeepPartial<ListOperationsResponse_Item_BalanceChange>
  ): ListOperationsResponse_Item_BalanceChange {
    const message = createBaseListOperationsResponse_Item_BalanceChange();
    return message;
  },
};

function createBaseListOperationsResponse_Item_Purchase(): ListOperationsResponse_Item_Purchase {
  return {
    couponId: "",
    stateChangedAt: undefined,
    amountCents: Long.ZERO,
    state: undefined,
  };
}

export const ListOperationsResponse_Item_Purchase = {
  encode(
    message: ListOperationsResponse_Item_Purchase,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.couponId !== "") {
      writer.uint32(10).string(message.couponId);
    }
    if (message.stateChangedAt !== undefined) {
      Timestamp.encode(
        message.stateChangedAt,
        writer.uint32(18).fork()
      ).ldelim();
    }
    if (!message.amountCents.isZero()) {
      writer.uint32(24).int64(message.amountCents);
    }
    if (message.state?.$case === "pending") {
      ListOperationsResponse_Item_Purchase_Pending.encode(
        message.state.pending,
        writer.uint32(90).fork()
      ).ldelim();
    }
    if (message.state?.$case === "preGame") {
      ListOperationsResponse_Item_Purchase_PreGame.encode(
        message.state.preGame,
        writer.uint32(98).fork()
      ).ldelim();
    }
    if (message.state?.$case === "inGame") {
      ListOperationsResponse_Item_Purchase_InGame.encode(
        message.state.inGame,
        writer.uint32(106).fork()
      ).ldelim();
    }
    if (message.state?.$case === "gameFinished") {
      ListOperationsResponse_Item_Purchase_GameFinished.encode(
        message.state.gameFinished,
        writer.uint32(114).fork()
      ).ldelim();
    }
    if (message.state?.$case === "cashOutRequested") {
      ListOperationsResponse_Item_Purchase_CashOutRequested.encode(
        message.state.cashOutRequested,
        writer.uint32(130).fork()
      ).ldelim();
    }
    if (message.state?.$case === "cashedOut") {
      ListOperationsResponse_Item_Purchase_CashedOut.encode(
        message.state.cashedOut,
        writer.uint32(122).fork()
      ).ldelim();
    }
    if (message.state?.$case === "convertedToJackpoker") {
      ListOperationsResponse_Item_Purchase_ConvertedToJackPoker.encode(
        message.state.convertedToJackpoker,
        writer.uint32(138).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListOperationsResponse_Item_Purchase {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOperationsResponse_Item_Purchase();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.couponId = reader.string();
          break;
        case 2:
          message.stateChangedAt = Timestamp.decode(reader, reader.uint32());
          break;
        case 3:
          message.amountCents = reader.int64() as Long;
          break;
        case 11:
          message.state = {
            $case: "pending",
            pending: ListOperationsResponse_Item_Purchase_Pending.decode(
              reader,
              reader.uint32()
            ),
          };
          break;
        case 12:
          message.state = {
            $case: "preGame",
            preGame: ListOperationsResponse_Item_Purchase_PreGame.decode(
              reader,
              reader.uint32()
            ),
          };
          break;
        case 13:
          message.state = {
            $case: "inGame",
            inGame: ListOperationsResponse_Item_Purchase_InGame.decode(
              reader,
              reader.uint32()
            ),
          };
          break;
        case 14:
          message.state = {
            $case: "gameFinished",
            gameFinished:
              ListOperationsResponse_Item_Purchase_GameFinished.decode(
                reader,
                reader.uint32()
              ),
          };
          break;
        case 16:
          message.state = {
            $case: "cashOutRequested",
            cashOutRequested:
              ListOperationsResponse_Item_Purchase_CashOutRequested.decode(
                reader,
                reader.uint32()
              ),
          };
          break;
        case 15:
          message.state = {
            $case: "cashedOut",
            cashedOut: ListOperationsResponse_Item_Purchase_CashedOut.decode(
              reader,
              reader.uint32()
            ),
          };
          break;
        case 17:
          message.state = {
            $case: "convertedToJackpoker",
            convertedToJackpoker:
              ListOperationsResponse_Item_Purchase_ConvertedToJackPoker.decode(
                reader,
                reader.uint32()
              ),
          };
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<ListOperationsResponse_Item_Purchase>
  ): ListOperationsResponse_Item_Purchase {
    const message = createBaseListOperationsResponse_Item_Purchase();
    message.couponId = object.couponId ?? "";
    message.stateChangedAt =
      object.stateChangedAt !== undefined && object.stateChangedAt !== null
        ? Timestamp.fromPartial(object.stateChangedAt)
        : undefined;
    message.amountCents =
      object.amountCents !== undefined && object.amountCents !== null
        ? Long.fromValue(object.amountCents)
        : Long.ZERO;
    if (
      object.state?.$case === "pending" &&
      object.state?.pending !== undefined &&
      object.state?.pending !== null
    ) {
      message.state = {
        $case: "pending",
        pending: ListOperationsResponse_Item_Purchase_Pending.fromPartial(
          object.state.pending
        ),
      };
    }
    if (
      object.state?.$case === "preGame" &&
      object.state?.preGame !== undefined &&
      object.state?.preGame !== null
    ) {
      message.state = {
        $case: "preGame",
        preGame: ListOperationsResponse_Item_Purchase_PreGame.fromPartial(
          object.state.preGame
        ),
      };
    }
    if (
      object.state?.$case === "inGame" &&
      object.state?.inGame !== undefined &&
      object.state?.inGame !== null
    ) {
      message.state = {
        $case: "inGame",
        inGame: ListOperationsResponse_Item_Purchase_InGame.fromPartial(
          object.state.inGame
        ),
      };
    }
    if (
      object.state?.$case === "gameFinished" &&
      object.state?.gameFinished !== undefined &&
      object.state?.gameFinished !== null
    ) {
      message.state = {
        $case: "gameFinished",
        gameFinished:
          ListOperationsResponse_Item_Purchase_GameFinished.fromPartial(
            object.state.gameFinished
          ),
      };
    }
    if (
      object.state?.$case === "cashOutRequested" &&
      object.state?.cashOutRequested !== undefined &&
      object.state?.cashOutRequested !== null
    ) {
      message.state = {
        $case: "cashOutRequested",
        cashOutRequested:
          ListOperationsResponse_Item_Purchase_CashOutRequested.fromPartial(
            object.state.cashOutRequested
          ),
      };
    }
    if (
      object.state?.$case === "cashedOut" &&
      object.state?.cashedOut !== undefined &&
      object.state?.cashedOut !== null
    ) {
      message.state = {
        $case: "cashedOut",
        cashedOut: ListOperationsResponse_Item_Purchase_CashedOut.fromPartial(
          object.state.cashedOut
        ),
      };
    }
    if (
      object.state?.$case === "convertedToJackpoker" &&
      object.state?.convertedToJackpoker !== undefined &&
      object.state?.convertedToJackpoker !== null
    ) {
      message.state = {
        $case: "convertedToJackpoker",
        convertedToJackpoker:
          ListOperationsResponse_Item_Purchase_ConvertedToJackPoker.fromPartial(
            object.state.convertedToJackpoker
          ),
      };
    }
    return message;
  },
};

function createBaseListOperationsResponse_Item_Purchase_Pending(): ListOperationsResponse_Item_Purchase_Pending {
  return {};
}

export const ListOperationsResponse_Item_Purchase_Pending = {
  encode(
    _: ListOperationsResponse_Item_Purchase_Pending,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListOperationsResponse_Item_Purchase_Pending {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOperationsResponse_Item_Purchase_Pending();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    _: DeepPartial<ListOperationsResponse_Item_Purchase_Pending>
  ): ListOperationsResponse_Item_Purchase_Pending {
    const message = createBaseListOperationsResponse_Item_Purchase_Pending();
    return message;
  },
};

function createBaseListOperationsResponse_Item_Purchase_PreGame(): ListOperationsResponse_Item_Purchase_PreGame {
  return {};
}

export const ListOperationsResponse_Item_Purchase_PreGame = {
  encode(
    _: ListOperationsResponse_Item_Purchase_PreGame,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListOperationsResponse_Item_Purchase_PreGame {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOperationsResponse_Item_Purchase_PreGame();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    _: DeepPartial<ListOperationsResponse_Item_Purchase_PreGame>
  ): ListOperationsResponse_Item_Purchase_PreGame {
    const message = createBaseListOperationsResponse_Item_Purchase_PreGame();
    return message;
  },
};

function createBaseListOperationsResponse_Item_Purchase_InGame(): ListOperationsResponse_Item_Purchase_InGame {
  return { bigBlindCents: Long.ZERO, nickname: "" };
}

export const ListOperationsResponse_Item_Purchase_InGame = {
  encode(
    message: ListOperationsResponse_Item_Purchase_InGame,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (!message.bigBlindCents.isZero()) {
      writer.uint32(8).int64(message.bigBlindCents);
    }
    if (message.nickname !== "") {
      writer.uint32(18).string(message.nickname);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListOperationsResponse_Item_Purchase_InGame {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOperationsResponse_Item_Purchase_InGame();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.bigBlindCents = reader.int64() as Long;
          break;
        case 2:
          message.nickname = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<ListOperationsResponse_Item_Purchase_InGame>
  ): ListOperationsResponse_Item_Purchase_InGame {
    const message = createBaseListOperationsResponse_Item_Purchase_InGame();
    message.bigBlindCents =
      object.bigBlindCents !== undefined && object.bigBlindCents !== null
        ? Long.fromValue(object.bigBlindCents)
        : Long.ZERO;
    message.nickname = object.nickname ?? "";
    return message;
  },
};

function createBaseListOperationsResponse_Item_Purchase_GameFinished(): ListOperationsResponse_Item_Purchase_GameFinished {
  return { bigBlindCents: Long.ZERO, nickname: "", resultCents: Long.ZERO };
}

export const ListOperationsResponse_Item_Purchase_GameFinished = {
  encode(
    message: ListOperationsResponse_Item_Purchase_GameFinished,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (!message.bigBlindCents.isZero()) {
      writer.uint32(16).int64(message.bigBlindCents);
    }
    if (message.nickname !== "") {
      writer.uint32(26).string(message.nickname);
    }
    if (!message.resultCents.isZero()) {
      writer.uint32(8).int64(message.resultCents);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListOperationsResponse_Item_Purchase_GameFinished {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseListOperationsResponse_Item_Purchase_GameFinished();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          message.bigBlindCents = reader.int64() as Long;
          break;
        case 3:
          message.nickname = reader.string();
          break;
        case 1:
          message.resultCents = reader.int64() as Long;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<ListOperationsResponse_Item_Purchase_GameFinished>
  ): ListOperationsResponse_Item_Purchase_GameFinished {
    const message =
      createBaseListOperationsResponse_Item_Purchase_GameFinished();
    message.bigBlindCents =
      object.bigBlindCents !== undefined && object.bigBlindCents !== null
        ? Long.fromValue(object.bigBlindCents)
        : Long.ZERO;
    message.nickname = object.nickname ?? "";
    message.resultCents =
      object.resultCents !== undefined && object.resultCents !== null
        ? Long.fromValue(object.resultCents)
        : Long.ZERO;
    return message;
  },
};

function createBaseListOperationsResponse_Item_Purchase_CashOutRequested(): ListOperationsResponse_Item_Purchase_CashOutRequested {
  return { bigBlindCents: Long.ZERO, nickname: "", resultCents: Long.ZERO };
}

export const ListOperationsResponse_Item_Purchase_CashOutRequested = {
  encode(
    message: ListOperationsResponse_Item_Purchase_CashOutRequested,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (!message.bigBlindCents.isZero()) {
      writer.uint32(16).int64(message.bigBlindCents);
    }
    if (message.nickname !== "") {
      writer.uint32(26).string(message.nickname);
    }
    if (!message.resultCents.isZero()) {
      writer.uint32(8).int64(message.resultCents);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListOperationsResponse_Item_Purchase_CashOutRequested {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseListOperationsResponse_Item_Purchase_CashOutRequested();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          message.bigBlindCents = reader.int64() as Long;
          break;
        case 3:
          message.nickname = reader.string();
          break;
        case 1:
          message.resultCents = reader.int64() as Long;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<ListOperationsResponse_Item_Purchase_CashOutRequested>
  ): ListOperationsResponse_Item_Purchase_CashOutRequested {
    const message =
      createBaseListOperationsResponse_Item_Purchase_CashOutRequested();
    message.bigBlindCents =
      object.bigBlindCents !== undefined && object.bigBlindCents !== null
        ? Long.fromValue(object.bigBlindCents)
        : Long.ZERO;
    message.nickname = object.nickname ?? "";
    message.resultCents =
      object.resultCents !== undefined && object.resultCents !== null
        ? Long.fromValue(object.resultCents)
        : Long.ZERO;
    return message;
  },
};

function createBaseListOperationsResponse_Item_Purchase_CashedOut(): ListOperationsResponse_Item_Purchase_CashedOut {
  return { bigBlindCents: Long.ZERO, nickname: "", resultCents: Long.ZERO };
}

export const ListOperationsResponse_Item_Purchase_CashedOut = {
  encode(
    message: ListOperationsResponse_Item_Purchase_CashedOut,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (!message.bigBlindCents.isZero()) {
      writer.uint32(16).int64(message.bigBlindCents);
    }
    if (message.nickname !== "") {
      writer.uint32(26).string(message.nickname);
    }
    if (!message.resultCents.isZero()) {
      writer.uint32(8).int64(message.resultCents);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListOperationsResponse_Item_Purchase_CashedOut {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOperationsResponse_Item_Purchase_CashedOut();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          message.bigBlindCents = reader.int64() as Long;
          break;
        case 3:
          message.nickname = reader.string();
          break;
        case 1:
          message.resultCents = reader.int64() as Long;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<ListOperationsResponse_Item_Purchase_CashedOut>
  ): ListOperationsResponse_Item_Purchase_CashedOut {
    const message = createBaseListOperationsResponse_Item_Purchase_CashedOut();
    message.bigBlindCents =
      object.bigBlindCents !== undefined && object.bigBlindCents !== null
        ? Long.fromValue(object.bigBlindCents)
        : Long.ZERO;
    message.nickname = object.nickname ?? "";
    message.resultCents =
      object.resultCents !== undefined && object.resultCents !== null
        ? Long.fromValue(object.resultCents)
        : Long.ZERO;
    return message;
  },
};

function createBaseListOperationsResponse_Item_Purchase_ConvertedToJackPoker(): ListOperationsResponse_Item_Purchase_ConvertedToJackPoker {
  return {};
}

export const ListOperationsResponse_Item_Purchase_ConvertedToJackPoker = {
  encode(
    _: ListOperationsResponse_Item_Purchase_ConvertedToJackPoker,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListOperationsResponse_Item_Purchase_ConvertedToJackPoker {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseListOperationsResponse_Item_Purchase_ConvertedToJackPoker();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    _: DeepPartial<ListOperationsResponse_Item_Purchase_ConvertedToJackPoker>
  ): ListOperationsResponse_Item_Purchase_ConvertedToJackPoker {
    const message =
      createBaseListOperationsResponse_Item_Purchase_ConvertedToJackPoker();
    return message;
  },
};

function createBaseListOperationsResponse_Item_Compensation(): ListOperationsResponse_Item_Compensation {
  return { couponId: "" };
}

export const ListOperationsResponse_Item_Compensation = {
  encode(
    message: ListOperationsResponse_Item_Compensation,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.couponId !== "") {
      writer.uint32(10).string(message.couponId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListOperationsResponse_Item_Compensation {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOperationsResponse_Item_Compensation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.couponId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<ListOperationsResponse_Item_Compensation>
  ): ListOperationsResponse_Item_Compensation {
    const message = createBaseListOperationsResponse_Item_Compensation();
    message.couponId = object.couponId ?? "";
    return message;
  },
};

function createBaseBuyCouponRequest(): BuyCouponRequest {
  return { transactionId: "" };
}

export const BuyCouponRequest = {
  encode(
    message: BuyCouponRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.transactionId !== "") {
      writer.uint32(10).string(message.transactionId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BuyCouponRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBuyCouponRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.transactionId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<BuyCouponRequest>): BuyCouponRequest {
    const message = createBaseBuyCouponRequest();
    message.transactionId = object.transactionId ?? "";
    return message;
  },
};

function createBaseBuyCouponResponse(): BuyCouponResponse {
  return { couponId: "" };
}

export const BuyCouponResponse = {
  encode(
    message: BuyCouponResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.couponId !== "") {
      writer.uint32(10).string(message.couponId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BuyCouponResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBuyCouponResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.couponId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<BuyCouponResponse>): BuyCouponResponse {
    const message = createBaseBuyCouponResponse();
    message.couponId = object.couponId ?? "";
    return message;
  },
};

function createBaseCashOutCouponRequest(): CashOutCouponRequest {
  return { couponId: "" };
}

export const CashOutCouponRequest = {
  encode(
    message: CashOutCouponRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.couponId !== "") {
      writer.uint32(10).string(message.couponId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): CashOutCouponRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCashOutCouponRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.couponId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<CashOutCouponRequest>): CashOutCouponRequest {
    const message = createBaseCashOutCouponRequest();
    message.couponId = object.couponId ?? "";
    return message;
  },
};

function createBaseCashOutCouponResponse(): CashOutCouponResponse {
  return {};
}

export const CashOutCouponResponse = {
  encode(
    _: CashOutCouponResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): CashOutCouponResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCashOutCouponResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<CashOutCouponResponse>): CashOutCouponResponse {
    const message = createBaseCashOutCouponResponse();
    return message;
  },
};

/**
 * Все запросы должны иметь хедер с кредлами агента:
 *
 *   Authorization: Basic base64(email:password)
 *
 * При неверных данных в хедере вернётся ошибка с кодом UNAUTHENTICATED.
 */
export const AgentDefinition = {
  name: "Agent",
  fullName: "deeplay.jackpoker_fast_funnel.agent.v1.Agent",
  methods: {
    /** Проверяет валидность кредлов. */
    checkCredentials: {
      name: "CheckCredentials",
      requestType: CheckCredentialsRequest,
      requestStream: false,
      responseType: CheckCredentialsResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "NO_SIDE_EFFECTS",
      },
    },
    /** Возвращает текущий баланс агента. */
    getBalance: {
      name: "GetBalance",
      requestType: GetBalanceRequest,
      requestStream: false,
      responseType: GetBalanceResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "NO_SIDE_EFFECTS",
      },
    },
    /** Возвращает историю операций — пополнений баланса и покупок. */
    listOperations: {
      name: "ListOperations",
      requestType: ListOperationsRequest,
      requestStream: false,
      responseType: ListOperationsResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "NO_SIDE_EFFECTS",
      },
    },
    /**
     * Выполняет покупку купона.
     *
     * При недостаточном балансе возвращает ошибку с кодом FAILED_PRECONDITION.
     */
    buyCoupon: {
      name: "BuyCoupon",
      requestType: BuyCouponRequest,
      requestStream: false,
      responseType: BuyCouponResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "IDEMPOTENT",
      },
    },
    /**
     * Помечает купон как обналиченный, т.е. агент выдал кастомеру его выигрыш.
     *
     * Если игра по купону ещё не завершена, возвращает ошибку с кодом
     * FAILED_PRECONDITION.
     */
    cashOutCoupon: {
      name: "CashOutCoupon",
      requestType: CashOutCouponRequest,
      requestStream: false,
      responseType: CashOutCouponResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "IDEMPOTENT",
      },
    },
  },
} as const;

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Long
  ? string | number | Long
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string }
  ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & {
      $case: T["$case"];
    }
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

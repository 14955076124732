import React, {useState} from 'react';
import {stylesheet, classes as cx} from 'typestyle';

type Props = {
  onOpen: () => void;
  onClose: () => void;
  onSearchChange: (searchText: string) => void;
  isOpen: boolean;
};

const classes = stylesheet({
  root: {
    position: 'relative',
    width: 36,
    height: 36,
    overflow: 'hidden',
    borderRadius: 4,
    border: '1px solid #3E1478',

    $nest: {
      '&::before': {
        content: '""',
        display: 'block',
        width: 24,
        height: 24,
        background: 'url(/agent/search.svg)',
        position: 'absolute',
        top: 6,
        left: 8,
      },

      input: {
        padding: 0,
      },
    },
  },
  opened: {
    width: '100vw',
    position: 'relative',

    $nest: {
      input: {
        padding: '6px 22px 6px 40px',
      },
    },
  },
  close: {
    display: 'block',
    position: 'absolute',
    background: 'url(/agent/close.svg)',
    width: 24,
    height: 24,
    top: 6,
    right: 6,
  },
  input: {
    padding: '6px 22px 6px 40px',
  },
});

export const Search: React.FC<
  Props & React.HTMLProps<HTMLInputElement>
> = props => {
  const [searchText, setSearchText] = useState<string>('');

  const onClose = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setSearchText('');
    props.onSearchChange('');
    props.onClose();
  };

  const onBlur = () => {
    setSearchText('');
    props.onSearchChange('');
    props.onClose();
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    if (props.onSearchChange) {
      props.onSearchChange(event.target.value);
    }
  };

  return (
    <div
      className={cx(classes.root, props.isOpen ? classes.opened : '')}
      onClick={props.onOpen}
    >
      {props.isOpen ? (
        <input
          autoFocus
          value={searchText}
          onChange={onChange}
          onBlur={onBlur}
          className={cx(props.className, classes.input)}
        />
      ) : (
        ''
      )}
      <div
        style={props.isOpen ? {} : {display: 'none'}}
        className={classes.close}
        onClick={onClose}
      />
    </div>
  );
};

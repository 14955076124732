import {stylesheet, classes as cx, media, style, cssRaw} from 'typestyle';
import {Alert, AlertProps, Classes} from '@blueprintjs/core';

cssRaw(`
@keyframes slidein {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

.${Classes.OVERLAY} {
  overflow: hidden !important;
}
`);

const classes = stylesheet({
  root: {
    animation: '0.2s slidein both',
    animationDirection: 'alternate',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: 560,
    margin: 0,
    padding: '24px 36px',
    position: 'absolute',
    bottom: 0,
    borderRadius: '12px 12px 0px 0px',
    background: '#2A0A57',
    color: '#fff',
    fontFamily: 'Poppins',
    textAlign: 'center',

    $nest: {
      [`.${Classes.ALERT_FOOTER}`]: {
        flexDirection: 'column',
      },
      [`.${Classes.BUTTON}`]: {
        width: 288,
        height: 56,
        borderRadius: 4,
        background: 'rgba(140, 60, 250, 0.2)',
        fontSize: 14,
        fontWeight: 300,
        color: '#fff',

        $nest: {
          '&:hover': {
            background: 'rgba(140, 60, 250, 0.2)',
          },
          '&:last-child': {
            marginTop: 16,
          },
          [`&.${Classes.INTENT_DANGER}`]: {
            background: '#8C3CFA',
            boxShadow: '0px 0px 10px rgba(140, 60, 250, 0.35)',
            fontWeight: 600,
          },
        },
      },
    },
  },
});

export const AgentAlert: React.FC<AlertProps> = props => {
  const className = style(
    media({minWidth: 600}, {position: 'static', borderRadius: 12}),
  );
  return (
    <Alert
      transitionDuration={0}
      {...props}
      className={cx(props.className, classes.root, className)}
    >
      {props.children}
    </Alert>
  );
};

import {MutationFunction, QueryClient} from 'react-query';
import {AdminClient} from '../../../client';
import {
  DeepPartial,
  SaveAgentRequest,
  SaveAgentResponse,
} from '../../../proto/deeplay/jackpoker_fast_funnel/admin/v1/admin';

export type SaveAgentMutationVariables = DeepPartial<SaveAgentRequest>;

export type SaveAgentMutationData = SaveAgentResponse;

export type SaveAgentMutationDeps = {
  adminClient: AdminClient;
};

export type SaveAgentMutationFn = MutationFunction<
  SaveAgentMutationData,
  SaveAgentMutationVariables
>;

export function SaveAgentMutationFn({
  adminClient,
}: SaveAgentMutationDeps): SaveAgentMutationFn {
  return async request => {
    return await adminClient.saveAgent(request);
  };
}

export function postSaveAgentMutation(
  queryClient: QueryClient,
  data: SaveAgentMutationData,
  request: SaveAgentMutationVariables,
) {
  queryClient.invalidateQueries(['list-agents']);
}

import {MutationFunction, QueryClient} from 'react-query';
import {AgentClient} from '../../../client';
import {
  DeepPartial,
  BuyCouponRequest,
  BuyCouponResponse,
} from '../../../proto/deeplay/jackpoker_fast_funnel/agent/v1/agent';

export type BuyCouponMutationVariables = DeepPartial<BuyCouponRequest>;

export type BuyCouponMutationData = BuyCouponResponse;

export type BuyCouponMutationDeps = {
  agentClient: AgentClient;
};

export type BuyCouponMutationFn = MutationFunction<
  BuyCouponMutationData,
  BuyCouponMutationVariables
>;

export function BuyCouponMutationFn({
  agentClient,
}: BuyCouponMutationDeps): BuyCouponMutationFn {
  return async request => {
    return await agentClient.buyCoupon(request);
  };
}

export function postBuyCouponMutation(
  queryClient: QueryClient,
  data: BuyCouponMutationData,
  request: BuyCouponMutationVariables,
) {
  queryClient.invalidateQueries(['list-operations']);
  queryClient.invalidateQueries(['get-balance']);
}

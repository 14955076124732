/* eslint-disable jsx-a11y/anchor-is-valid */
import {Spinner, SpinnerSize} from '@blueprintjs/core';
import assert from 'assert';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation, useQueryClient} from 'react-query';
import {useParams} from 'react-router-dom';
import {stylesheet} from 'typestyle';
import {CustomerClient} from '../../../client';
import {
  getCodeSentTimestamp,
  removeCodeSentTimestamp,
  setCodeSentTimestamp,
} from '../../../utils/smsCode';
import {Button} from '../../common/Button';
import {ContactUs} from '../../common/ContactUs';
import {Input} from '../../common/Input';
import {PokerBackground} from '../../common/PokerBackground';
import {
  postResendVerificationCodeMutation,
  ResendVerificationCodeMutationFn,
} from '../queries/resendVerificationCode';
import {
  postVerifyPhoneNumberMutation,
  VerifyPhoneNumberMutationFn,
} from '../queries/verifyPhoneNumber';

const customerClient = CustomerClient();

const classes = stylesheet({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  header: {
    display: 'flex',
  },
  logo: {
    margin: '85px auto 60px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: '40px auto 0',
  },
  select: {
    marginBottom: 8,
  },
  text: {
    fontSize: 12,
    textAlign: 'center',
    marginTop: 12,
  },
  resend: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    fontSize: 12,
    textAlign: 'center',
    marginTop: 32,
    marginBottom: 32,
    color: '#F2AC5A',
    textDecorationLine: 'underline',
    $nest: {
      '&:visited, &:hover, &:active': {
        color: '#F2AC5A',
      },
    },
  },
  resendDisabled: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    fontSize: 12,
    textAlign: 'center',
    marginTop: 32,
    marginBottom: 32,
    color: '#6919D7',
    textDecorationLine: 'none',
  },
  footer: {
    marginTop: 16,
    fontSize: 12,
    fontWeight: 300,
  },
  link: {
    cursor: 'pointer',
    fontWeight: 500,
    textDecoration: 'underline',
  },
});

type Props = {
  phoneNumber: string;
  countryId: string;
};

export const SMSCodeVerification: React.FC<Props> = ({
  phoneNumber,
  countryId,
}) => {
  const {t} = useTranslation();
  const {couponId} = useParams();

  assert(couponId != null);

  const codeSentTimestamp = getCodeSentTimestamp();

  const [code, setCode] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [secondsLeft, setSecondsLeft] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [isHelpOpened, setIsHelpOpened] = useState<boolean>(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (codeSentTimestamp && codeSentTimestamp + 60 * 1000 > +Date.now()) {
        const date = new Date();
        const seconds = Math.floor(
          (codeSentTimestamp + 60 * 1000 - +Date.now()) / 1000,
        );
        date.setSeconds(seconds);
        setSecondsLeft(date.toISOString().substring(17, 19));
      } else {
        removeCodeSentTimestamp();
        setSecondsLeft(undefined);
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const queryClient = useQueryClient();
  const verifyPhoneNumberMutation = useMutation({
    mutationFn: VerifyPhoneNumberMutationFn({
      customerClient,
    }),
    onSuccess(data, variables) {
      setLoading(false);
      if (data.validationErrors.length > 0) {
        for (const error of data.validationErrors) {
          setError(error.field?.verificationCode ?? '');
        }
      } else {
        postVerifyPhoneNumberMutation(queryClient, data, variables);
      }
    },
    onError(err: any) {
      setLoading(false);
      throw err;
    },
  });
  const resendVerificationCode = useMutation({
    mutationFn: ResendVerificationCodeMutationFn({
      customerClient,
    }),
    onSuccess(data, variables) {
      setCodeSentTimestamp();
      postResendVerificationCodeMutation(queryClient, data, variables);
    },
    onError(err: any) {
      removeCodeSentTimestamp();
      throw err;
    },
  });

  const onClose = () => setCode('');
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setCode(event.target.value);
  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    verifyPhoneNumberMutation.mutate({
      couponId,
      verificationCode: code,
    });
  };

  const onResendClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    resendVerificationCode.mutate({
      couponId,
    });
  };

  const onHelpClick = () => {
    setIsHelpOpened(true);
  };

  const onHelpClose = () => {
    setIsHelpOpened(false);
  };

  return (
    <div className={classes.root}>
      <PokerBackground height={230} />
      <div className={classes.header}>
        <img width={180} className={classes.logo} alt="logo" src="/logo.svg" />
      </div>
      <form onSubmit={onFormSubmit} className={classes.form}>
        <Input
          disabled
          value={phoneNumber}
          placeholder={t('Phone')}
          type="text"
        />
        <p className={classes.text}>{t('Enter the code from SMS')}</p>
        <Input
          onClose={onClose}
          onChange={onChange}
          value={code}
          placeholder={t('Code')}
          type="text"
          error={error}
        />

        {codeSentTimestamp ? (
          <span className={classes.resendDisabled}>
            {secondsLeft ? (
              `${t('Resend code')}: 00:${secondsLeft}`
            ) : (
              <Spinner size={SpinnerSize.SMALL} />
            )}
          </span>
        ) : (
          <a onClick={onResendClick} className={classes.resend}>
            Resend code
          </a>
        )}

        <Button
          loading={loading}
          primary
          disabled={code.length < 3 || loading}
          text={t('Submit')}
        />
        <div className={classes.footer}>
          {t(`Didn't get a confirmation code?`)}{' '}
          <span onClick={onHelpClick} className={classes.link}>
            {t('Contact us')}
          </span>
        </div>
        <ContactUs isOpen={isHelpOpened} onClose={onHelpClose} />
      </form>
    </div>
  );
};

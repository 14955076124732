/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import {stylesheet} from 'typestyle';
import {Button} from '../../common/Button';
import {Input} from '../../common/Input';
import {PokerBackground} from '../../common/PokerBackground';
import {CountrySelect} from '../../common/Select';
import {Toc} from '../../common/Toc';
import {ListCountriesResponse_Item as Country} from '../../../proto/deeplay/jackpoker_fast_funnel/customer/v1/customer';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {CustomerClient} from '../../../client';
import {ListCountriesQueryFn} from '../queries/listCountries';
import {
  postStartRegistrationMutation,
  StartRegistrationMutationFn,
} from '../queries/startRegistration';
import {useParams} from 'react-router-dom';
import assert from 'assert';
import {setCodeSentTimestamp} from '../../../utils/smsCode';
import {useTranslation} from 'react-i18next';

const customerClient = CustomerClient();

const classes = stylesheet({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  header: {
    display: 'flex',
  },
  logo: {
    margin: '85px auto 60px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: '40px auto 0',
  },
  select: {
    marginBottom: 24,
  },
  input: {
    marginBottom: 24,
  },
  checkbox: {
    width: 250,

    $nest: {
      '& input': {
        opacity: 0,
        position: 'absolute',
      },
      '& span': {
        fontWeight: 300,
        fontSize: 10,
        display: 'block',
        paddingLeft: 28,
        position: 'relative',

        $nest: {
          '&::before': {
            content: '""',
            display: 'block',
            width: 18,
            height: 18,
            border: '2px solid white',
            borderRadius: 4,
            position: 'absolute',
            left: 0,
          },
        },
      },
      '& input:checked ~ span::before': {
        background: '#8C3CFA',
        backgroundImage: 'url(/check.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        backgroundPositionY: 4,
        border: 'none',
      },
    },
  },
  link: {
    color: '#fff !important',
    borderBottom: '1px solid #fff !important',
    textDecoration: 'none !important',
  },
});

export const CountryAndPhone = () => {
  const {t} = useTranslation();
  const {couponId} = useParams();

  assert(couponId != null);

  const [isTocOpen, setIsTocOpen] = useState<boolean>(false);
  const [isTocAgreed, setIsTocAgreed] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  // const [countryError, setCountryError] = useState<string>('');
  const [phoneNumberError, setPhoneNumberError] = useState<string>('');
  const [mask, setMask] = useState<string | string[] | undefined>(undefined);
  const [country, setCountry] = useState<Country | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const {data: countriesResponse} = useQuery({
    queryKey: ['list-countries'],
    queryFn: ListCountriesQueryFn({customerClient}),
    suspense: true,
    refetchOnWindowFocus: false,
  });

  const queryClient = useQueryClient();
  const startRegistrationMutation = useMutation({
    mutationFn: StartRegistrationMutationFn({
      customerClient,
    }),
    onSuccess(data, variables) {
      setLoading(false);
      if (data.validationErrors.length > 0) {
        for (const error of data.validationErrors) {
          if (error.field?.$case === 'countryId') {
            // setCountryError(error.field.countryId);
          }
          if (error.field?.$case === 'phoneNumber') {
            setPhoneNumberError(error.field.phoneNumber);
          }
        }
      } else {
        setCodeSentTimestamp();
        postStartRegistrationMutation(queryClient, data, variables);
      }
    },
    onError(err: any) {
      setLoading(false);
      throw err;
    },
  });

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  };

  const onClose = () => setPhoneNumber('');

  const onSelect = (selectedCountry: Country) => {
    if (country?.name !== selectedCountry.name) {
      setPhoneNumber('');
    }
    setCountry(selectedCountry);
    setMask(selectedCountry.phoneFormat.replace(/\./g, '9'));
  };

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);
    // setCountryError('');
    setPhoneNumberError('');

    startRegistrationMutation.mutate({
      couponId,
      phoneNumber: phoneNumber.replace(/\(|\)| |-/g, ''),
      countryId: country?.id,
    });
  };

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setIsTocOpen(true);
  };

  const onDialogClose = () => {
    setIsTocOpen(false);
  };

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsTocAgreed(event.target.checked);
  };

  const submitDisabled =
    !isTocAgreed ||
    phoneNumber.includes('_') ||
    (country?.name === 'Other' && phoneNumber.length < 10) ||
    phoneNumber.length === 0 ||
    country === undefined;

  return (
    <div className={classes.root}>
      <PokerBackground height={230} />
      <div className={classes.header}>
        <img width={180} className={classes.logo} alt="logo" src="/logo.svg" />
      </div>
      <form onSubmit={onFormSubmit} className={classes.form}>
        <CountrySelect
          className={classes.select}
          countries={countriesResponse?.items ?? []}
          onSelect={onSelect}
        />

        <Input
          onChange={onChange}
          onClose={onClose}
          value={phoneNumber}
          placeholder={t('Phone')}
          type="tel"
          mask={mask}
          disabled={country === undefined}
          className={classes.input}
          error={phoneNumberError}
        />

        <div className={classes.checkbox}>
          <label>
            <input onChange={onCheckboxChange} type="checkbox" />
            <span>
              {t(
                `I certify that I am the minimum legal age for gambling in my jurisdiction, and that I have read and agreed to the`,
              )}{' '}
              <a className={classes.link} onClick={onClick}>
                {t('Terms & Conditions')}
              </a>
              .
            </span>
          </label>
        </div>

        <br />
        <Button
          loading={loading}
          primary
          disabled={submitDisabled || loading}
          text={t('Get Verification Code')}
        />
      </form>

      <Toc isOpen={isTocOpen} onClose={onDialogClose} />
    </div>
  );
};

import {useParams} from 'react-router-dom';
import assert from 'assert';
import {GetCouponInfoQueryFn} from './queries/getCouponIInfo';
import {CustomerClient} from '../../client';
import {useQuery} from 'react-query';
import {stylesheet} from 'typestyle';
import {PreGame} from './states/PreGame';
import {GameFinished} from './states/GameFinished';
import {CashOutRequested} from './states/CashOutRequested';
import {CashedOut} from './states/CashedOut';
import {InGame} from './states/InGame';

const customerClient = CustomerClient();

const classes = stylesheet({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
});

export const Customer = () => {
  const {couponId} = useParams();

  assert(couponId != null);

  const {data: coupon} = useQuery({
    queryKey: ['get-coupon-info'],
    queryFn: GetCouponInfoQueryFn({customerClient, couponId}),
    suspense: true,
    refetchOnWindowFocus: false,
  });

  assert(coupon != null && coupon.state != null);

  let Content: React.FC<{couponId: string}> = () => <></>;
  const {state} = coupon;

  switch (state.$case) {
    case 'preGame':
      Content = PreGame;
      break;
    case 'inGame':
      Content = InGame;
      break;
    case 'cashOutRequested':
      Content = () => (
        <CashOutRequested
          couponId={couponId}
          resultCents={state.cashOutRequested.resultCents.toNumber()}
        />
      );
      break;
    case 'cashedOut':
      Content = CashedOut;
      break;
    case 'gameFinished':
      Content = () => (
        <GameFinished
          couponId={couponId}
          resultCents={state.gameFinished.resultCents.toNumber()}
          bbCents={state.gameFinished.bigBlindCents.toNumber()}
        />
      );
      break;
    default:
      break;
  }

  return (
    <div className={classes.root}>
      <Content couponId={couponId} />
    </div>
  );
};

import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {stylesheet, classes as cx} from 'typestyle';

type Props = {
  onShow: () => void;
  onHide: () => void;
};

const classes = stylesheet({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '10px 12px 10px 16px',
    width: 140,
    height: 36,
    background: '#290D50',
    borderRadius: 4,
    fontSize: 12,
    color: 'rgba(255, 255, 255, 0.6)',
    transition: 'color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  },
  checked: {
    color: '#fff',
  },
  toggle: {
    position: 'relative',
    borderRadius: 7,
    width: 34,
    height: 14,
    background: '#5013A3',

    $nest: {
      '&::after': {
        position: 'absolute',
        top: -3,
        right: 0,
        content: '""',
        display: 'block',
        width: 20,
        height: 20,
        background: '#8C3CFA',
        borderRadius: '50%',
        transition: 'right 200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
      },
    },
  },
  toggleOff: {
    $nest: {
      '&::after': {
        right: 14,
        background: '#5C16BD',
      },
    },
  },
});

export const ShowAll: React.FC<Props & React.HTMLProps<HTMLDivElement>> = ({
  onHide,
  onShow,
  ...rest
}) => {
  const {t} = useTranslation();
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const onClick = () => {
    if (isChecked) {
      onHide();
    } else {
      onShow();
    }
    setIsChecked(!isChecked);
  };

  return (
    <div
      {...rest}
      onClick={onClick}
      className={cx(classes.root, isChecked ? classes.checked : '')}
    >
      {t('Show all')}
      <div
        className={cx(classes.toggle, !isChecked ? classes.toggleOff : '')}
      />
    </div>
  );
};

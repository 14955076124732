import {stylesheet} from 'typestyle';
import {Alert, Button, Card, Colors, Divider} from '@blueprintjs/core';
import {useState} from 'react';
import {EditDialog} from './EditDialog';
import {ListAgentsResponse_Item as Agent} from '../../proto/deeplay/jackpoker_fast_funnel/admin/v1/admin';
import {useQuery} from 'react-query';
import {ListAgentsQueryFn} from './queries/listAgents';
import {AdminClient} from '../../client';
import {useAuth} from '../../Auth';
import {formatCents} from '../../utils/formatCents';

const classes = stylesheet({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '0 auto',
    borderLeft: '1px solid rgba(0,0,0,0.1)',
    borderRight: '1px solid rgba(0,0,0,0.1)',
    backgroundColor: '#f5f8fa',
    padding: 20,
    maxWidth: 1200,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerButton: {
    alignSelf: 'center',
  },
  agentList: {
    height: '100%',
    overflowY: 'scroll',
    padding: 1,
    margin: -1,
    marginTop: 20,
  },
  agentListItem: {
    marginBottom: 10,
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
  },
  label: {
    color: Colors.GRAY4,
    fontSize: '0.8em',
  },
  divider: {
    margin: '0 20px',
  },
  button: {
    backgroundColor: Colors.BLUE1,
  },
});

export const Admin = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [agent, setAgent] = useState<Agent | undefined>(undefined);
  const {user, logOut} = useAuth();

  const onClick = (a: Agent) => {
    setAgent(a);
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
    setAgent(undefined);
  };

  const {data: agentsResponse} = useQuery({
    queryKey: ['list-agents'],
    queryFn: ListAgentsQueryFn({adminClient: AdminClient(user!.credentials)}),
    suspense: true,
  });

  const onLogoutClick = () => {
    setIsAlertOpen(true);
  };

  const onConfirm = () => {
    logOut();
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h1>Agents</h1>
        <Button
          className={classes.headerButton}
          onClick={onLogoutClick}
          rightIcon="log-out"
        />
      </div>
      <Button
        onClick={() => setIsOpen(true)}
        text="Add agent"
        intent="primary"
        icon="plus"
      />
      <EditDialog agent={agent} isOpen={isOpen} onClose={onClose} />
      <div className={classes.agentList}>
        {agentsResponse?.items.map(agent => (
          <div key={agent.email}>
            <Card
              onClick={() => onClick(agent)}
              className={classes.agentListItem}
              elevation={0}
              interactive
            >
              <span>
                <div className={classes.label}>Email</div>
                <div>{agent.email}</div>
              </span>
              <Divider className={classes.divider} />
              <span>
                <div className={classes.label}>Balance</div>
                <div>{formatCents(agent.balanceCents.toNumber())}</div>
              </span>
            </Card>
          </div>
        ))}
      </div>
      <Alert
        intent="danger"
        cancelButtonText="Cancel"
        confirmButtonText="Log out"
        canEscapeKeyCancel
        onClose={() => setIsAlertOpen(false)}
        onConfirm={onConfirm}
        isOpen={isAlertOpen}
      >
        <p>Are you sure you want to log out?</p>
      </Alert>
    </div>
  );
};

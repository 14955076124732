import {Dialog, DialogProps} from '@blueprintjs/core';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {stylesheet, cssRaw, classes as cx} from 'typestyle';
import {PokerLayout} from '../../PokerLayout';

cssRaw(`
@keyframes slidein {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0%);
  }
}
`);

const classes = stylesheet({
  root: {
    display: 'flex',
    flexDirection: 'column',
    animation: '0.2s slidein both',
    position: 'relative',
    width: '100%',
    height: '100vh',
    padding: 0,
    margin: 0,
    borderRadius: 0,
  },
  content: {
    padding: 24,
    height: '100%',
    zIndex: 100,
  },
  close: {
    cursor: 'pointer',
    alignSelf: 'flex-end',
    width: 25,
    height: 25,
    marginTop: -2,
    border: `1px solid #fff`,
    borderRadius: 12,
    position: 'absolute',
    top: 24,
    right: 24,
    $nest: {
      '&::after': {
        content: "''",
        display: 'block',
        position: 'absolute',
        width: 11,
        height: 1,
        top: 11,
        left: 6,
        transform: 'rotate(45deg)',
        backgroundColor: '#fff',
      },
      '&::before': {
        content: "''",
        display: 'block',
        position: 'absolute',
        width: 11,
        height: 1,
        top: 11,
        left: 6,
        transform: 'rotate(-45deg)',
        backgroundColor: '#fff',
      },
    },
  },
  heading: {
    margin: 0,
    fontSize: 20,
    fontWeight: 500,
  },
  subHeading: {
    marginTop: 32,
    fontSize: 14,
  },
  pageControl: {
    zIndex: 100,
    position: 'fixed',
    height: 80,
    bottom: 0,
    width: '100%',
    maxWidth: 360,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  pages: {
    display: 'flex',
    flexDirection: 'row',
  },
  page: {
    fontSize: 12,
    width: 24,
    height: 24,
    paddingTop: 3,
    textAlign: 'center',
    border: '1px solid rgba(0,0,0,0)',
  },
  pageActive: {
    border: '1px solid #fff',
    borderRadius: '50%',
  },
  back: {
    marginLeft: 24,
    width: 21,
    height: 14,
    background: 'url(agent/back-icon.svg)',
  },
  forward: {
    marginRight: 24,
    width: 21,
    height: 14,
    background: 'url(agent/back-icon.svg)',
    transform: 'rotate(180deg)',
  },
  transparent: {
    opacity: 0,
  },
  status: {
    display: 'inline-block',
    boxSizing: 'border-box',
    marginTop: 16,
    marginBottom: 8,
    marginRight: 8,
    padding: '2px 8px',
    borderRadius: 4,
    fontSize: 10,
    width: 'fit-content',
  },
  statusDescription: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 300,
  },
  created: {
    background: 'rgba(128,128,128,0.3)',
    border: '1px solid #808080',
  },
  preGame: {
    background: 'rgba(204, 124, 31, 0.3)',
    border: '1px solid #CC7C1F',
  },
  inGame: {
    background: 'rgba(30, 104, 192, 0.3)',
    border: '1px solid #1E68C0',
  },
  gameFinished: {
    background: 'rgba(61, 153, 65, 0.3)',
    border: '1px solid #3D9941',
  },
  cashOutRequested: {
    border: '1px solid #B21B1B',
    background: 'rgba(179, 27, 27, 0.3)',
  },
  cashedOut: {
    background: 'rgba(55, 55, 55, 0.3)',
    border: '1px solid #373737',
  },
  paidByJP: {
    border: '1px solid #373737',
    background: 'rgba(55, 55, 55, 0.3)',
  },
});

type Page = 1 | 2;

export const About: React.FC<DialogProps> = props => {
  const {t} = useTranslation();
  const [page, setPage] = useState<Page>(1);

  const onFirstPageClick = () => setPage(1);
  const onSecondPageClick = () => setPage(2);

  const onForwardClick = () => setPage(2);
  const onBackClick = () => setPage(1);

  return (
    <Dialog canEscapeKeyClose {...props} className={classes.root}>
      <PokerLayout noRouter>
        <div className={classes.content}>
          <div onClick={props.onClose} className={classes.close} />
          <h1 className={classes.heading}>{t('Coupon status information')}</h1>

          <div className={classes.subHeading}>
            {t('Learn more about the meaning of each coupon status.')}
          </div>
          {page === 1 ? (
            <div>
              <div>
                <div className={cx(classes.status, classes.created)}>
                  {t('pending')}
                </div>
                <div className={classes.statusDescription}>
                  {t('createdDescription')}
                </div>
              </div>
              <div>
                <div className={cx(classes.status, classes.preGame)}>
                  {t('pre-game')}
                </div>
                <div className={classes.statusDescription}>
                  {t('preGameDescription')}
                </div>
              </div>
              <div>
                <div className={cx(classes.status, classes.inGame)}>
                  {t('in game')}
                </div>
                <div className={classes.statusDescription}>
                  {t('inGameDescription')}
                </div>
              </div>
              <div>
                <div className={cx(classes.status, classes.gameFinished)}>
                  {t('game finished')}
                </div>
                <div className={classes.statusDescription}>
                  {t('gameFinishedDescription')}
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div>
                <div className={cx(classes.status, classes.cashOutRequested)}>
                  {t('cashout requested')}
                </div>
                <div className={classes.statusDescription}>
                  {t('cashoutRequestedDescription')}
                </div>
              </div>
              <div>
                <div className={cx(classes.status, classes.cashedOut)}>
                  {t('cashed out')}
                </div>
                <div className={classes.statusDescription}>
                  {t('cashedOutDescription')}
                </div>
              </div>
              <div>
                <div className={cx(classes.status, classes.paidByJP)}>
                  {t('paid by JP')}
                </div>
                <div className={classes.statusDescription}>
                  {t('paidByJPDescription')}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={classes.pageControl}>
          <div
            onClick={onBackClick}
            className={cx(classes.back, page === 1 ? classes.transparent : '')}
          />
          <div className={classes.pages}>
            <div
              className={cx(classes.page, page === 1 ? classes.pageActive : '')}
              onClick={onFirstPageClick}
            >
              1
            </div>
            <div
              className={cx(classes.page, page === 2 ? classes.pageActive : '')}
              onClick={onSecondPageClick}
            >
              2
            </div>
          </div>
          <div
            onClick={onForwardClick}
            className={cx(
              classes.forward,
              page === 2 ? classes.transparent : '',
            )}
          />
        </div>
      </PokerLayout>
    </Dialog>
  );
};

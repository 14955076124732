import {ClientError} from 'nice-grpc-web';
import React, {FormEvent, useState} from 'react';
import {useMutation} from 'react-query';
import {stylesheet} from 'typestyle';
import {CustomerClient} from '../../../client';
import {StartGameMutationFn} from '../queries/startGame';
import {Button} from '../../common/Button';
import {Input} from '../../common/Input';
import {PokerBackground} from '../../common/PokerBackground';
import {Toc} from '../../common/Toc';
import {useTranslation} from 'react-i18next';

const classes = stylesheet({
  root: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  header: {
    display: 'flex',
    height: 286,
  },
  footer: {
    marginBottom: 16,
  },
  content: {},
  logo: {
    margin: '0 auto',
    filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.6));',
  },
  form: {
    margin: '8px auto 0',
    position: 'relative',
    width: 250,
  },
  text: {
    color: '#fff',
    fontSize: 14,
    lineHeight: '21px',
    textAlign: 'center',
  },
  terms: {
    color: '#fff',
    width: 180,
    margin: '40px auto 0',
    fontSize: 10,
    lineHeight: '15px',
    textAlign: 'center',
  },
  link: {
    color: '#fff !important',
    borderBottom: '1px solid #fff !important',
    textDecoration: 'none !important',
  },
  button: {
    marginTop: 24,
  },
});

type Props = {
  couponId: string;
};

export const PreGame: React.FC<Props> = ({couponId}) => {
  const {t} = useTranslation();
  const [nickname, setNickname] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isTocOpen, setIsTocOpen] = useState<boolean>(false);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNickname(event.target.value);
  };

  const onClose = () => {
    setNickname('');
  };

  const startGameMutation = useMutation({
    mutationFn: StartGameMutationFn({
      customerClient: CustomerClient(),
    }),
    onSuccess(data, variables) {
      window.location.href = data.tableUrl;
    },
    onError(err: any) {
      setError(err instanceof ClientError ? err.details : err.message);
      setLoading(false);
    },
  });

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError('');
    setLoading(true);
    startGameMutation.mutate({
      couponId,
      nickname,
    });
  };

  const onDialogClose = () => {
    setIsTocOpen(false);
  };

  const onTocClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setIsTocOpen(true);
  };

  return (
    <div className={classes.root}>
      <PokerBackground />
      <div className={classes.header}>
        <img width={180} className={classes.logo} alt="logo" src="/logo.svg" />
      </div>
      <div className={classes.content}>
        <div className={classes.text}>
          <span>{t('Welcome! To start playing, enter your nickname.')}</span>
        </div>
        <form onSubmit={onSubmit} className={classes.form}>
          <Input
            label={t('Your nickname')}
            error={error}
            value={nickname}
            onClose={onClose}
            onChange={onChange}
          />
          <Button
            className={classes.button}
            loading={loading}
            text={t('Play')}
            primary
            disabled={nickname.length === 0 || loading}
          />
        </form>
      </div>
      <footer className={classes.footer}>
        <div className={classes.terms}>
          {t('By pressing Play, you agree to our')}
          <br />
          <a href="/" onClick={onTocClick} className={classes.link}>
            {t('Terms & Conditions')}
          </a>
        </div>
      </footer>
      <Toc isOpen={isTocOpen} onClose={onDialogClose} />
    </div>
  );
};

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "deeplay.jackpoker_fast_funnel.customer.v1";

export interface GetCouponInfoRequest {
  couponId: string;
}

export interface GetCouponInfoResponse {
  state?:
    | { $case: "preGame"; preGame: GetCouponInfoResponse_PreGame }
    | { $case: "inGame"; inGame: GetCouponInfoResponse_InGame }
    | {
        $case: "gameFinished";
        gameFinished: GetCouponInfoResponse_GameFinished;
      }
    | {
        $case: "cashOutRequested";
        cashOutRequested: GetCouponInfoResponse_CashOutRequested;
      }
    | { $case: "cashedOut"; cashedOut: GetCouponInfoResponse_CashedOut };
}

export interface GetCouponInfoResponse_PreGame {}

export interface GetCouponInfoResponse_InGame {
  /**
   * Deprecated — для получения `table_url` надо пользоваться методами
   * `StartGame` и `ResumeGame`.
   *
   * @deprecated
   */
  tableUrl: string;
  bigBlindCents: Long;
}

export interface GetCouponInfoResponse_GameFinished {
  bigBlindCents: Long;
  /**
   * Размер выигрыша в центах, т.е. полное количество денег, выведенных со
   * стола.
   * Если кастомер всё проиграл — то 0.
   */
  resultCents: Long;
}

export interface GetCouponInfoResponse_CashOutRequested {
  bigBlindCents: Long;
  /**
   * Размер выигрыша в центах, т.е. полное количество денег, выведенных со
   * стола.
   * Если кастомер всё проиграл — то 0.
   */
  resultCents: Long;
}

export interface GetCouponInfoResponse_CashedOut {
  bigBlindCents: Long;
  /**
   * Размер выигрыша в центах, т.е. полное количество денег, выведенных со
   * стола.
   * Если кастомер всё проиграл — то 0.
   */
  resultCents: Long;
}

export interface StartGameRequest {
  couponId: string;
  nickname: string;
}

export interface StartGameResponse {
  tableUrl: string;
}

export interface ResumeGameRequest {
  couponId: string;
}

export interface ResumeGameResponse {
  tableUrl: string;
}

export interface FinishGameRequest {
  couponId: string;
}

export interface FinishGameResponse {}

export interface RequestCashOutRequest {
  couponId: string;
}

export interface RequestCashOutResponse {}

export interface ListCountriesRequest {}

export interface ListCountriesResponse {
  items: ListCountriesResponse_Item[];
}

export interface ListCountriesResponse_Item {
  id: string;
  /**
   * Код страны.
   * Примеры: `BR`, `CA`.
   */
  isoCode: string;
  name: string;
  /** Телефонный код страны. */
  dialCode: number;
  /**
   * Формат номера телефона.
   * Примеры: `+..-..-....-.....`, `+. (...) ...-....`.
   */
  phoneFormat: string;
}

export interface GetRegistrationStateRequest {
  couponId: string;
}

export interface GetRegistrationStateResponse {
  status: GetRegistrationStateResponse_Status;
  /** Пусто, если регистрация не была начата. */
  countryId: string;
  /** Пусто, если регистрация не была начата. */
  phoneNumber: string;
}

export enum GetRegistrationStateResponse_Status {
  NOT_STARTED = "NOT_STARTED",
  STARTED = "STARTED",
  VERIFIED = "VERIFIED",
  COMPLETED = "COMPLETED",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function getRegistrationStateResponse_StatusFromJSON(
  object: any
): GetRegistrationStateResponse_Status {
  switch (object) {
    case 0:
    case "NOT_STARTED":
      return GetRegistrationStateResponse_Status.NOT_STARTED;
    case 1:
    case "STARTED":
      return GetRegistrationStateResponse_Status.STARTED;
    case 2:
    case "VERIFIED":
      return GetRegistrationStateResponse_Status.VERIFIED;
    case 3:
    case "COMPLETED":
      return GetRegistrationStateResponse_Status.COMPLETED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return GetRegistrationStateResponse_Status.UNRECOGNIZED;
  }
}

export function getRegistrationStateResponse_StatusToNumber(
  object: GetRegistrationStateResponse_Status
): number {
  switch (object) {
    case GetRegistrationStateResponse_Status.NOT_STARTED:
      return 0;
    case GetRegistrationStateResponse_Status.STARTED:
      return 1;
    case GetRegistrationStateResponse_Status.VERIFIED:
      return 2;
    case GetRegistrationStateResponse_Status.COMPLETED:
      return 3;
    default:
      return 0;
  }
}

export interface StartRegistrationRequest {
  couponId: string;
  /** Идентификатор страны, полученный в `ListCountriesResponse`. */
  countryId: string;
  /** Номер телефона в формате `+12345678900`. */
  phoneNumber: string;
}

export interface StartRegistrationResponse {
  validationErrors: StartRegistrationResponse_ValidationError[];
}

export interface StartRegistrationResponse_ValidationError {
  field?:
    | { $case: "countryId"; countryId: string }
    | { $case: "phoneNumber"; phoneNumber: string };
}

export interface VerifyPhoneNumberRequest {
  couponId: string;
  verificationCode: string;
}

export interface VerifyPhoneNumberResponse {
  validationErrors: VerifyPhoneNumberResponse_ValidationError[];
}

export interface VerifyPhoneNumberResponse_ValidationError {
  field?: { $case: "verificationCode"; verificationCode: string };
}

export interface ResendVerificationCodeRequest {
  couponId: string;
}

export interface ResendVerificationCodeResponse {}

export interface CompleteRegistrationRequest {
  couponId: string;
  nickname: string;
  email: string;
  password: string;
}

export interface CompleteRegistrationResponse {
  /**
   * Урл JackPoker, на который надо редиректнуть кастомера после регистрации.
   * Это может быть простая ссылка либо ссылка с залогиненной сессией.
   *
   * Пусто, если есть ошибки валидации.
   */
  redirectUrl: string;
  validationErrors: CompleteRegistrationResponse_ValidationError[];
}

export interface CompleteRegistrationResponse_ValidationError {
  field?:
    | { $case: "nickname"; nickname: string }
    | { $case: "email"; email: string }
    | { $case: "password"; password: string };
}

function createBaseGetCouponInfoRequest(): GetCouponInfoRequest {
  return { couponId: "" };
}

export const GetCouponInfoRequest = {
  encode(
    message: GetCouponInfoRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.couponId !== "") {
      writer.uint32(10).string(message.couponId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): GetCouponInfoRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCouponInfoRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.couponId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<GetCouponInfoRequest>): GetCouponInfoRequest {
    const message = createBaseGetCouponInfoRequest();
    message.couponId = object.couponId ?? "";
    return message;
  },
};

function createBaseGetCouponInfoResponse(): GetCouponInfoResponse {
  return { state: undefined };
}

export const GetCouponInfoResponse = {
  encode(
    message: GetCouponInfoResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.state?.$case === "preGame") {
      GetCouponInfoResponse_PreGame.encode(
        message.state.preGame,
        writer.uint32(10).fork()
      ).ldelim();
    }
    if (message.state?.$case === "inGame") {
      GetCouponInfoResponse_InGame.encode(
        message.state.inGame,
        writer.uint32(18).fork()
      ).ldelim();
    }
    if (message.state?.$case === "gameFinished") {
      GetCouponInfoResponse_GameFinished.encode(
        message.state.gameFinished,
        writer.uint32(26).fork()
      ).ldelim();
    }
    if (message.state?.$case === "cashOutRequested") {
      GetCouponInfoResponse_CashOutRequested.encode(
        message.state.cashOutRequested,
        writer.uint32(42).fork()
      ).ldelim();
    }
    if (message.state?.$case === "cashedOut") {
      GetCouponInfoResponse_CashedOut.encode(
        message.state.cashedOut,
        writer.uint32(34).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): GetCouponInfoResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCouponInfoResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.state = {
            $case: "preGame",
            preGame: GetCouponInfoResponse_PreGame.decode(
              reader,
              reader.uint32()
            ),
          };
          break;
        case 2:
          message.state = {
            $case: "inGame",
            inGame: GetCouponInfoResponse_InGame.decode(
              reader,
              reader.uint32()
            ),
          };
          break;
        case 3:
          message.state = {
            $case: "gameFinished",
            gameFinished: GetCouponInfoResponse_GameFinished.decode(
              reader,
              reader.uint32()
            ),
          };
          break;
        case 5:
          message.state = {
            $case: "cashOutRequested",
            cashOutRequested: GetCouponInfoResponse_CashOutRequested.decode(
              reader,
              reader.uint32()
            ),
          };
          break;
        case 4:
          message.state = {
            $case: "cashedOut",
            cashedOut: GetCouponInfoResponse_CashedOut.decode(
              reader,
              reader.uint32()
            ),
          };
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<GetCouponInfoResponse>
  ): GetCouponInfoResponse {
    const message = createBaseGetCouponInfoResponse();
    if (
      object.state?.$case === "preGame" &&
      object.state?.preGame !== undefined &&
      object.state?.preGame !== null
    ) {
      message.state = {
        $case: "preGame",
        preGame: GetCouponInfoResponse_PreGame.fromPartial(
          object.state.preGame
        ),
      };
    }
    if (
      object.state?.$case === "inGame" &&
      object.state?.inGame !== undefined &&
      object.state?.inGame !== null
    ) {
      message.state = {
        $case: "inGame",
        inGame: GetCouponInfoResponse_InGame.fromPartial(object.state.inGame),
      };
    }
    if (
      object.state?.$case === "gameFinished" &&
      object.state?.gameFinished !== undefined &&
      object.state?.gameFinished !== null
    ) {
      message.state = {
        $case: "gameFinished",
        gameFinished: GetCouponInfoResponse_GameFinished.fromPartial(
          object.state.gameFinished
        ),
      };
    }
    if (
      object.state?.$case === "cashOutRequested" &&
      object.state?.cashOutRequested !== undefined &&
      object.state?.cashOutRequested !== null
    ) {
      message.state = {
        $case: "cashOutRequested",
        cashOutRequested: GetCouponInfoResponse_CashOutRequested.fromPartial(
          object.state.cashOutRequested
        ),
      };
    }
    if (
      object.state?.$case === "cashedOut" &&
      object.state?.cashedOut !== undefined &&
      object.state?.cashedOut !== null
    ) {
      message.state = {
        $case: "cashedOut",
        cashedOut: GetCouponInfoResponse_CashedOut.fromPartial(
          object.state.cashedOut
        ),
      };
    }
    return message;
  },
};

function createBaseGetCouponInfoResponse_PreGame(): GetCouponInfoResponse_PreGame {
  return {};
}

export const GetCouponInfoResponse_PreGame = {
  encode(
    _: GetCouponInfoResponse_PreGame,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): GetCouponInfoResponse_PreGame {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCouponInfoResponse_PreGame();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    _: DeepPartial<GetCouponInfoResponse_PreGame>
  ): GetCouponInfoResponse_PreGame {
    const message = createBaseGetCouponInfoResponse_PreGame();
    return message;
  },
};

function createBaseGetCouponInfoResponse_InGame(): GetCouponInfoResponse_InGame {
  return { tableUrl: "", bigBlindCents: Long.ZERO };
}

export const GetCouponInfoResponse_InGame = {
  encode(
    message: GetCouponInfoResponse_InGame,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.tableUrl !== "") {
      writer.uint32(10).string(message.tableUrl);
    }
    if (!message.bigBlindCents.isZero()) {
      writer.uint32(16).int64(message.bigBlindCents);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): GetCouponInfoResponse_InGame {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCouponInfoResponse_InGame();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.tableUrl = reader.string();
          break;
        case 2:
          message.bigBlindCents = reader.int64() as Long;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<GetCouponInfoResponse_InGame>
  ): GetCouponInfoResponse_InGame {
    const message = createBaseGetCouponInfoResponse_InGame();
    message.tableUrl = object.tableUrl ?? "";
    message.bigBlindCents =
      object.bigBlindCents !== undefined && object.bigBlindCents !== null
        ? Long.fromValue(object.bigBlindCents)
        : Long.ZERO;
    return message;
  },
};

function createBaseGetCouponInfoResponse_GameFinished(): GetCouponInfoResponse_GameFinished {
  return { bigBlindCents: Long.ZERO, resultCents: Long.ZERO };
}

export const GetCouponInfoResponse_GameFinished = {
  encode(
    message: GetCouponInfoResponse_GameFinished,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (!message.bigBlindCents.isZero()) {
      writer.uint32(16).int64(message.bigBlindCents);
    }
    if (!message.resultCents.isZero()) {
      writer.uint32(8).int64(message.resultCents);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): GetCouponInfoResponse_GameFinished {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCouponInfoResponse_GameFinished();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          message.bigBlindCents = reader.int64() as Long;
          break;
        case 1:
          message.resultCents = reader.int64() as Long;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<GetCouponInfoResponse_GameFinished>
  ): GetCouponInfoResponse_GameFinished {
    const message = createBaseGetCouponInfoResponse_GameFinished();
    message.bigBlindCents =
      object.bigBlindCents !== undefined && object.bigBlindCents !== null
        ? Long.fromValue(object.bigBlindCents)
        : Long.ZERO;
    message.resultCents =
      object.resultCents !== undefined && object.resultCents !== null
        ? Long.fromValue(object.resultCents)
        : Long.ZERO;
    return message;
  },
};

function createBaseGetCouponInfoResponse_CashOutRequested(): GetCouponInfoResponse_CashOutRequested {
  return { bigBlindCents: Long.ZERO, resultCents: Long.ZERO };
}

export const GetCouponInfoResponse_CashOutRequested = {
  encode(
    message: GetCouponInfoResponse_CashOutRequested,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (!message.bigBlindCents.isZero()) {
      writer.uint32(16).int64(message.bigBlindCents);
    }
    if (!message.resultCents.isZero()) {
      writer.uint32(8).int64(message.resultCents);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): GetCouponInfoResponse_CashOutRequested {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCouponInfoResponse_CashOutRequested();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          message.bigBlindCents = reader.int64() as Long;
          break;
        case 1:
          message.resultCents = reader.int64() as Long;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<GetCouponInfoResponse_CashOutRequested>
  ): GetCouponInfoResponse_CashOutRequested {
    const message = createBaseGetCouponInfoResponse_CashOutRequested();
    message.bigBlindCents =
      object.bigBlindCents !== undefined && object.bigBlindCents !== null
        ? Long.fromValue(object.bigBlindCents)
        : Long.ZERO;
    message.resultCents =
      object.resultCents !== undefined && object.resultCents !== null
        ? Long.fromValue(object.resultCents)
        : Long.ZERO;
    return message;
  },
};

function createBaseGetCouponInfoResponse_CashedOut(): GetCouponInfoResponse_CashedOut {
  return { bigBlindCents: Long.ZERO, resultCents: Long.ZERO };
}

export const GetCouponInfoResponse_CashedOut = {
  encode(
    message: GetCouponInfoResponse_CashedOut,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (!message.bigBlindCents.isZero()) {
      writer.uint32(16).int64(message.bigBlindCents);
    }
    if (!message.resultCents.isZero()) {
      writer.uint32(8).int64(message.resultCents);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): GetCouponInfoResponse_CashedOut {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCouponInfoResponse_CashedOut();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          message.bigBlindCents = reader.int64() as Long;
          break;
        case 1:
          message.resultCents = reader.int64() as Long;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<GetCouponInfoResponse_CashedOut>
  ): GetCouponInfoResponse_CashedOut {
    const message = createBaseGetCouponInfoResponse_CashedOut();
    message.bigBlindCents =
      object.bigBlindCents !== undefined && object.bigBlindCents !== null
        ? Long.fromValue(object.bigBlindCents)
        : Long.ZERO;
    message.resultCents =
      object.resultCents !== undefined && object.resultCents !== null
        ? Long.fromValue(object.resultCents)
        : Long.ZERO;
    return message;
  },
};

function createBaseStartGameRequest(): StartGameRequest {
  return { couponId: "", nickname: "" };
}

export const StartGameRequest = {
  encode(
    message: StartGameRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.couponId !== "") {
      writer.uint32(10).string(message.couponId);
    }
    if (message.nickname !== "") {
      writer.uint32(18).string(message.nickname);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StartGameRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartGameRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.couponId = reader.string();
          break;
        case 2:
          message.nickname = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<StartGameRequest>): StartGameRequest {
    const message = createBaseStartGameRequest();
    message.couponId = object.couponId ?? "";
    message.nickname = object.nickname ?? "";
    return message;
  },
};

function createBaseStartGameResponse(): StartGameResponse {
  return { tableUrl: "" };
}

export const StartGameResponse = {
  encode(
    message: StartGameResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.tableUrl !== "") {
      writer.uint32(10).string(message.tableUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StartGameResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartGameResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.tableUrl = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<StartGameResponse>): StartGameResponse {
    const message = createBaseStartGameResponse();
    message.tableUrl = object.tableUrl ?? "";
    return message;
  },
};

function createBaseResumeGameRequest(): ResumeGameRequest {
  return { couponId: "" };
}

export const ResumeGameRequest = {
  encode(
    message: ResumeGameRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.couponId !== "") {
      writer.uint32(10).string(message.couponId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResumeGameRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResumeGameRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.couponId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<ResumeGameRequest>): ResumeGameRequest {
    const message = createBaseResumeGameRequest();
    message.couponId = object.couponId ?? "";
    return message;
  },
};

function createBaseResumeGameResponse(): ResumeGameResponse {
  return { tableUrl: "" };
}

export const ResumeGameResponse = {
  encode(
    message: ResumeGameResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.tableUrl !== "") {
      writer.uint32(10).string(message.tableUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResumeGameResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResumeGameResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.tableUrl = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<ResumeGameResponse>): ResumeGameResponse {
    const message = createBaseResumeGameResponse();
    message.tableUrl = object.tableUrl ?? "";
    return message;
  },
};

function createBaseFinishGameRequest(): FinishGameRequest {
  return { couponId: "" };
}

export const FinishGameRequest = {
  encode(
    message: FinishGameRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.couponId !== "") {
      writer.uint32(10).string(message.couponId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FinishGameRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFinishGameRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.couponId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<FinishGameRequest>): FinishGameRequest {
    const message = createBaseFinishGameRequest();
    message.couponId = object.couponId ?? "";
    return message;
  },
};

function createBaseFinishGameResponse(): FinishGameResponse {
  return {};
}

export const FinishGameResponse = {
  encode(
    _: FinishGameResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FinishGameResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFinishGameResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<FinishGameResponse>): FinishGameResponse {
    const message = createBaseFinishGameResponse();
    return message;
  },
};

function createBaseRequestCashOutRequest(): RequestCashOutRequest {
  return { couponId: "" };
}

export const RequestCashOutRequest = {
  encode(
    message: RequestCashOutRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.couponId !== "") {
      writer.uint32(10).string(message.couponId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): RequestCashOutRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRequestCashOutRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.couponId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<RequestCashOutRequest>
  ): RequestCashOutRequest {
    const message = createBaseRequestCashOutRequest();
    message.couponId = object.couponId ?? "";
    return message;
  },
};

function createBaseRequestCashOutResponse(): RequestCashOutResponse {
  return {};
}

export const RequestCashOutResponse = {
  encode(
    _: RequestCashOutResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): RequestCashOutResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRequestCashOutResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<RequestCashOutResponse>): RequestCashOutResponse {
    const message = createBaseRequestCashOutResponse();
    return message;
  },
};

function createBaseListCountriesRequest(): ListCountriesRequest {
  return {};
}

export const ListCountriesRequest = {
  encode(
    _: ListCountriesRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListCountriesRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCountriesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<ListCountriesRequest>): ListCountriesRequest {
    const message = createBaseListCountriesRequest();
    return message;
  },
};

function createBaseListCountriesResponse(): ListCountriesResponse {
  return { items: [] };
}

export const ListCountriesResponse = {
  encode(
    message: ListCountriesResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.items) {
      ListCountriesResponse_Item.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListCountriesResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCountriesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.items.push(
            ListCountriesResponse_Item.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<ListCountriesResponse>
  ): ListCountriesResponse {
    const message = createBaseListCountriesResponse();
    message.items =
      object.items?.map((e) => ListCountriesResponse_Item.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListCountriesResponse_Item(): ListCountriesResponse_Item {
  return { id: "", isoCode: "", name: "", dialCode: 0, phoneFormat: "" };
}

export const ListCountriesResponse_Item = {
  encode(
    message: ListCountriesResponse_Item,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.isoCode !== "") {
      writer.uint32(18).string(message.isoCode);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.dialCode !== 0) {
      writer.uint32(32).uint32(message.dialCode);
    }
    if (message.phoneFormat !== "") {
      writer.uint32(42).string(message.phoneFormat);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListCountriesResponse_Item {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCountriesResponse_Item();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.isoCode = reader.string();
          break;
        case 3:
          message.name = reader.string();
          break;
        case 4:
          message.dialCode = reader.uint32();
          break;
        case 5:
          message.phoneFormat = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<ListCountriesResponse_Item>
  ): ListCountriesResponse_Item {
    const message = createBaseListCountriesResponse_Item();
    message.id = object.id ?? "";
    message.isoCode = object.isoCode ?? "";
    message.name = object.name ?? "";
    message.dialCode = object.dialCode ?? 0;
    message.phoneFormat = object.phoneFormat ?? "";
    return message;
  },
};

function createBaseGetRegistrationStateRequest(): GetRegistrationStateRequest {
  return { couponId: "" };
}

export const GetRegistrationStateRequest = {
  encode(
    message: GetRegistrationStateRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.couponId !== "") {
      writer.uint32(10).string(message.couponId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): GetRegistrationStateRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRegistrationStateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.couponId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<GetRegistrationStateRequest>
  ): GetRegistrationStateRequest {
    const message = createBaseGetRegistrationStateRequest();
    message.couponId = object.couponId ?? "";
    return message;
  },
};

function createBaseGetRegistrationStateResponse(): GetRegistrationStateResponse {
  return {
    status: GetRegistrationStateResponse_Status.NOT_STARTED,
    countryId: "",
    phoneNumber: "",
  };
}

export const GetRegistrationStateResponse = {
  encode(
    message: GetRegistrationStateResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.status !== GetRegistrationStateResponse_Status.NOT_STARTED) {
      writer
        .uint32(8)
        .int32(getRegistrationStateResponse_StatusToNumber(message.status));
    }
    if (message.countryId !== "") {
      writer.uint32(18).string(message.countryId);
    }
    if (message.phoneNumber !== "") {
      writer.uint32(26).string(message.phoneNumber);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): GetRegistrationStateResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRegistrationStateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = getRegistrationStateResponse_StatusFromJSON(
            reader.int32()
          );
          break;
        case 2:
          message.countryId = reader.string();
          break;
        case 3:
          message.phoneNumber = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<GetRegistrationStateResponse>
  ): GetRegistrationStateResponse {
    const message = createBaseGetRegistrationStateResponse();
    message.status =
      object.status ?? GetRegistrationStateResponse_Status.NOT_STARTED;
    message.countryId = object.countryId ?? "";
    message.phoneNumber = object.phoneNumber ?? "";
    return message;
  },
};

function createBaseStartRegistrationRequest(): StartRegistrationRequest {
  return { couponId: "", countryId: "", phoneNumber: "" };
}

export const StartRegistrationRequest = {
  encode(
    message: StartRegistrationRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.couponId !== "") {
      writer.uint32(10).string(message.couponId);
    }
    if (message.countryId !== "") {
      writer.uint32(18).string(message.countryId);
    }
    if (message.phoneNumber !== "") {
      writer.uint32(26).string(message.phoneNumber);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): StartRegistrationRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartRegistrationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.couponId = reader.string();
          break;
        case 2:
          message.countryId = reader.string();
          break;
        case 3:
          message.phoneNumber = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<StartRegistrationRequest>
  ): StartRegistrationRequest {
    const message = createBaseStartRegistrationRequest();
    message.couponId = object.couponId ?? "";
    message.countryId = object.countryId ?? "";
    message.phoneNumber = object.phoneNumber ?? "";
    return message;
  },
};

function createBaseStartRegistrationResponse(): StartRegistrationResponse {
  return { validationErrors: [] };
}

export const StartRegistrationResponse = {
  encode(
    message: StartRegistrationResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.validationErrors) {
      StartRegistrationResponse_ValidationError.encode(
        v!,
        writer.uint32(10).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): StartRegistrationResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartRegistrationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.validationErrors.push(
            StartRegistrationResponse_ValidationError.decode(
              reader,
              reader.uint32()
            )
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<StartRegistrationResponse>
  ): StartRegistrationResponse {
    const message = createBaseStartRegistrationResponse();
    message.validationErrors =
      object.validationErrors?.map((e) =>
        StartRegistrationResponse_ValidationError.fromPartial(e)
      ) || [];
    return message;
  },
};

function createBaseStartRegistrationResponse_ValidationError(): StartRegistrationResponse_ValidationError {
  return { field: undefined };
}

export const StartRegistrationResponse_ValidationError = {
  encode(
    message: StartRegistrationResponse_ValidationError,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.field?.$case === "countryId") {
      writer.uint32(10).string(message.field.countryId);
    }
    if (message.field?.$case === "phoneNumber") {
      writer.uint32(18).string(message.field.phoneNumber);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): StartRegistrationResponse_ValidationError {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartRegistrationResponse_ValidationError();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.field = { $case: "countryId", countryId: reader.string() };
          break;
        case 2:
          message.field = {
            $case: "phoneNumber",
            phoneNumber: reader.string(),
          };
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<StartRegistrationResponse_ValidationError>
  ): StartRegistrationResponse_ValidationError {
    const message = createBaseStartRegistrationResponse_ValidationError();
    if (
      object.field?.$case === "countryId" &&
      object.field?.countryId !== undefined &&
      object.field?.countryId !== null
    ) {
      message.field = { $case: "countryId", countryId: object.field.countryId };
    }
    if (
      object.field?.$case === "phoneNumber" &&
      object.field?.phoneNumber !== undefined &&
      object.field?.phoneNumber !== null
    ) {
      message.field = {
        $case: "phoneNumber",
        phoneNumber: object.field.phoneNumber,
      };
    }
    return message;
  },
};

function createBaseVerifyPhoneNumberRequest(): VerifyPhoneNumberRequest {
  return { couponId: "", verificationCode: "" };
}

export const VerifyPhoneNumberRequest = {
  encode(
    message: VerifyPhoneNumberRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.couponId !== "") {
      writer.uint32(10).string(message.couponId);
    }
    if (message.verificationCode !== "") {
      writer.uint32(18).string(message.verificationCode);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): VerifyPhoneNumberRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVerifyPhoneNumberRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.couponId = reader.string();
          break;
        case 2:
          message.verificationCode = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<VerifyPhoneNumberRequest>
  ): VerifyPhoneNumberRequest {
    const message = createBaseVerifyPhoneNumberRequest();
    message.couponId = object.couponId ?? "";
    message.verificationCode = object.verificationCode ?? "";
    return message;
  },
};

function createBaseVerifyPhoneNumberResponse(): VerifyPhoneNumberResponse {
  return { validationErrors: [] };
}

export const VerifyPhoneNumberResponse = {
  encode(
    message: VerifyPhoneNumberResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.validationErrors) {
      VerifyPhoneNumberResponse_ValidationError.encode(
        v!,
        writer.uint32(10).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): VerifyPhoneNumberResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVerifyPhoneNumberResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.validationErrors.push(
            VerifyPhoneNumberResponse_ValidationError.decode(
              reader,
              reader.uint32()
            )
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<VerifyPhoneNumberResponse>
  ): VerifyPhoneNumberResponse {
    const message = createBaseVerifyPhoneNumberResponse();
    message.validationErrors =
      object.validationErrors?.map((e) =>
        VerifyPhoneNumberResponse_ValidationError.fromPartial(e)
      ) || [];
    return message;
  },
};

function createBaseVerifyPhoneNumberResponse_ValidationError(): VerifyPhoneNumberResponse_ValidationError {
  return { field: undefined };
}

export const VerifyPhoneNumberResponse_ValidationError = {
  encode(
    message: VerifyPhoneNumberResponse_ValidationError,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.field?.$case === "verificationCode") {
      writer.uint32(10).string(message.field.verificationCode);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): VerifyPhoneNumberResponse_ValidationError {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVerifyPhoneNumberResponse_ValidationError();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.field = {
            $case: "verificationCode",
            verificationCode: reader.string(),
          };
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<VerifyPhoneNumberResponse_ValidationError>
  ): VerifyPhoneNumberResponse_ValidationError {
    const message = createBaseVerifyPhoneNumberResponse_ValidationError();
    if (
      object.field?.$case === "verificationCode" &&
      object.field?.verificationCode !== undefined &&
      object.field?.verificationCode !== null
    ) {
      message.field = {
        $case: "verificationCode",
        verificationCode: object.field.verificationCode,
      };
    }
    return message;
  },
};

function createBaseResendVerificationCodeRequest(): ResendVerificationCodeRequest {
  return { couponId: "" };
}

export const ResendVerificationCodeRequest = {
  encode(
    message: ResendVerificationCodeRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.couponId !== "") {
      writer.uint32(10).string(message.couponId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ResendVerificationCodeRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResendVerificationCodeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.couponId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<ResendVerificationCodeRequest>
  ): ResendVerificationCodeRequest {
    const message = createBaseResendVerificationCodeRequest();
    message.couponId = object.couponId ?? "";
    return message;
  },
};

function createBaseResendVerificationCodeResponse(): ResendVerificationCodeResponse {
  return {};
}

export const ResendVerificationCodeResponse = {
  encode(
    _: ResendVerificationCodeResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ResendVerificationCodeResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResendVerificationCodeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    _: DeepPartial<ResendVerificationCodeResponse>
  ): ResendVerificationCodeResponse {
    const message = createBaseResendVerificationCodeResponse();
    return message;
  },
};

function createBaseCompleteRegistrationRequest(): CompleteRegistrationRequest {
  return { couponId: "", nickname: "", email: "", password: "" };
}

export const CompleteRegistrationRequest = {
  encode(
    message: CompleteRegistrationRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.couponId !== "") {
      writer.uint32(10).string(message.couponId);
    }
    if (message.nickname !== "") {
      writer.uint32(18).string(message.nickname);
    }
    if (message.email !== "") {
      writer.uint32(26).string(message.email);
    }
    if (message.password !== "") {
      writer.uint32(34).string(message.password);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): CompleteRegistrationRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompleteRegistrationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.couponId = reader.string();
          break;
        case 2:
          message.nickname = reader.string();
          break;
        case 3:
          message.email = reader.string();
          break;
        case 4:
          message.password = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<CompleteRegistrationRequest>
  ): CompleteRegistrationRequest {
    const message = createBaseCompleteRegistrationRequest();
    message.couponId = object.couponId ?? "";
    message.nickname = object.nickname ?? "";
    message.email = object.email ?? "";
    message.password = object.password ?? "";
    return message;
  },
};

function createBaseCompleteRegistrationResponse(): CompleteRegistrationResponse {
  return { redirectUrl: "", validationErrors: [] };
}

export const CompleteRegistrationResponse = {
  encode(
    message: CompleteRegistrationResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.redirectUrl !== "") {
      writer.uint32(18).string(message.redirectUrl);
    }
    for (const v of message.validationErrors) {
      CompleteRegistrationResponse_ValidationError.encode(
        v!,
        writer.uint32(10).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): CompleteRegistrationResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompleteRegistrationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          message.redirectUrl = reader.string();
          break;
        case 1:
          message.validationErrors.push(
            CompleteRegistrationResponse_ValidationError.decode(
              reader,
              reader.uint32()
            )
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<CompleteRegistrationResponse>
  ): CompleteRegistrationResponse {
    const message = createBaseCompleteRegistrationResponse();
    message.redirectUrl = object.redirectUrl ?? "";
    message.validationErrors =
      object.validationErrors?.map((e) =>
        CompleteRegistrationResponse_ValidationError.fromPartial(e)
      ) || [];
    return message;
  },
};

function createBaseCompleteRegistrationResponse_ValidationError(): CompleteRegistrationResponse_ValidationError {
  return { field: undefined };
}

export const CompleteRegistrationResponse_ValidationError = {
  encode(
    message: CompleteRegistrationResponse_ValidationError,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.field?.$case === "nickname") {
      writer.uint32(10).string(message.field.nickname);
    }
    if (message.field?.$case === "email") {
      writer.uint32(18).string(message.field.email);
    }
    if (message.field?.$case === "password") {
      writer.uint32(26).string(message.field.password);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): CompleteRegistrationResponse_ValidationError {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompleteRegistrationResponse_ValidationError();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.field = { $case: "nickname", nickname: reader.string() };
          break;
        case 2:
          message.field = { $case: "email", email: reader.string() };
          break;
        case 3:
          message.field = { $case: "password", password: reader.string() };
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<CompleteRegistrationResponse_ValidationError>
  ): CompleteRegistrationResponse_ValidationError {
    const message = createBaseCompleteRegistrationResponse_ValidationError();
    if (
      object.field?.$case === "nickname" &&
      object.field?.nickname !== undefined &&
      object.field?.nickname !== null
    ) {
      message.field = { $case: "nickname", nickname: object.field.nickname };
    }
    if (
      object.field?.$case === "email" &&
      object.field?.email !== undefined &&
      object.field?.email !== null
    ) {
      message.field = { $case: "email", email: object.field.email };
    }
    if (
      object.field?.$case === "password" &&
      object.field?.password !== undefined &&
      object.field?.password !== null
    ) {
      message.field = { $case: "password", password: object.field.password };
    }
    return message;
  },
};

export const CustomerDefinition = {
  name: "Customer",
  fullName: "deeplay.jackpoker_fast_funnel.customer.v1.Customer",
  methods: {
    /** Возвращает информацию о текущем состоянии купона. */
    getCouponInfo: {
      name: "GetCouponInfo",
      requestType: GetCouponInfoRequest,
      requestStream: false,
      responseType: GetCouponInfoResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "NO_SIDE_EFFECTS",
      },
    },
    /**
     * Регистрирует игрока, начисляет ему баланс и возвращает ссылку на стол с
     * игрой.
     *
     * Этот метод вызывается при сабмите формы с никнеймом.
     */
    startGame: {
      name: "StartGame",
      requestType: StartGameRequest,
      requestStream: false,
      responseType: StartGameResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "IDEMPOTENT",
      },
    },
    /** Возобновляет игровую сессию и возвращает ссылку на стол с игрой. */
    resumeGame: {
      name: "ResumeGame",
      requestType: ResumeGameRequest,
      requestStream: false,
      responseType: ResumeGameResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "IDEMPOTENT",
      },
    },
    /**
     * Завершает игру.
     *
     * Этот метод вызывается, когда кастомер нажал кнопку завершения игры в
     * покерном приложении, и его редиректнуло на наш вебапп.
     */
    finishGame: {
      name: "FinishGame",
      requestType: FinishGameRequest,
      requestStream: false,
      responseType: FinishGameResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "IDEMPOTENT",
      },
    },
    /** Двигает купон в статус запроса денег от агента. */
    requestCashOut: {
      name: "RequestCashOut",
      requestType: RequestCashOutRequest,
      requestStream: false,
      responseType: RequestCashOutResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "IDEMPOTENT",
      },
    },
    /** Возвращает список стран с форматами номеров телефонов. */
    listCountries: {
      name: "ListCountries",
      requestType: ListCountriesRequest,
      requestStream: false,
      responseType: ListCountriesResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "NO_SIDE_EFFECTS",
      },
    },
    /** Возвращает текущее состояние регистрации. */
    getRegistrationState: {
      name: "GetRegistrationState",
      requestType: GetRegistrationStateRequest,
      requestStream: false,
      responseType: GetRegistrationStateResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "NO_SIDE_EFFECTS",
      },
    },
    /**
     * Начинает флоу регистрации пользователя в JackPoker. В результате на номер
     * телефона отправляется СМС с кодом верификации.
     */
    startRegistration: {
      name: "StartRegistration",
      requestType: StartRegistrationRequest,
      requestStream: false,
      responseType: StartRegistrationResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "IDEMPOTENT",
      },
    },
    /** Подтверждает номер телефона пользователя в JackPoker с помощью кода из СМС. */
    verifyPhoneNumber: {
      name: "VerifyPhoneNumber",
      requestType: VerifyPhoneNumberRequest,
      requestStream: false,
      responseType: VerifyPhoneNumberResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "IDEMPOTENT",
      },
    },
    /** Повторно отправляет СМС с кодом верификации. */
    resendVerificationCode: {
      name: "ResendVerificationCode",
      requestType: ResendVerificationCodeRequest,
      requestStream: false,
      responseType: ResendVerificationCodeResponse,
      responseStream: false,
      options: {},
    },
    /** Завершает флоу регистрации пользователя в JackPoker. */
    completeRegistration: {
      name: "CompleteRegistration",
      requestType: CompleteRegistrationRequest,
      requestStream: false,
      responseType: CompleteRegistrationResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "IDEMPOTENT",
      },
    },
  },
} as const;

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Long
  ? string | number | Long
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string }
  ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & {
      $case: T["$case"];
    }
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

const codeSentTimestampKey = 'smsCodeSentTimestamp';

export const getCodeSentTimestamp = () => {
  const stored = localStorage.getItem(codeSentTimestampKey);

  if (stored == null) {
    return undefined;
  }

  return parseInt(stored, 10);
};

export const setCodeSentTimestamp = () => {
  localStorage.setItem(codeSentTimestampKey, `${+Date.now()}`);
};

export const removeCodeSentTimestamp = () => {
  localStorage.removeItem(codeSentTimestampKey);
};

import React, {useState} from 'react';
import {stylesheet, classes as cx, cssRaw} from 'typestyle';
import InputMask from 'react-input-mask';
import {Colors} from './colors';

cssRaw(`
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}
`);

const classes = stylesheet({
  formGroup: {
    position: 'relative',
  },
  label: {
    position: 'absolute',
    top: 2,
    left: 0,
    transform: 'translate(16px, 16px) scale(1)',
    transition: '200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    pointerEvents: 'none',
    color: Colors.JPViolet4,
  },
  focused: {
    fontSize: '10px',
    lineHeight: '15px',
    transform: 'translate(16px, 8px) scale(1)',
  },
  input: {
    width: '100%',
    padding: '24px 16px 10px',
    background: 'rgba(0, 0, 0, 0.5)',
    border: '1px solid rgba(0,0,0,0)',
    boxShadow: 'none !important',
    borderRadius: '4px',
    display: 'block',
    fontSize: 14,
    lineHeight: '21px',
    caretColor: Colors.JPViolet4,
    color: '#fff',
  },
  inputError: {
    border: '1px solid #E72518',
  },
  inputFocused: {
    border: '1px solid #6919D7',
  },
  errorText: {
    color: '#E72518',
    fontSize: 10,
    lineHeight: '15px',
    marginLeft: 16,
  },
  closeIcon: {
    cursor: 'pointer',
    margin: 10,
    width: 21,
    height: 21,
    borderRadius: 10,
    background: 'rgba(255, 255, 255, 0.07)',
    position: 'absolute',
    top: 6,
    right: 10,
    $nest: {
      '&::before': {
        display: 'block',
        content: "''",
        width: 1,
        height: 10,
        position: 'absolute',
        top: 5,
        left: 10,
        background: '#fff',
        transform: 'rotate(45deg)',
      },
      '&::after': {
        display: 'block',
        content: "''",
        width: 1,
        height: 10,
        position: 'absolute',
        top: 5,
        left: 10,
        background: '#fff',
        transform: 'rotate(-45deg)',
      },
    },
  },
  eyeIcon: {
    position: 'absolute',
    margin: 21,
    top: 0,
    right: 0,
    width: 20,
    height: 20,
    background: 'url(/eye.svg)',
    backgroundRepeat: 'no-repeat',
    $nest: {
      '&.hide': {
        background: 'url(/eye-crossed.svg)',
        marginTop: 18,
      },
    },
  },
  disabled: {
    color: Colors.JPViolet4,
    opacity: 0.7,
  },
});

type Props = {
  error?: string;
  onClose?: () => void;
  label?: string;
  mask?: string | string[];
} & React.InputHTMLAttributes<HTMLInputElement>;

export const Input: React.FC<Props> = ({
  error,
  value,
  onChange,
  onClose,
  label,
  placeholder,
  mask,
  ...props
}) => {
  const [focus, setFocus] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const onBlur = () => {
    setFocus(false);
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event);
  };

  const onFocus = () => {
    setFocus(true);
  };

  const onClick = () => {
    setFocus(false);
    onClose && onClose();
  };

  const onShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  const hasValue =
    (typeof value === 'number' && value !== undefined) ||
    (value && value?.length > 0);

  const type =
    props.type === 'password'
      ? showPassword
        ? 'text'
        : 'password'
      : props.type ?? 'text';

  const labelClassName =
    focus || (!focus && hasValue)
      ? cx(classes.label, classes.focused)
      : classes.label;
  const inputClassName = error
    ? cx(classes.input, classes.inputError)
    : focus
    ? cx(classes.input, classes.inputFocused)
    : classes.input;

  return (
    <div className={cx(classes.formGroup, props.className)}>
      <label className={labelClassName}>{label ?? placeholder}</label>
      {type !== 'tel' ? (
        <input
          {...props}
          onChange={onInputChange}
          onFocus={onFocus}
          onBlur={onBlur}
          className={cx(inputClassName, props.disabled ? classes.disabled : '')}
          value={value}
          type={type}
        />
      ) : (
        <InputMask
          {...props}
          mask={mask ?? []}
          onChange={onInputChange}
          onFocus={onFocus}
          onBlur={onBlur}
          className={cx(inputClassName, props.disabled ? classes.disabled : '')}
          value={value}
        />
      )}
      {!props.disabled && hasValue && props.type !== 'password' ? (
        <div onClick={onClick} className={classes.closeIcon} />
      ) : (
        ''
      )}
      {!props.disabled && hasValue && props.type === 'password' ? (
        <div
          onClick={onShowPasswordClick}
          className={cx(classes.eyeIcon, showPassword ? 'hide' : '')}
        />
      ) : (
        ''
      )}
      {error?.length ? <div className={classes.errorText}>{error}</div> : ''}
    </div>
  );
};

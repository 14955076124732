import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

const en = require('./lang/en.json');
const pt = require('./lang/pt.json');

const LNG_KEY = 'lng';

export const saveLanguage = (lng: string) => {
  localStorage.setItem(LNG_KEY, lng);
};

const loadLanguage = () => {
  return localStorage.getItem(LNG_KEY);
};

i18n.use(initReactI18next).init({
  debug: true,
  lng: loadLanguage() ?? 'pt',
  fallbackLng: 'en',
  resources: {
    en,
    pt,
  },
});

export default i18n;

import {MutationFunction, QueryClient} from 'react-query';
import {AgentClient} from '../../../client';
import {
  DeepPartial,
  CashOutCouponRequest,
  CashOutCouponResponse,
} from '../../../proto/deeplay/jackpoker_fast_funnel/agent/v1/agent';

export type CashOutCouponMutationVariables = DeepPartial<CashOutCouponRequest>;

export type CashOutCouponMutationData = CashOutCouponResponse;

export type CashOutCouponMutationDeps = {
  agentClient: AgentClient;
};

export type CashOutCouponMutationFn = MutationFunction<
  CashOutCouponMutationData,
  CashOutCouponMutationVariables
>;

export function CashOutCouponMutationFn({
  agentClient,
}: CashOutCouponMutationDeps): CashOutCouponMutationFn {
  return async request => {
    return await agentClient.cashOutCoupon(request);
  };
}

export function postCashOutCouponMutation(
  queryClient: QueryClient,
  data: CashOutCouponMutationData,
  request: CashOutCouponMutationVariables,
) {
  queryClient.invalidateQueries(['list-operations']);
  queryClient.invalidateQueries(['get-balance']);
}

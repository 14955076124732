import {stylesheet, classes as cx} from 'typestyle';
import {Spinner, SpinnerSize} from '@blueprintjs/core';
import {AuthLocationState, useAuth} from '../Auth';
import {useLocation, useNavigate} from 'react-router-dom';
import {FormEvent} from 'react';
import {useState} from 'react';
import {Button} from './common/Button';
import {Input} from './common/Input';
import {ContactUs} from './common/ContactUs';
import assert from 'assert';
import {useTranslation} from 'react-i18next';
import {saveLanguage} from '../i18n';

const classes = stylesheet({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 640,
    margin: '0 auto',
    padding: 20,
    alignSelf: 'center',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    marginTop: 60,
  },
  form: {
    maxWidth: 290,
    width: '100%',
    textAlign: 'center',
  },
  text: {
    display: 'inline-block',
    marginBottom: 16,
    fontSize: 16,
    fontWeight: 600,
  },
  button: {
    marginTop: 32,
    width: '100%',
  },
  error: {
    marginTop: 8,
    fontSize: 12,
    color: '#E72518',
  },
  footer: {
    fontSize: 12,
    fontWeight: 300,
  },
  link: {
    cursor: 'pointer',
    fontWeight: 500,
    textDecoration: 'underline',
  },
  languageButtons: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'row',
  },
  languageButton: {
    width: '50%',
    color: '#fff',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  current: {
    textDecoration: 'none',
    color: '#8C3CFA',
  },
});

export const Login = () => {
  const {t, i18n} = useTranslation();
  const {logIn, loggingIn, error} = useAuth();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isHelpOpened, setIsHelpOpened] = useState<boolean>(false);
  const location = useLocation();
  const state = location.state as AuthLocationState;
  const navigate = useNavigate();

  assert(state.from != null);

  const onClick = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const {pathname} = window.location;
    const admin = pathname.includes('/admin');
    const to = state.from;

    logIn(email, password, admin).then(() => {
      navigate(to, {replace: true});
    });
  };

  const onClose = () => {
    setEmail('');
  };

  const onHelpClick = () => {
    setIsHelpOpened(true);
  };

  const onHelpClose = () => {
    setIsHelpOpened(false);
  };

  const setEn = () => {
    i18n.changeLanguage('en');
    saveLanguage('en');
  };
  const setPt = () => {
    i18n.changeLanguage('pt');
    saveLanguage('pt');
  };

  return (
    <div className={classes.root}>
      <img width={180} className={classes.logo} alt="logo" src="/logo.svg" />
      <form className={classes.form} autoComplete="off" onSubmit={onClick}>
        <span className={classes.text}>{t('Sign in')}</span>
        <Input
          placeholder={t('Login')}
          value={email}
          onClose={onClose}
          onChange={event => setEmail(event.target.value)}
        />
        <br />
        <Input
          placeholder={t('Password')}
          type="password"
          value={password}
          onChange={event => setPassword(event.target.value)}
        />
        {error && <span className={classes.error}>{error}</span>}
        <div className={classes.languageButtons}>
          <div
            onClick={setEn}
            className={cx(
              classes.languageButton,
              i18n.language === 'en' ? classes.current : '',
            )}
          >
            English
          </div>
          <div
            onClick={setPt}
            className={cx(
              classes.languageButton,
              i18n.language === 'pt' ? classes.current : '',
            )}
          >
            Portugal
          </div>
        </div>
        <Button
          disabled={loggingIn || email.length === 0 || password.length === 0}
          primary
          className={classes.button}
        >
          {loggingIn ? (
            <Spinner size={SpinnerSize.SMALL} />
          ) : (
            <span>{t('Sign in')}</span>
          )}
        </Button>
      </form>
      <div className={classes.footer}>
        {t('Need help?')}{' '}
        <span onClick={onHelpClick} className={classes.link}>
          {t('Contact us')}
        </span>
      </div>
      <ContactUs isOpen={isHelpOpened} onClose={onHelpClose} />
    </div>
  );
};

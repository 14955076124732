import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {stylesheet, classes as cx} from 'typestyle';
import {formatCents} from '../../../utils/formatCents';

const classes = stylesheet({
  root: {
    position: 'relative',
    padding: '10px 16px',
    width: 120,
    height: 36,
    background: '#290D50',
    border: '1px solid #3E1478',
    borderRadius: 4,
    fontSize: 12,
    textAlign: 'center',
  },
  opened: {
    background: '#0F041F',
    paddingRight: 52,
  },
  hide: {
    position: 'absolute',
    top: -1,
    right: -1,
    width: 36,
    height: 36,
    border: '1px solid #3E1478',
    background: '#290D50',
    paddingTop: 4,
    borderRadius: 4,
  },
  balance: {
    height: 36,
    textAlign: 'center',
  },
});

export const ViewBalance: React.FC<
  {balance: number} & React.HTMLProps<HTMLDivElement>
> = props => {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClick = () => setIsOpen(true);
  const onHide = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(false);
  };
  const balanceString = formatCents(props.balance);

  return (
    <div
      {...props}
      onClick={onClick}
      className={cx(classes.root, isOpen ? classes.opened : '')}
    >
      {isOpen ? (
        <>
          <div className={classes.balance}>{balanceString}</div>
          <div onClick={onHide} className={classes.hide}>
            <img alt="" src="/agent/hide.svg" />
          </div>
        </>
      ) : (
        t('View Balance')
      )}
    </div>
  );
};

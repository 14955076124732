import {MutationFunction, QueryClient} from 'react-query';
import {CustomerClient} from '../../../client';
import {
  DeepPartial,
  CompleteRegistrationRequest,
  CompleteRegistrationResponse,
} from '../../../proto/deeplay/jackpoker_fast_funnel/customer/v1/customer';

export type CompleteRegistrationMutationVariables =
  DeepPartial<CompleteRegistrationRequest>;

export type CompleteRegistrationMutationData = CompleteRegistrationResponse;

export type CompleteRegistrationMutationDeps = {
  customerClient: CustomerClient;
};

export type CompleteRegistrationMutationFn = MutationFunction<
  CompleteRegistrationMutationData,
  CompleteRegistrationMutationVariables
>;

export function CompleteRegistrationMutationFn({
  customerClient,
}: CompleteRegistrationMutationDeps): CompleteRegistrationMutationFn {
  return async request => {
    return await customerClient.completeRegistration(request);
  };
}

export function postCompleteRegistrationMutation(
  queryClient: QueryClient,
  data: CompleteRegistrationMutationData,
  request: CompleteRegistrationMutationVariables,
) {
  queryClient.invalidateQueries(['get-registration-state']);
}

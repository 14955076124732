import {QueryFunction} from 'react-query';
import {AgentClient} from '../../../client';
import {ListOperationsResponse} from '../../../proto/deeplay/jackpoker_fast_funnel/agent/v1/agent';

export type ListOperationsQueryKey = [tag: 'list-operations'];

export type ListOperationsQueryData = ListOperationsResponse;

export type ListOperationsQueryDeps = {
  agentClient: AgentClient;
};

export type ListOperationsQueryFn = QueryFunction<
  ListOperationsQueryData,
  ListOperationsQueryKey
>;

export function ListOperationsQueryFn({
  agentClient,
}: ListOperationsQueryDeps): ListOperationsQueryFn {
  return ({signal}) => agentClient.listOperations({}, {signal});
}

import {MutationFunction, QueryClient} from 'react-query';
import {CustomerClient} from '../../../client';
import {
  DeepPartial,
  ResendVerificationCodeRequest,
  ResendVerificationCodeResponse,
} from '../../../proto/deeplay/jackpoker_fast_funnel/customer/v1/customer';

export type ResendVerificationCodeMutationVariables =
  DeepPartial<ResendVerificationCodeRequest>;

export type ResendVerificationCodeMutationData = ResendVerificationCodeResponse;

export type ResendVerificationCodeMutationDeps = {
  customerClient: CustomerClient;
};

export type ResendVerificationCodeMutationFn = MutationFunction<
  ResendVerificationCodeMutationData,
  ResendVerificationCodeMutationVariables
>;

export function ResendVerificationCodeMutationFn({
  customerClient,
}: ResendVerificationCodeMutationDeps): ResendVerificationCodeMutationFn {
  return async request => {
    return await customerClient.resendVerificationCode(request);
  };
}

export function postResendVerificationCodeMutation(
  queryClient: QueryClient,
  data: ResendVerificationCodeMutationData,
  request: ResendVerificationCodeMutationVariables,
) {
  queryClient.invalidateQueries(['get-registration-state']);
}

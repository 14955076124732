import {DialogProps, Dialog} from '@blueprintjs/core';
import {classes as cx, stylesheet} from 'typestyle';
import {Colors} from './colors';

const classes = stylesheet({
  root: {
    margin: 16,
    padding: '16px 0',
    background: '#190633',
    color: '#fff',
    textAlign: 'center',
    fontFamily: 'Poppins',
  },
  content: {
    maxHeight: '80vh',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    marginTop: 8,
    width: 256,
    textAlign: 'left',
    fontWeight: 300,
  },
  close: {
    cursor: 'pointer',
    alignSelf: 'flex-end',
    margin: '-8px 8px 8px 0',
    width: 25,
    height: 25,
    border: `1px solid ${Colors.JPViolet4}`,
    borderRadius: 12,
    position: 'relative',
    $nest: {
      '&::after': {
        content: "''",
        display: 'block',
        position: 'absolute',
        width: 11,
        height: 1,
        top: 11,
        left: 6,
        transform: 'rotate(45deg)',
        backgroundColor: Colors.JPViolet4,
      },
      '&::before': {
        content: "''",
        display: 'block',
        position: 'absolute',
        width: 11,
        height: 1,
        top: 11,
        left: 6,
        transform: 'rotate(-45deg)',
        backgroundColor: Colors.JPViolet4,
      },
    },
  },
});

export const Toc: React.FC<DialogProps> = ({...props}) => {
  return (
    <Dialog {...props} className={cx(props.className ?? '', classes.root)}>
      <div onClick={props.onClose} className={classes.close} />
      <div className={classes.content}>
        <strong>
          Termos de Serviço
          <br /> JackPoker
        </strong>
        <p className={classes.text}>
          1. CONCESSÃO DE LICENÇA para VOCÊ: Sujeito aos termos de serviço aqui
          contidos, o JackPoker concede ao Usuário um direito não exclusivo,
          pessoal e intransferível de baixar, instalar e usar o Software como
          cliente para acessar os Servidores e jogar os Jogos e para nenhum
          outro propósito.
          <br />
          <br /> Observe que Você está proibido de usar este site ou o Software
          se Você for (i) menor de 18 anos, (ii) uma pessoa menor de idade em
          Sua jurisdição ou (iii) conectar-se ao Site a partir de um jurisdição
          a partir da qual tal uso é proibido ou seria ilegal para a JackPoker
          oferecer tal software para download ou fornecer os serviços
          relacionados ao Seu uso do Software. 1.1 Criando sua conta: Para usar
          os serviços, primeiro você precisa criar uma conta no JackPoker.
          Enquanto criar sua conta, você será solicitado a fornecer as
          informações pessoais que o JackPoker possa exigir para conhecer seus
          clientes, incluindo, mas não limitado a seu nome e sobrenome,
          endereço, um endereço de e-mail válido ao qual você tenha acesso, data
          de seu nascimento e um número de telefone válido. Você deve garantir e
          é obrigado a fornecer informações precisas e verdadeiras ao criar sua
          conta e manter essas informações de conta atualizadas. Caso você tenha
          alguma dúvida sobre como criar sua conta ou atualizar informações,
          entre em contato com help@jack-poker.com. Você só pode ter uma conta
          no JackPoker e só deve usar o Serviço usando essa única conta. Jogar
          Poker através de várias contas, simultaneamente ou não, com ou sem o
          consentimento do proprietário da conta, é uma violação dos Termos de
          Serviço para qualquer pessoa envolvida em tal atividade.
        </p>
      </div>
    </Dialog>
  );
};

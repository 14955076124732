import {Alert, Dialog, Classes, Spinner, SpinnerSize} from '@blueprintjs/core';
import React, {useState} from 'react';
import {cssRaw, stylesheet} from 'typestyle';
import {ListOperationsResponse_Item as Operation} from '../../../proto/deeplay/jackpoker_fast_funnel/agent/v1/agent';
import {useMutation, useQueryClient} from 'react-query';
import {
  FinishGameMutationFn,
  postFinishGameMutation,
} from '../queries/finishGame';
import {CustomerClient} from '../../../client';
import {toaster} from '../../../toaster';
import {ClientError} from 'nice-grpc-web';
import {getCouponURL} from '../../../utils/getCouponURL';
import assert from 'assert';
import {QRCode} from '../../common/QRCode';
import {Button} from '../../common/Button';
import {useTranslation} from 'react-i18next';
import {CouponDetailsDialog} from './CouponDetailsDialog';

cssRaw(`
@keyframes slidein {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

.${Classes.OVERLAY} {
  overflow: hidden !important;
}
.${Classes.OVERLAY_OPEN} {
  animation: '0.2s slidein both';
}
`);

const classes = stylesheet({
  root: {
    animation: '0.2s slidein both',
    padding: '24px 26px',
    borderRadius: '12px 12px 0px 0px',
    margin: 0,
    width: '100%',
    height: '100%',
    background: '#1E073D',
    color: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: 0,
    fontFamily: 'Poppins',
    textAlign: 'center',
  },
  idAndDate: {
    marginTop: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  couponId: {
    fontSize: 10,
    lineHeight: '16px',
  },
  couponDate: {
    fontSize: 10,
    lineHeight: '16px',
    color: 'rgba(255,255,255,0.6)',
  },
  copyLinkMessage: {
    background: 'rgba(17, 4, 36, 0.9)',
    boxShadow:
      '0px 0.25px 3px rgba(0, 0, 0, 0.039), 0px 2.75px 9px rgba(0, 0, 0, 0.19)',
    borderRadius: 4,
    color: '#fff',
    width: '90%',
    textAlign: 'center',

    $nest: {
      [`.${Classes.BUTTON_GROUP}`]: {
        display: 'none',
      },
    },
  },
  topButton: {
    marginBottom: 16,
  },
  detailsLink: {
    marginBottom: 24,
    color: '#8C3CFA',
    fontSize: 14,
    lineHeight: '16px',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

export const CouponDialog: React.FC<{
  operation?: Operation;
  isOpen: boolean;
  onClose: () => void;
}> = ({operation, isOpen, onClose}) => {
  const {t} = useTranslation();
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: FinishGameMutationFn({customerClient: CustomerClient()}),
    onSuccess(data, variables) {
      setLoading(false);
      onClose();
      postFinishGameMutation(queryClient, data, variables);
    },
    onError(err: any) {
      setLoading(false);
      toaster.show({
        message: (
          <div>
            <div>Error:</div>
            <div>{err instanceof ClientError ? err.details : err.message}</div>
          </div>
        ),
        intent: 'danger',
      });
    },
  });

  if (!isOpen) {
    return <></>;
  }

  assert(operation != null && operation.type?.$case === 'purchase');

  const {purchase} = operation.type;
  const {couponId} = purchase;

  const onButtonClick = () => {
    setIsAlertOpen(true);
  };
  const onConfirm = () => {
    setLoading(true);
    mutation.mutate({
      couponId,
    });
  };
  const onAlertClose = () => {
    setIsAlertOpen(false);
  };
  const onCopyClick = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/customer/${purchase.couponId}`,
    );
    toaster.show({
      message: <div>{t('Link has been copied')}</div>,
      timeout: 2000,
      className: classes.copyLinkMessage,
    });
  };
  const onDetailsClick = () => setIsDetailsOpen(true);
  const onDetailsClose = () => setIsDetailsOpen(false);

  const status = purchase.state?.$case;

  assert(couponId != null);

  const date = operation.timestamp
    ? new Date(operation.timestamp.seconds.toNumber() * 1000)
    : '';

  return (
    <Dialog className={classes.root} isOpen={isOpen} onClose={onClose}>
      <div>
        <QRCode size={256} url={getCouponURL(couponId)} />
        <div className={classes.idAndDate}>
          <div className={classes.couponId}>
            {couponId.slice(0, 6).toUpperCase()}
          </div>
          <div className={classes.couponDate}>{date.toLocaleString()}</div>
        </div>
      </div>
      <div>
        {status === 'inGame' ? (
          <Button disabled={loading} onClick={onButtonClick}>
            {loading ? (
              <Spinner size={SpinnerSize.SMALL} />
            ) : (
              `${t('Finish game')}`
            )}
          </Button>
        ) : (
          ''
        )}
        <div
          style={{display: 'none'}}
          className={classes.detailsLink}
          onClick={onDetailsClick}
        >
          {t('Coupon details')}
        </div>
        <Button primary className={classes.topButton} onClick={onCopyClick}>
          {t('Copy Link')}
        </Button>
        <Button onClick={onClose}>{t('Close')}</Button>
      </div>

      <Alert
        intent="danger"
        isOpen={isAlertOpen}
        cancelButtonText={t('Cancel')}
        confirmButtonText={t('Finish')}
        canEscapeKeyCancel
        onClose={onAlertClose}
        onConfirm={onConfirm}
      >
        {t('Are you sure you want to finish a game?')}
      </Alert>
      <CouponDetailsDialog
        embedded
        operation={operation}
        isOpen={isDetailsOpen}
        onClose={onDetailsClose}
      />
    </Dialog>
  );
};
